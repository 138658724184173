export const SAMPLE_PATIENT_LIST = [
  {
    firstName: "F Name",
    lastName: "Fisher",
    missedDoses: 5,
    isATOn: true,
    isHighRisk: true,
    isMyPatient: false,
    phoneNumber: "+19876543210",
  },
  {
    firstName: "F Name",
    lastName: "Johnson",
    missedDoses: 3,
    isATOn: true,
    isHighRisk: true,
    isMyPatient: false,
    phoneNumber: "+19876543211",
  },
  {
    firstName: "Otto + null",
    lastName: null,
    missedDoses: 0,
    isATOn: true,
    isHighRisk: false,
    isMyPatient: false,
    phoneNumber: "+19876543212",
  },
  {
    firstName: "Derick",
    lastName: "Newman",
    missedDoses: 2,
    isATOn: true,
    isHighRisk: false,
    isMyPatient: false,
    phoneNumber: "+19876543213",
  },
  {
    firstName: null,
    lastName: null,
    missedDoses: 0,
    isATOn: true,
    isHighRisk: false,
    isMyPatient: false,
    phoneNumber: "+19876543214",
  },
  {
    firstName: "Clara",
    lastName: null,
    missedDoses: 0,
    isATOn: false,
    isHighRisk: false,
    isMyPatient: false,
    phoneNumber: "+19876543215",
  },
  {
    firstName: "Leo",
    lastName: null,
    missedDoses: 0,
    isATOn: true,
    isHighRisk: false,
    isMyPatient: false,
    phoneNumber: "+19876543216",
  },
];
