// MSAL imports
import { PublicClientApplication } from "@azure/msal-browser";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import Context from "context/Context";
import { theme } from "helpers/theme";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { msalConfig } from "./auth.config";
import reportWebVitals from "./reportWebVitals";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <Context>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App pca={msalInstance} />
    </ThemeProvider>
  </Context>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
