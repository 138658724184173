import { groupBy } from "utility/groupBy";

// Todo : no group by
export const REFERRAL_DETAILS = [
  {
    eventCategory: "Reach out",
    eventName: "Pending initial call",
    eventCommentDetails: null,
    updatedAt: 1634217140657,
    eventId: "12345",
  },
  {
    eventCategory: "Reach out",
    eventName: "Non-working number",
    eventCommentDetails: {
      eventComment: "New number provided",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217282396,
    eventId: "12345",
  },
  {
    eventCategory: "Reach out",
    eventName: "Wrong number",
    eventCommentDetails: {
      eventComment: "New number provided",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217362995,
    eventId: "12345",
  },
  {
    eventCategory: "Reach out",
    eventName: "No answer",
    eventCommentDetails: {
      eventComment: "New number provided",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217362995,
    eventId: "12345",
  },
  {
    eventCategory: "Reach out",
    eventName: "Unable to reach",
    eventCommentDetails: {
      eventComment: "New number provided",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217362995,
    eventId: "12345",
  },
  {
    eventCategory: "Reach out",
    eventName: "Call back request",
    eventCommentDetails: null,
    updatedAt: 1634217362995,
    eventId: "12345",
  },
  {
    eventCategory: "Reach out",
    eventName: "Left voicemail",
    eventCommentDetails: {
      eventComment: "Home free to call back",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217384537,
    eventId: "12345",
  },
  {
    eventCategory: "Reach out",
    eventName: "Wrong number",
    eventCommentDetails: {
      eventComment: "New number provided",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217389670,
    eventId: "12345",
  },
  {
    eventCategory: "Consult",
    eventName: "Consult scheduled",
    eventCommentDetails: {
      eventComment: "Consult scheduled for October 20",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217447716,
    eventId: "12345",
  },
  {
    eventCategory: "Consult",
    eventName: "Consult cancelled",
    eventCommentDetails: {
      eventComment: "Consult cancelled for October 15",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217541413,
    eventId: "12345",
  },
  {
    eventCategory: "Consult",
    eventName: "Consult no-show",
    eventCommentDetails: {
      eventComment: "Consult cancelled for October 15",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217541413,
    eventId: "12345",
  },
  {
    eventCategory: "Consult",
    eventName: "Consult rescheduled",
    eventCommentDetails: {
      eventComment: "Consult rescheduled for October 15",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217541413,
    eventId: "12345",
  },
  {
    eventCategory: "Consult",
    eventName: "Consult declined",
    eventCommentDetails: null,
    updatedAt: 1634217541413,
    eventId: "12345",
  },
  {
    eventCategory: "Consult",
    eventName: "Telehealth",
    eventCommentDetails: null,
    updatedAt: 1634217541413,
    eventId: "12345",
  },
  {
    eventCategory: "Consult",
    eventName: "Virtual visit",
    eventCommentDetails: null,
    updatedAt: 1634217541413,
    eventId: "12345",
  },
  {
    eventCategory: "Consult",
    eventName: "Documentation pending",
    eventCommentDetails: null,
    updatedAt: 1634217541413,
    eventId: "12345",
  },
  {
    eventCategory: "Unknown",
    eventName: "Submitted by pharmacy",
    eventCommentDetails: null,
    updatedAt: 1634217541416,
    eventId: "12345",
  },
  {
    eventCategory: "Pharmacy service",
    eventName: "Service declined",
    eventCommentDetails: {
      eventComment: "Check shipment details",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217541804,
    eventId: "12345",
  },
  {
    eventCategory: "Pharmacy service",
    eventName: "Submitted to pharmacy",
    eventCommentDetails: {
      eventComment: "Check shipment details",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217541804,
    eventId: "12345",
  },
  {
    eventCategory: "Pharmacy service",
    eventName: "Received by pharmacy",
    eventCommentDetails: {
      eventComment: "Check shipment details",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217541804,
    eventId: "12345",
  },
  {
    eventCategory: "Pharmacy service",
    eventName: "First shipment created",
    eventCommentDetails: {
      eventComment: "Check shipment details",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217541804,
    eventId: "12345",
  },
  {
    eventCategory: "Pharmacy service",
    eventName: "Client Cancelled",
    eventCommentDetails: {
      eventComment: "Check shipment details",
      commentUpdatedAt: 1634800451703,
    },
    updatedAt: 1634217541804,
    eventId: "12345",
  },
];

export const PENDING_INITIAL_CALL_DATA = (updatedAt) => [
  {
    eventCategory: "Reach out",
    eventName: "Pending Initial Call",
    eventCommentDetails: null,
    updatedAt,
    eventId: null,
  },
];

const groupData = (referralDetailItems) => {
  const groupedItems = groupBy(referralDetailItems, ["eventCategory"]);

  let consult = groupedItems["Consult"];
  let pharmacyServices = groupedItems["Pharmacy service"];
  let reachOut = groupedItems["Reach out"];

  consult = consult ? consult : [];
  pharmacyServices = pharmacyServices ? pharmacyServices : [];
  reachOut = reachOut ? reachOut : [];

  return { consult, pharmacyServices, reachOut };
};

export const GROUPED_REFERRAL_DETAILS = groupData(REFERRAL_DETAILS);
