import axios from "axios";

import { AxiosHttpErrors } from "./errors";
import { SchemaValidationError } from "./errors/schemaValidationError";

const API_TIMEOUT_LIMIT = 1000 * 30; // In milliseconds

export const get = (route, params = null, hashKey, joiSchema = null) => {
  const url = process.env.REACT_APP_SERVER_ROOT + route;
  return axios
    .get(url, {
      params,
      timeout: API_TIMEOUT_LIMIT,
    })
    .then(
      (response) => {
        if (joiSchema) {
          const validationResponse = joiSchema.validate(response.data);
          if (validationResponse.error) {
            console.warn("Schema Validation Error", {
              validationResponse,
              route,
              hashKey,
            });
            throw new SchemaValidationError(route, validationResponse);
          }
        }
        return response;
      },
      (error) => {
        throw new AxiosHttpErrors(hashKey, error);
      },
    );
};

export const put = (route, data = null, params = null) => {
  const url = process.env.REACT_APP_SERVER_ROOT + route;
  if (typeof data === "object") data = JSON.stringify(data);
  return axios.put(url, data, { params, timeout: API_TIMEOUT_LIMIT });
};

export const post = (route, data = null, params = null, hashKey = null) => {
  const url = process.env.REACT_APP_SERVER_ROOT + route;
  if (typeof data === "object") data = JSON.stringify(data);
  return axios.post(url, data, { params, timeout: API_TIMEOUT_LIMIT }).then(
    (response) => {
      return response;
    },
    (error) => {
      throw new AxiosHttpErrors(hashKey, error);
    },
  );
};

export const patch = (route, data = null, params = null) => {
  const url = process.env.REACT_APP_SERVER_ROOT + route;
  if (typeof data === "object") data = JSON.stringify(data);
  return axios.patch(url, data, { params, timeout: API_TIMEOUT_LIMIT });
};

// Developer Mode
export const fetchCustomPayload = (hash, fallBackPayload = null) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const sampleHash = hash + "#payload";
      let customPayload = null;
      try {
        customPayload = JSON.parse(localStorage.getItem(sampleHash));
      } catch {
        customPayload = null;
      }

      const payload = customPayload ? customPayload : fallBackPayload;
      payload ? resolve(payload) : reject(null);
    }, 1000);
  });
};

// Third-party API calls
export const getFromExternal = (url, params = null) => {
  return axios.get(url, { params });
};
