import { makeStyles } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Store } from "context/Context";
import { getPatientList } from "helpers/api";
import { INSTANT_READ_CACHE } from "helpers/cacheConfiguration";
import { clinicianRoutes } from "helpers/routeConfig";
import CardErrorBoundary from "pages/common/CardErrorBoundary/CardErrorBoundary";
import GlobalApiError from "pages/common/ErrorScreens/GlobalApiError";
import { NoMyPatients, NoPatients } from "pages/common/NoDataScreens";
import SimplePageLoader from "pages/common/PageLoaders/SimplePageLoader";
import PatientCard from "pages/common/PatientCard/PatientCard";
import SearchBar from "pages/common/SearchBar/SearchBar";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { generateHash } from "utility";

const useStyles = makeStyles((theme) => ({
  patientRootContainer: {
    maxWidth: theme.pageDimensions.mobileMaxWidth,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: theme.palette.gradient.linear,
    padding: theme.customMixins.pxToRem(24),
    overflowY: "auto",
  },
  listContainer: {
    flexGrow: 1,
    width: "100%",
    paddingTop: theme.customMixins.pxToRem(0),
  },
  pageTitleContainer: {
    width: "100%",
  },
  myPatients: {
    font: theme.typography.sectionSubtitle.medium.font,
    color: theme.palette.primary.A900,
  },
  selectFilter: {
    marginTop: theme.customMixins.pxToRem(24),
    "& .MuiSvgIcon-root ": {
      color: theme.palette.primary.A900,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      color: theme.palette.primary.A900,
      font: theme.typography.sectionSubtitle.medium.font,
    },
    "& .MuiSelect-select": {
      color: theme.palette.primary.A900,
      font: theme.typography.sectionSubtitle.medium.font,
      paddingLeft: theme.customMixins.pxToRem(0),
      paddingTop: theme.customMixins.pxToRem(0),
      paddingBottom: theme.customMixins.pxToRem(0),
    },
  },

  pageSubTitle: {
    color: theme.palette.textPrimary.emphasisLow,
    font: theme.typography.body.regular.font,
    marginTop: theme.customMixins.pxToRem(20),
  },
}));

const getPatientListItems = async (args) => {
  return getPatientList();
};

export const Patients = () => {
  const classes = useStyles();
  const history = useHistory();

  const SORT_OPTIONS = {
    myPatients: "My patients",
    allPatients: "All patients",
  };

  const [searchState, setSearchState] = useState("");
  const [sort, setSort] = useState(SORT_OPTIONS.allPatients);

  const { state, dispatch } = useContext(Store);

  function showSelectedPatient(patient) {
    if (patient) {
      setSearchState("");
      localStorage.removeItem("patientDetailsMenu");
      history.push(clinicianRoutes.patientDetail);
      localStorage.setItem(
        "patientDetails",
        JSON.stringify({
          firstName: patient.firstName,
          lastName: patient.lastName,
          phoneNumber: patient.phoneNumber,
        }),
      );
    }
  }

  useEffect(() => {
    dispatch({ type: "APP_BAR_TITLE", title: "Patients" });
    dispatch({ type: "SHOW_BACK_BUTTON", showBackButton: false });
  }, [dispatch]);

  const {
    data: patientList,
    isLoading,
    isError,
    error,
  } = useQuery(["PatientList"], getPatientListItems, INSTANT_READ_CACHE);

  const alphabeticSorting = (patients) => {
    return patients.sort((a, b) => {
      if (a.lastName?.toLowerCase() < b.lastName?.toLowerCase()) return -1;
      if (a.lastName?.toLowerCase() > b.lastName?.toLowerCase()) return 1;
      return 0;
    });
  };

  const sortPatients = (patients) => {
    if (searchState !== "") {
      patients = patients.filter((patient) => {
        return (
          patient?.firstName
            ?.toLowerCase()
            .includes(searchState.toLowerCase()) ||
          patient?.lastName?.toLowerCase().includes(searchState.toLowerCase())
        );
      });
    }

    if (sort === SORT_OPTIONS.allPatients) {
      return patients;
    } else if (sort === SORT_OPTIONS.myPatients) {
      return patients.filter((patient) => {
        return patient.isMyPatient === true;
      });
    } else {
      return patients;
    }
  };

  const handleChange = (event) => {
    setSort(event.target.value);
  };

  return (
    <div
      className={classes.patientRootContainer}
      data-testid="patients-container"
    >
      <div className={classes.pageTitleContainer}>
        {!isError && patientList && (
          <>
            {patientList?.length !== 0 && (
              <>
                <SearchBar
                  onChange={setSearchState}
                  placeholder="Search by patient name"
                />
                <div className={classes.pageSubTitle}>
                  Your patients' missed doses over the past seven days are shown
                  below.
                </div>
                <Select
                  value={sort}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className={classes.selectFilter}
                  name="sort"
                >
                  <MenuItem value={SORT_OPTIONS.allPatients}>
                    All patients
                  </MenuItem>
                  <MenuItem value={SORT_OPTIONS.myPatients}>
                    My patients
                  </MenuItem>
                </Select>
              </>
            )}
          </>
        )}
      </div>
      {!isError && (
        <>
          {patientList &&
            patientList.length === 0 &&
            sort === SORT_OPTIONS.allPatients && <NoPatients />}
          <SimplePageLoader hidden={!isLoading} />
          <div className={classes.listContainer}>
            {patientList &&
              alphabeticSorting(sortPatients(patientList)).map((patient) => {
                return (
                  <CardErrorBoundary
                    key={generateHash(JSON.stringify(patient))}
                  >
                    <PatientCard
                      key={generateHash(JSON.stringify(patient))}
                      onClick={() => showSelectedPatient(patient)}
                      patient={patient}
                    />
                  </CardErrorBoundary>
                );
              })}
            {patientList &&
              !sortPatients(patientList).length &&
              sort === SORT_OPTIONS.myPatients && <NoMyPatients />}
          </div>
        </>
      )}
      {isError && (
        <GlobalApiError showErrorDetails={!state.isProduction} error={error} />
      )}
    </div>
  );
};

export default Patients;
