import { post } from "../communication";

export const ROUTE_URL = "/user-mgmt/clinician/referral";
export const API_DEV_HASH = "devMode##referralForm";

export const sendReferralData = (referralData) => {
  let apiPromise;

  if (localStorage.getItem(API_DEV_HASH) === "true") {
    apiPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: {} });
      }, 1000);
    });
  } else {
    apiPromise = post(ROUTE_URL, referralData);
  }

  return apiPromise;
};
