export const PALETTE = {
  primary: {
    main: "#3370BA /* primary main A700 */",
    A900: "#224288 /* primary A900 */",
    A800: "#2D5FA8 /* primary A800 */",
    A700: "#3370BA /* primary A700 */",
    A600: "#3B81CC /* primary A600 */",
    A500: "#418ED9 /* primary A500 */",
    A400: "#559EDF /* primary A400 */",
    A300: "#6FAEE4 /* primary A300 */",
    A200: "#95C5EC /* primary A200 */",
    A100: "#BDDBF4 /* primary A100 */",
    A50: "#E4F1FA /* primary A50 */",
  },
  secondary: {
    main: "#4CA1A6 /* secondary main */",
    A900: "#3C6866 /* secondary A900 */",
    A800: "#478C8F /* secondary A800 */",
    A700: "#4CA1A6 /* secondary A700 */",
    A600: "#53B7BF /* secondary A600 */",
    A500: "#58C7D1 /* secondary A500 */",
    A400: "#5FD0D7 /* secondary A400 */",
    A300: "#70D9DE /* secondary A300 */",
    A200: "#93E5E7 /* secondary A200 */",
    A100: "#BBEFF0 /* secondary A100 */",
    A50: "#E3F9F9 /* secondary A50 */",
  },
  white: {
    high: "#fff",
    medium: "rgba(255, 255, 255, 0.74)",
    low: "rgba(255, 255, 255, 0.38)",
  },
  background: {
    grey: "rgba(245, 245, 245, 1)",
  },
  error: {
    main: "#E70000",
    secondary: "#BB261A",
    light: "#FDFFAF",
    dark: "rgba(187, 38, 26, 1)",
  },
  warning: {
    main: "#FF8A00",
    light: "#FFCECE",
  },
  success: {
    main: "#00BE4C",
  },
  textPrimary: {
    main: "#000000 /* Main */",
    emphasisLowest: "rgba(0, 0, 0, 0.38) /* Lowest Emphasis */",
    emphasisLow: "rgba(0, 0, 0, 0.6) /* Low Emphasis */",
    emphasisMedium: "rgba(0, 0, 0, 0.74) /* Medium Emphasis */",
    emphasisHigh: "rgba(0, 0, 0, 0.87) /* High Emphasis */",
  },
  system: {
    W: "#FFFFFF",
    BG: "#F5F5F5",
    line: "#C8C8C8",
    text1: "rgba(0, 0, 0, 0.6)",
    text2: "rgba(0, 0, 0, 0.74)",
    B: "rgba(0, 0, 0, 0.87)",
  },
  shades: {
    A1: "#ECECEC /* Shades A1 */",
    A2: "#C8C8C8 /* Shades A2 */",
    A3: "#B5B5B5 /* Shades A3 */",
    A4: "#909090 /* Shades A4 */",
    A5: "#6B6B6B /* Shades A5 */",
  },
  alert: {
    fill: "#FFCECE",
    highlight: "#FDFFAF",
    yellow: "#FF8A00",
    green: "#00A82F",
  },
  gradient: {
    linear: "linear-gradient(180deg, #E6EFFA 55.21%, #E3F9F9 100%)",
    angle: "linear-gradient(135.95deg, #E6EFFA 54.88%, #E3F9F9 96.89%)",
  },
};
