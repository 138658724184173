import {
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { unauthenticatedRoutes } from "helpers/routeConfig";

const useStyles = makeStyles((theme) => ({
  appContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "hidden",
    alignItems: "center",
    background: theme.palette.gradient.linear,
    "&> :nth-child(2)": {
      flexGrow: 1,
    },
  },
  "@supports (-webkit-touch-callout: none)": {
    appContainer: {
      height: "87vh",
    },
  },
  pageContent: {
    display: "flex",
    maxWidth: theme.pageDimensions.mobileMaxWidth,
    flexDirection: "column",
    flexGrow: 1,
    padding: theme.customMixins.pxToRem(24),
    overflowY: "scroll",
  },
  sectionTitle: {
    font: theme.typography.sectionTitle.medium.font,
    color: theme.palette.primary.A900,
  },
  title: {
    marginTop: theme.customMixins.pxToRem(24),
    color: theme.palette.textPrimary.emphasisLow,
    font: theme.typography.description.bold.font,
  },
  italicTitle: {
    marginTop: theme.customMixins.pxToRem(24),
    color: theme.palette.textPrimary.emphasisLow,
    font: theme.typography.description.bold.font,
    fontStyle: "italic",
  },
  bodyText: {
    marginTop: theme.customMixins.pxToRem(8),
    font: theme.typography.description.regular.font,
    color: theme.palette.textPrimary.emphasisLow,
    padding: 0,
  },
}));

function PrivacyPolicy() {
  const classes = useStyles();

  return (
    <div
      data-testid="privacy-policy-container"
      className={classes.appContainer}
    >
      <div className={classes.pageContent}>
        <Typography variant="h2" className={classes.sectionTitle}>
          Privacy Policy
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          Last Updated: [insert date posted to services]
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          Integrated Medication Management, LLC d/b/a HomeFree Pharmacy Services
          (<strong>“HomeFree”</strong>, <strong>“we”</strong>,
          <strong>“our”</strong>, or <strong>“us”</strong>) recognizes the
          importance of protecting your privacy. This Privacy Policy explains
          the information we collect through{" "}
          <Link
            rel="noopener noreferrer"
            href="https://www.google.com/"
            target="_blank"
          >
            [insert URL]
          </Link>{" "}
          and its subdomains as well as the HomeFree mobile applications and
          other sites where this privacy policy is posted or linked
          (collectively, the <strong>“Services”</strong>). This Privacy Policy
          is not applicable to any information that is protected health
          information (<strong>“PHI”</strong>) under the Health Insurance
          Portability and Accountability Act (<strong>“HIPAA”</strong>). Any
          information that is PHI is governed by, and will be used and disclosed
          solely as permitted by the HomeFree Notice of Privacy Practices{" "}
          <Link
            rel="noopener noreferrer"
            href="https://www.google.com/"
            target="_blank"
          >
            [insert hyperlink to NPP]
          </Link>
          .
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          Please review this Privacy Policy, which is incorporated into and made
          part of our{" "}
          <Link
            rel="noopener noreferrer"
            href={unauthenticatedRoutes.termsOfService}
            target="_blank"
          >
            Terms and Conditions
          </Link>
          . By accessing or using the Services, you consent to our collection,
          use and disclosure of your information in accordance with this Privacy
          Policy and Terms of Use. If you do not agree to our Privacy Policy or
          Terms and Conditions, you may not use the Services.
        </Typography>
        <Typography variant="h3" className={classes.title}>
          Information we collect
        </Typography>
        <Typography variant="h3" className={classes.italicTitle}>
          Personal Information we may collect
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          “Personal Information” is information that can be used to identify,
          contact or locate you. Examples of Personal Information include your
          name, physical address, email address, telephone number and other
          information you provide.
        </Typography>
        <Typography variant="h3" className={classes.italicTitle}>
          Information you provide to us
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          We collect information, including Personal Information you provide
          directly to us. For example, we collect information when you: create
          an account, use the interactive areas and features of the Services,
          subscribe to a newsletter or email list, participate in a survey or
          events, pay a bill, request customer or technical support, or
          otherwise communicate with us.
          <br />
          <br />
          The types of information we may collect from you include:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              a) <strong>Account Information</strong>, such as your name, email
              address, password, postal address, phone number, date of birth and
              any other information you choose to provide.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              b) <strong>Transaction Information</strong>, such as your health
              insurance information and limited payment information from you,
              such as payment method and payment card information; however, we
              do not collect or store full payment card numbers and all
              transactions are processed by our third-party payment processor.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              c) <strong>Health Information</strong>, such as your past and
              present medical condition, medication information, and treatment
              history.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              d) <strong>Other Information You Choose to Provide</strong>, such
              as when you participate in a survey, assessment, contest,
              promotion or interactive area of the Services or when you request
              technical or customer support.
            </ListItemText>
          </ListItem>
        </List>
        <Typography variant="h3" className={classes.italicTitle}>
          Information We Collect Automatically When You Use the Services
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          When you access or use the Services, the types of information we may
          automatically collect about you include:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              a) <strong>Log Information</strong>: When you visit the Services,
              our servers automatically record certain log file information,
              such as your Internet Protocol (“<strong>IP</strong>”) address,
              operating system, browser type and language, referring URLs,
              access times, pages viewed, links clicked and other information
              about your activities on the Services.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              b) <strong>Mobile Device Information</strong>: We collect
              information about the mobile device you use to access or use the
              Services, including the hardware model, operating system and
              version, unique device identifiers, mobile network information and
              information about your use of our mobile applications. With your
              consent, we may also collect information about the precise
              location of your device and access and collect information from
              certain native applications on your device (such as your device’s
              camera, photo album and phonebook applications) to facilitate your
              use of certain features of the Services. For more information
              about how you can control the collection of location information
              and/or our access to other applications on your device, please see
              “Your Choices” below.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              c){" "}
              <strong>
                Information Collected by Cookies and Other Tracking Technologies
              </strong>
              : We and our service providers use various tracking technologies,
              including cookies and web beacons, to collect information about
              you when you interact with our Services. Cookies are small data
              files stored on your hard drive or in device memory that help us
              improve the Services and your experience, see which areas and
              features of the Services are popular, and count visits. Web
              beacons are electronic images that may be used in the Services or
              emails and help deliver cookies, count visits and understand usage
              and campaign effectiveness. For more information about cookies,
              and how to disable them, please see “Your Choices” below.
            </ListItemText>
          </ListItem>
        </List>
        <Typography variant="h3" className={classes.italicTitle}>
          Information Collected From Other Sources
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          {/* check [] */}
          We may also obtain information about you from other sources. For
          example, we may collect information about you from third parties,
          including but not limited to [insert example of information collected
          from third parties, if applicable]. Once we combine information from
          other sources with your information collected pursuant to this policy,
          we apply this policy to the combined information as long as it is
          combined.
        </Typography>
        <Typography variant="h3" className={classes.title}>
          How We Use Information
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          HomeFree uses the information about you for various purposes,
          including to:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • Provide, maintain and improve our Services and provide you with
              relevant information;
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • Send you technical notices, updates, security alerts and support
              and administrative messages;
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • Respond to your comments, questions and requests and provide
              customer service;
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • Communicate with you about products and services offered by us
              and others, and to provide news and information we think will be
              of interest to you;
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • Monitor and analyze trends, usage and activities in connection
              with our Services;
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • Detect, investigate and prevent fraudulent transactions and
              other illegal activities and protect the rights and property of
              HomeFree and others;
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • Maintain appropriate records for internal administrative
              purposes;
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • To de-identify and aggregate information. Once de-identified and
              aggregated (for example, we may aggregate Personal Information to
              calculate the percentage of our users who have a particular
              telephone area code), it is no longer Personal information. Such
              de-identified and/or aggregated information which does not
              identify individuals is not subject to this Privacy Policy; and
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • Carry out any other purpose described to you at the time the
              information was collected.
            </ListItemText>
          </ListItem>
        </List>
        <Typography variant="body1" className={classes.bodyText}>
          Please note, use of your protected health information is explained in
          the Notice of Privacy Practices{" "}
          <Link
            rel="noopener noreferrer"
            href={unauthenticatedRoutes.termsOfService}
            target="_blank"
          >
            [insert hyperlink to NPP]
          </Link>
          , not in this Privacy Policy.
        </Typography>
        <Typography variant="h3" className={classes.title}>
          Disclosure of Information
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          We may share information about you, including Personal Information, as
          follows, or as otherwise described in this Privacy Policy:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • With vendors, consultants and other service providers who need
              access to such information to carry out work or perform services
              on our behalf;
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • In response to requests from local, state, provincial or federal
              law enforcement officials, any judicial, administrative or similar
              proceeding or order, such as a subpoena if we believe disclosure
              is in accordance with, or required by any applicable law;
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • If we believe your actions are inconsistent with our user
              agreements or policies, or to protect the rights, property and
              safety of HomeFree and others;
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • To investigate suspected fraud, harassment, physical threats, or
              other violations of any law, rule or regulation, the Services’
              rules or policies, or the rights of third parties or to
              investigate any suspected conduct which we deem improper;
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • In connection with, or during negotiations of, any merger, sale
              of company assets, financing or acquisition of all or a portion of
              our business by another company;
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • Between and among HomeFree and our current and future parents,
              affiliates, subsidiaries, and other companies under common control
              and ownership;
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • With your consent or at your direction;
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • To comply with transparency or other public reporting
              obligations; and
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • As otherwise permitted or required by law.
            </ListItemText>
          </ListItem>
        </List>
        <Typography variant="body1" className={classes.bodyText}>
          We may also share aggregated or de-identified information, which
          cannot reasonably be used to identify you.
          <br />
          <br />
          We are based in the United States and the information we collect is
          governed by U.S. law. By accessing or using the Services or otherwise
          providing information to us, you consent to the processing and
          transfer of information in and to the U.S. and other countries, where
          you may not have the same rights as you do under local law. Where this
          is the case, we will take appropriate measures to protect information
          about you in accordance with this Privacy Policy.
        </Typography>
        <Typography variant="h3" className={classes.title}>
          Children’s Privacy
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          HomeFree is committed to protecting the privacy of children. Our
          Services are not intended for, or designed to attract children. In
          addition, we do not collect Personal Information from any person known
          by HomeFree to be a child under the age of 13.
        </Typography>
        <Typography variant="h3" className={classes.title}>
          Security
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          We seek to use reasonable physical, technical, and administrative
          measures designed to protect personal information within our
          organization. Unfortunately, no data transmission, processing or
          storage system can be guaranteed to be 100% secure. If you have reason
          to believe that your interaction with us is no longer secure (for
          example, if you feel that the security of your account has been
          compromised), please immediately notify us in accordance with the
          “Contacting Us” section below.
        </Typography>
        <Typography variant="h3" className={classes.title}>
          Links to Third-Party Websites
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          Our Services may reference or provide links to third-party websites.
          Other websites may also reference or link to our Services. Because
          these websites are not controlled by HomeFree, we are not responsible
          for the third-party websites. We encourage our users to be aware when
          they leave our Services to review the privacy policies posted on each
          and every website that collects personally identifiable information.
          Please be aware that HomeFree does not control, endorse, screen or
          approve, nor are we responsible for, the privacy policies or
          information practices of third parties or their websites or mobile
          applications. Visiting these other websites is at your own risk.
        </Typography>
        <Typography variant="h3" className={classes.title}>
          Advertising and Analytics Services Provided by Others
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          We may allow others to provide analytics services and serve
          advertisements on our behalf across the web and in the mobile
          applications. These entities may use cookies, web beacons, device
          identifiers and other technologies to collect information about your
          use of the Services and other websites and applications, including
          your IP address, web browser, mobile network information, pages
          viewed, time spent on pages or in apps, links clicked, and conversion
          information. This information may be used by HomeFree and others to,
          among other things, analyze and track data, determine the popularity
          of certain content, and better understand your online activity. For
          more information about interest-based ads, or to opt out of having
          your web browsing information used for behavioral advertising
          purposes, please visit www.aboutads.info/choices. Your device may also
          include a feature (“Limit Ad Tracking” on iOS or “Opt Out of
          Interest-Based Ads” or “Opt Out of Ads Personalization” on Android)
          that allows you to opt out of having certain information collected
          through apps used for behavioral advertising purposes.
        </Typography>
        <Typography variant="h3" className={classes.title}>
          Do Not Track Signals
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          HomeTree does not respond to Do Not Track signals to the extent
          received from a web browser. Because there currently is not a
          universally accepted technological or legal standard for recognizing
          or honoring DNT signals as reflections of user choice, we do not
          respond to them at this time. Once such technology is created and has
          received applicable regulatory approval, we will consider (a) whether
          it is possible; and (b) if so, how it can be incorporated within
          HomeFree’s environment in the future.
        </Typography>
        <Typography variant="h3" className={classes.title}>
          Your Choices
        </Typography>
        <Typography variant="h3" className={classes.italicTitle}>
          Account Information
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          You may update, correct or modify information about you at any time by
          logging into your online account or by contacting us at [insert
          contact information]. If you wish to delete or deactivate your
          account, please email us at [insert contact information], but note we
          may continue to store information about you as required by law or for
          legitimate business purposes.
        </Typography>
        <Typography variant="h3" className={classes.title}>
          Notice to California Residents
        </Typography>
        {/* check [insert] things */}
        <List disablePadding>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • “Sales” of Personal Information
              <List disablePadding>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    ◦ We do not sell your personal information and have not done
                    so within the past 12 months.
                  </ListItemText>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • California Privacy Rights
              <List disablePadding>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    ◦ Privacy Rights – Under the CCPA, California residents may
                    have certain privacy rights, including the rights to: (i)
                    request additional disclosures about the Personal
                    Information we collect, use, and disclose, i.e., a “Request
                    to Know (Categories of Information)”; (ii) obtain a copy of
                    Personal Information, i.e., a “Request to Know (Specific
                    Pieces of Information),” sometimes called the Right to
                    Access; (iii) request deletion of Personal Information,
                    i.e., a “Request to Delete Information,” sometimes called
                    the Right to Be Forgotten; and (iv) opt out of the sale of
                    Personal Information, i.e., a “Request to Opt Out.”
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    ◦ How to Exercise Privacy Rights – If you wish to exercise
                    any of these rights, please email [insert email address] or
                    call us at [insert Toll Free Number]. The rights described
                    herein are not absolute and we reserve all of our rights
                    available to us at law in this regard. Additionally, if we
                    retain your data only in de-identified form, we will not
                    attempt to re-identify your data in response to a data
                    subject rights request.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    ◦ We will not discriminate against you because of your
                    exercise of any of the privacy rights provided in this
                    notice.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    ◦ If you make a request related to Personal Information
                    about you, we will need to verify your identity. To do so,
                    we will request that you match specific pieces of
                    information you have provided us previously. If it is
                    necessary to collect additional information from you, we
                    will use the information only for verification purposes and
                    will delete it as soon as practicable after complying with
                    the request. For requests related to particularly sensitive
                    information, we may require additional proof of
                    identification. If you make a request through an authorized
                    agent, we will require written proof that the agent is
                    authorized to act on your behalf. We will process your
                    request within the time provided by applicable law.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    ◦ You may also authorize someone to contact us or exercise
                    any of the above rights on your behalf, but we will require
                    documentation that you have requested an authorized agent to
                    make a request on your behalf. If we have collected
                    information on your minor child, you may exercise the above
                    rights on behalf of your minor child. We must be able to
                    reasonably verify your identity and authority to exercise
                    these rights before fulfilling a request. There are also
                    various exclusions and exceptions that apply to these rights
                    under applicable laws.
                  </ListItemText>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • Additional Privacy Rights for California Residents (California
              Shine the Light)
              <List disablePadding>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    ◦ If you are a California resident, California Civil Code
                    Section 1798.83 permits you to request information about our
                    practices related to the disclosure of your personal
                    information to certain third parties for their direct
                    marketing purposes. You may be able to opt-out of our
                    sharing of your personal information with unaffiliated third
                    parties for the third parties' direct marketing purposes in
                    certain circumstances. Please send your request (along with
                    your full name, email address, postal address, and the
                    subject line labeled "Your California Privacy Rights") at
                    [insert contact information].
                  </ListItemText>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>
        </List>
        <Typography variant="h3" className={classes.italicTitle}>
          Communications with HomeFree:
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          We want to communicate with you in ways you want to hear from us.
          Examples include email, text messages, and newsletters, which may
          contain special offers and new product announcements. To the extent
          that you are receiving marketing communications from the Services you
          may unsubscribe or opt-out of future communications as follows:
        </Typography>
        <List disablePadding>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • If you no longer wish to receive texts you may text the word
              “STOP” to the telephone number that messaged you, from the mobile
              device you wish to no longer receive texts.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              • If you no longer wish to receive emails you may click on the
              hyperlink titled “Unsubscribe” at the bottom of any marketing
              related email sent to you by HomeFree, and then follow the
              directions to unsubscribe from email. Please note that if you opt
              out of receiving promotional communications from us, we may still
              send you transactional communications, including emails about your
              account or purchases.
            </ListItemText>
          </ListItem>
        </List>
        <Typography variant="h3" className={classes.italicTitle}>
          Native Applications on Mobile Device
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          Some features of our mobile applications may require access to certain
          native applications on your mobile device, such as the camera and
          photo storage applications (e.g., to take and upload photos) and the
          phonebook application. If you decide to use these features, we will
          ask you for your consent prior to accessing the applications and
          collecting information. Note that you can revoke your consent at any
          time by changing the settings on your device.
        </Typography>
        <Typography variant="h3" className={classes.italicTitle}>
          Cookies and Your Ad Choices
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          Most web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove or reject browser
          cookies. Please note that if you choose to remove or reject cookies,
          this could affect the availability and functionality of the Services.
        </Typography>
        <Typography variant="h3" className={classes.italicTitle}>
          Push Notifications
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          With your consent, we may send promotional and non-promotional push
          notifications or alerts to your mobile device. You can deactivate
          these messages at any time by changing the notification settings on
          your mobile device or within our mobile applications.
        </Typography>
        <Typography variant="h3" className={classes.italicTitle}>
          Contact Us
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          {/* add contact information */}
          If you have any questions about this Privacy Policy or concerns about
          the way HomeFree processes your information, or require assistance in
          managing your privacy choices, please get in touch with us at: [insert
          contact information].
        </Typography>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
