import {
  BrowserAuthError,
  ClientAuthError,
  ClientConfigurationError,
  InteractionRequiredAuthError,
  ServerError,
} from "@azure/msal-browser";

export const manageSessionOnAuthErrors = (msalInstance, error) => {
  console.log({
    message: "Token error type",
    name: error.name,
    type: typeof error,
    keys: Object.keys(error),
  });
  if (error instanceof InteractionRequiredAuthError) {
    /**
     * InteractionRequiredAuthError: Error class, extends ServerError to represent server errors,
     * which require an interactive call.
     *
     * This error is thrown by acquireTokenSilent if the user is required to interact with the server
     * to provide credentials or consent for authentication/authorization.
     *
     * Error codes include "interaction_required", "login_required", and "consent_required".
     * */
    msalInstance
      .acquireTokenRedirect({ scopes: [] })
      .then(console.log)
      .catch(console.error);
  } else if (
    error instanceof ClientAuthError ||
    error instanceof ClientConfigurationError
  ) {
    /**
     * ClientAuthError: Error class, which denotes an issue with Client authentication.
     * Most errors that come from the library will be ClientAuthErrors. These errors result from
     * things like calling a login method when login is already in progress, the user cancels the login, and so on.
     *
     * ClientConfigurationError: Error class, extends ClientAuthError thrown before requests are
     * made when the given user config parameters are malformed or missing.
     */
    window.location.reload();
  } else if (error instanceof BrowserAuthError) {
    /**
     * BrowserAuthError: Error class, which denotes connection error and other browser issues.
     */
    window.location.reload();
  } else if (error instanceof ServerError) {
    /**
     * ServerError: Error class, represents the error strings sent by the authentication server.
     * These may be errors such as invalid request formats or parameters, or any other errors
     * that prevent the server from authenticating or authorizing the user.
     */
    msalInstance
      .loginRedirect(ServerError)
      .then(console.log)
      .catch(console.error);
  } else {
    /** For any other error move to login page. */
    console.log("Error condition matched");
    msalInstance
      .loginRedirect(ServerError)
      .then(console.log)
      .catch(console.error);
  }
};
