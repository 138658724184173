import { MEDICATION_INFO } from "samples/medicationInfo";

import { get } from "./../communication";

function formatDate(date) {
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${year}${month}${day}`;
}

export const ROUTE_URL = "/med-mgmt/member/{current}/medication";
export const API_DEV_HASH = "devMode##getMedicationData";

const PRESCRIPTION_UI_CONFIGURATION = [
  {
    label: "Manufacturer",
    key: "manufacturer",
    isValueInline: false,
  },
  {
    label: "Appearance",
    key: "appearance",
    isValueInline: false,
  },
  {
    label: "Dosage",
    key: "strength",
    isValueInline: false,
  },
  {
    label: "Quantity",
    key: "dispensedQuantity",
    isValueInline: false,
  },
  {
    label: "Instructions",
    key: "instructions",
    isValueInline: true,
  },
];

const REFILL_UI_CONFIGURATION = [
  {
    label: "Prescriber",
    key: "prescriberName",
    isValueInline: false,
  },
  {
    label: "Date filled",
    key: "dateFilled",
    isValueInline: false,
  },
  {
    label: "Days Supply",
    key: "daysSupply",
    isValueInline: false,
  },
  {
    label: "Refills",
    key: "remainingRefills",
    isValueInline: false,
  },
];

export const getMedicationData = async (drugcode, date = new Date()) => {
  let response;
  if (localStorage.getItem(API_DEV_HASH) === "true") {
    response = await new Promise((resolve) => {
      setTimeout(() => {
        resolve(MEDICATION_INFO);
      }, 1000);
    });
  } else {
    response = await get(ROUTE_URL, { date: formatDate(date), drugcode }).then(
      (response) => Promise.resolve(response.data),
    );
  }

  // Todo: Error Management
  const medicationDetails = response.medication;
  const prescription = PRESCRIPTION_UI_CONFIGURATION.map((item) => {
    const content = medicationDetails[item.key];
    return { ...item, content };
  });

  const refill = REFILL_UI_CONFIGURATION.map((item) => {
    const content = medicationDetails[item.key];
    return { ...item, content };
  });

  console.log({ medicationDetails, prescription, refill });

  return { prescription, refill };
};
