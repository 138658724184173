import * as Joi from "joi";

const referralDetails = Joi.object({
  eventCategory: Joi.string().required(),
  eventName: Joi.string().required(),
  eventCommentDetails: Joi.object({
    eventComment: Joi.string().allow(null, ""),
    commentUpdatedAt: Joi.number().allow(null),
  }).allow(null),
  updatedAt: Joi.number().allow(null),
  eventId: Joi.string().allow(null),
}).unknown();

export const referralDetailsSchema = Joi.array()
  .items(referralDetails)
  .required();
