export const PREFERENCE_SAMPLE = [
  {
    preferenceType: "ADHERENCE",
    preferenceValue: localStorage.getItem("enableAdherenceTracking") === "true", // true
  },
  {
    preferenceType: "REMINDER",
    preferenceValue: localStorage.getItem("enableReminderTracking") === "true", // true
  },
  {
    preferenceType: "SHIPPING_NOTIFICATION",
    preferenceValue:
      localStorage.getItem("enableShippingNotifications") === "true", // true
  },
  {
    preferenceType: "FORGOT_REMINDER",
    preferenceValue: 10, // 10, 15
  },
];
