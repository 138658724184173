import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { useGlobalNoDataStyles } from "./../global.styles";
import NoDataSvg from "./no-data.svg";

const useStyles = makeStyles((theme) => ({
  text: {
    font: theme.typography.body.medium.font,
    color: theme.palette.primary.main,
    marginTop: theme.customMixins.pxToRem(24),
  },
}));

function NoReferrals({ hidden, message, extraPadding }) {
  const globalClasses = useGlobalNoDataStyles();
  const classes = useStyles();

  if (hidden) return <></>;

  return (
    <div
      data-testid="no-data-root-container"
      style={{
        paddingTop: `${extraPadding}rem`,
      }}
      className={globalClasses.noDataRootContainer}
    >
      <img
        src={NoDataSvg}
        className={globalClasses.noDataIcon}
        alt="No data available"
      />
      <Typography className={classes.text}>{message}</Typography>
    </div>
  );
}

export default NoReferrals;

NoReferrals.defaultProps = {
  message: "No referral data available. Please check back later.",
  hidden: false,
  extraPadding: 0,
};

NoReferrals.propTypes = {
  /**
   * Message text to be shown on UI
   */
  message: PropTypes.string.isRequired,

  /**
   * Set UI visibility
   */
  hidden: PropTypes.bool,

  /**
   * Add Extra padding in REM units
   */
  extraPadding: PropTypes.number,
};
