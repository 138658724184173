// Todo:
// 1. Testing

import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { UI_ERROR_MESSAGES } from "constants/errorMessages";
import { TIME_OPTIONS } from "constants/timeOfDay";
import { Store } from "context/Context";
import {
  getMedicationSkipReasons,
  updateAdherence,
  updateReminderTimings,
} from "helpers/api";
import { BASIC_CACHE } from "helpers/cacheConfiguration";
import CardErrorBoundary from "pages/common/CardErrorBoundary/CardErrorBoundary";
import DateToggle from "pages/common/DateToggle/DateToggle";
import { MedicalCard } from "pages/common/MedicalCard";
import { NoDailyMedication } from "pages/common/NoDataScreens";
import UpdateAdherenceTime from "pages/common/UpdateAdherenceTime/UpdateAdherenceTime";
import UpdateReminderTime from "pages/common/UpdateReminderTime/UpdateReminderTime";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import {
  formatToStandardDate,
  generateHash,
  isDateInWithInXDay,
} from "utility";

import GlobalApiError from "./../../../common/ErrorScreens/GlobalApiError";
import adherenceIcon from "./assets/adherenceIcon.svg";
import DailyGreeting from "./DailyGreeting/DailyGreeting";
import {
  getDailyMedicationPageData,
  getParsedDate,
  getPreferences,
  updateAdherenceTracking,
} from "./helpers";

const useStyles = makeStyles((theme) => ({
  dateChangeContainer: {
    marginBottom: theme.customMixins.pxToRem(24),
  },
  cardHolder: {
    marginBottom: theme.customMixins.pxToRem(24),
    boxShadow: theme.customCss.cardShadow,
  },
  loaderContainer: {
    display: "flex",
    padding: "2rem",
    justifyContent: "center",
  },
  greeting: {
    font: theme.typography.sectionSubtitle.bold.font,
    color: theme.palette.primary.A900,
    margin: theme.customMixins.pxToRem(24, 0),
  },
  adherenceState: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.customMixins.pxToRem(24),
    "& > img": {
      marginRight: theme.customMixins.pxToRem(8),
    },
    "& > h3": {
      font: theme.typography.sectionSubtitle.medium.font,
      color: theme.palette.textPrimary.emphasisLow,
    },
  },
  snackbar: theme.customComponents.snackbar.error,
}));

function DailyMedicationView(props) {
  const {
    patientPhoneNumber,
    onSelectedDateChange,
    defaultDate,
    readOnly,
    showMissedReason,
    showGreetingMessage,
    noDataMessage,
  } = props;

  const classes = useStyles();
  let [pageStateHash, _] = useState(+new Date());

  const [selectedDate, setSelectedDate] = useState(
    defaultDate ? defaultDate : new Date(),
  );

  const [isUpdateAdherenceTracking, setIsUpdateAdherenceTracking] =
    useState(false);
  const [isUpdateReminderTracking, setIsUpdateReminderTracking] =
    useState(false);

  const [updateTimeData, setUpdateTimeData] = useState({});
  const [showGreeting, setShowGreeting] = useState(showGreetingMessage);

  let onAdherenceTrackingClick = (updateModalData) => {
    updateModalData.consumptionDate = selectedDate;
    setUpdateTimeData(updateModalData);
    setIsUpdateAdherenceTracking(true);
  };

  let onReminderTrackingClick = (updateModalData) => {
    setUpdateTimeData(updateModalData);
    setIsUpdateReminderTracking(true);
  };

  if (readOnly) {
    onReminderTrackingClick = undefined;
    onAdherenceTrackingClick = undefined;
  }

  const handleUpdateTimeSave = (time) => {
    const { isReminderUpdate, timeOfDay, rows } = updateTimeData;
    setIsUpdateAdherenceTracking(false);
    setIsUpdateReminderTracking(false);

    const apiCallback = () => {
      pastDailyMedication.refetch();
      dailyMedication.refetch();
      futureDailyMedication.refetch();
    };

    if (isReminderUpdate) {
      updateReminderTimings({
        reminders: [{ timeOfDay, reminderTime: time }],
      }).then(apiCallback);
    } else {
      updateAdherenceTracking(rows, time).then(apiCallback);
    }
  };

  const onDateChange = (newSelectedDate) => {
    setSelectedDate(newSelectedDate);
    onSelectedDateChange(newSelectedDate);

    const isToday = isDateInWithInXDay(newSelectedDate, 1);
    if (showGreetingMessage) {
      setShowGreeting(isToday);
    } else {
      setShowGreeting(false);
    }
    dailyMedication.refetch();
  };

  const useDailyMedication = (dayIndex) => {
    return useQuery(
      [
        "DailyMedications",
        formatToStandardDate(getParsedDate(new Date(selectedDate), dayIndex)),
        patientPhoneNumber,
        pageStateHash,
      ],
      getDailyMedicationPageData,
      BASIC_CACHE,
    );
  };

  const { data: missedReasons } = useQuery(
    ["ReasonCodes"],
    getMedicationSkipReasons,
    BASIC_CACHE,
  );

  const pastDailyMedication = useDailyMedication(-1);
  const dailyMedication = useDailyMedication(0);
  const futureDailyMedication = useDailyMedication(+1);

  const preferences = useQuery(
    ["userPreferences", patientPhoneNumber],
    getPreferences,
    BASIC_CACHE,
  );

  const isDateSelectedToday =
    selectedDate.toDateString() === new Date().toDateString();

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("message");

  const handleClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  // Error Management
  const { state } = useContext(Store);

  return (
    <>
      <div className={classes.dateChangeContainer}>
        <DateToggle
          defaultDate={selectedDate}
          onDateChange={onDateChange}
        ></DateToggle>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openSnackBar}
        className={classes.snackbar}
        autoHideDuration={5000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackbarMessage}</span>}
      />
      {dailyMedication.isError && (
        <GlobalApiError
          showErrorDetails={!state.isProduction}
          error={dailyMedication.error}
        />
      )}

      {!dailyMedication.isError && (
        <>
          {readOnly && !preferences.isLoading && (
            <div className={classes.adherenceState}>
              <img src={adherenceIcon} alt="adherenceIcon" />
              <Typography className={classes.enableAdherenceState} variant="h3">
                Adherence tracking&nbsp;
                {preferences.data.enableAdherenceTracking ? "on" : "off"}
              </Typography>
            </div>
          )}

          {showGreeting && isDateSelectedToday && (
            <>
              {dailyMedication.data && (
                <div className={classes.greetingHolder}>
                  <DailyGreeting />
                </div>
              )}
            </>
          )}

          {dailyMedication.isFetching && (
            <div className={classes.loaderContainer}>
              <CircularProgress color="inherit" />
            </div>
          )}

          {!dailyMedication.isFetching && dailyMedication.data && (
            <>
              <div
                data-testid="no-grouped-time-data"
                hidden={
                  Object.keys(dailyMedication.data.groupedTimeData).length
                }
              >
                <NoDailyMedication message={noDataMessage} />
              </div>
              <div
                data-testid="grouped-time-data"
                hidden={
                  !Object.keys(dailyMedication.data.groupedTimeData).length
                }
              >
                {TIME_OPTIONS.map((key, index) => {
                  if (!dailyMedication.data.groupedTimeData[key]) return "";
                  const groupedData = dailyMedication.data.groupedTimeData[key];
                  return (
                    <div className={classes.cardHolder} key={key}>
                      <CardErrorBoundary
                        message={UI_ERROR_MESSAGES.medicationDetailsDisplay}
                      >
                        <MedicalCard
                          key={generateHash(JSON.stringify(groupedData.rows))}
                          title={key}
                          index={index}
                          medicationDate={selectedDate}
                          expanded={groupedData.expanded}
                          reminderTime={groupedData.reminderTiming}
                          rows={groupedData.rows}
                          enableAdherenceTracking={
                            key !== "TAKE AS PRESCRIBED" &&
                            preferences.data?.enableAdherenceTracking
                          }
                          enableReminderTracking={
                            key !== "TAKE AS PRESCRIBED" &&
                            preferences.data?.enableReminderTracking
                          }
                          adherenceTrackingResponse={(v, isRefetch) => {
                            updateAdherence(v)
                              .then((_) => {
                                if (isRefetch) dailyMedication.refetch();
                              })
                              .catch((_) => {
                                dailyMedication.remove();
                                dailyMedication.refetch();
                                setSnackbarMessage(
                                  "We are having trouble processing your request, please try again later",
                                );
                                setOpenSnackBar(true);
                              });
                          }}
                          onAdherenceTrackingClick={onAdherenceTrackingClick}
                          onReminderTrackingClick={onReminderTrackingClick}
                          showMissedReason={showMissedReason}
                          readOnly={readOnly}
                          missedReasons={missedReasons?.data}
                        ></MedicalCard>
                      </CardErrorBoundary>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {!dailyMedication.isFetching && dailyMedication.data && (
            <div
              hidden={
                !dailyMedication.data.groupedCadenceData["AS NEEDED"].length
              }
            >
              <div className={classes.cardHolder}>
                <CardErrorBoundary
                  message={UI_ERROR_MESSAGES.medicationDetailsDisplay}
                >
                  <MedicalCard
                    title="As needed"
                    key="asNeeded"
                    expanded={true}
                    index={5}
                    enableAdherenceTracking={false}
                    enableReminderTracking={false}
                    rows={dailyMedication.data.groupedCadenceData["AS NEEDED"]}
                    readOnly={readOnly}
                    missedReasons={missedReasons?.data}
                  ></MedicalCard>
                </CardErrorBoundary>
              </div>
            </div>
          )}
        </>
      )}

      {isUpdateAdherenceTracking && (
        <UpdateAdherenceTime
          onClose={() => setIsUpdateAdherenceTracking(false)}
          onSave={handleUpdateTimeSave}
          currentTime={updateTimeData}
        />
      )}
      {isUpdateReminderTracking && (
        <UpdateReminderTime
          onClose={() => setIsUpdateReminderTracking(false)}
          onSave={handleUpdateTimeSave}
          currentTime={updateTimeData}
        />
      )}
    </>
  );
}

DailyMedicationView.propTypes = {
  patientPhoneNumber: PropTypes.string,
  onSelectedDateChange: PropTypes.func,
  defaultDate: PropTypes.object,
  readOnly: PropTypes.bool,
  showMissedReason: PropTypes.bool,
  showGreetingMessage: PropTypes.bool,
  noDataMessage: PropTypes.string,
};

DailyMedicationView.defaultProps = {
  patientPhoneNumber: "{current}",
  onSelectedDateChange: () => {},
  defaultDate: new Date(),
  readOnly: false,
  showMissedReason: false,
  showGreetingMessage: true,
  noDataMessage: "no data",
};

export default DailyMedicationView;
