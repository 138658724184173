import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/icons";
import { Store } from "context/Context";
import { clinicianRoutes } from "helpers/routeConfig";
import ApplicationBar from "pages/common/ApplicationBar/ApplicationBar";
import DeliveryDetails from "pages/patient/PatientHome/DeliveryDetails/DeliveryDetails";
import { useContext, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { getMobileOperatingSystem } from "utility";

import { PatientDetails } from "./Patients/PatientDetails/PatientDetails";
import Patients from "./Patients/Patients";
import Referrals from "./Referrals/Referrals";
import SideNavigation from "./sideNavigation/SideNavigation";
import SupportViewComponent from "./SupportViewComponent/SupportViewComponent";

const useStyles = makeStyles((theme) => ({
  clinicianAppContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
    alignItems: "center",
    paddingBottom:
      getMobileOperatingSystem() === "Android" &&
      getMobileOperatingSystem() !== "iOS"
        ? theme.customMixins.pxToRem(16 * 3)
        : theme.customMixins.pxToRem(0),
    background: theme.palette.gradient.linear,
    "&> :nth-child(2)": {
      flexGrow: 1,
    },
  },
  "@supports (-webkit-touch-callout: none)": {
    clinicianAppContainer: {
      height: "87vh",
    },
    background: theme.palette.gradient.linear,
  },
  hamburger: {
    color: theme.palette.white.high,
  },
}));

function ClinicianHome() {
  const classes = useStyles();
  const { state } = useContext(Store);
  const [sideNameOpen, setSideNameOpen] = useState(false);

  return (
    <div className={classes.clinicianAppContainer} data-testid="clinician-view">
      <Drawer
        anchor="left"
        open={sideNameOpen}
        onClose={() => setSideNameOpen(false)}
        data-testid="drawer"
      >
        <SideNavigation onRequestClose={() => setSideNameOpen(false)} />
      </Drawer>
      <ApplicationBar title={state.title}>
        {!state.showBackButton && (
          <Menu
            className={classes.hamburger}
            onClick={() => setSideNameOpen(true)}
            data-testid="hamburger"
          />
        )}
      </ApplicationBar>
      <Switch>
        <Route component={Patients} path={clinicianRoutes.patientView} exact />
        <Route
          component={PatientDetails}
          path={clinicianRoutes.patientDetail}
          exact
        />
        <Route
          component={SupportViewComponent}
          path={clinicianRoutes.support}
          exact
        />
        <Route component={Referrals} path={clinicianRoutes.referrals} />
        <Route
          component={DeliveryDetails}
          path={`${clinicianRoutes.patientDetail}/shipment/:shipmentId`}
        />
        <Redirect to={clinicianRoutes.patientView} />
      </Switch>
    </div>
  );
}

export default ClinicianHome;
