const getParsedDate = (day) => {
  return new Date(new Date().setDate(new Date().getDate() + day))
    .toISOString()
    .split("T")[0];
};

const ARRIVING = [
  {
    latestStatus: "PROCESSING", // Required [PROCESSING, SHIPPED, DELIVERED]
    deliveryDate: null, // Required
    // shipmentId: new Date().toISOString(), //Question
    shipmentId: 123,
    firstName: null,
    lastName: null,
    address: {
      // Required
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      zipcode: null,
    },
    deliveryEvents: [
      {
        status: "Processing", // Required
        dateOfEvent: null, //
        note: null,
      },
      {
        status: "SHIPPING", // Required ["SHIPPING", "SHIPPED", "DELIVERY", "DELIVERED"]
        dateOfEvent: null, // null
        note: null,
      },
    ],
  },
  {
    latestStatus: "SHIPPED",
    deliveryDate: new Date(new Date().setDate(new Date().getDate() + 2))
      .toISOString()
      .split("T")[0],
    shipmentId: 124,
    firstName: "John",
    lastName: "Doe",
    address: {
      addressLine1: "1234 Broadway",
      addressLine2: "Bldg C APT 3",
      city: "Westville",
      state: "NJ",
      zipcode: "8093",
    },
    deliveryEvents: [
      {
        status: "Processing",
        dateOfEvent: "2021/8/2",
        note: null,
      },
      {
        status: "Shipped",
        dateOfEvent: "2021/8/16",
        note: null,
      },
    ],
  },
];

const DELIVERED = [
  {
    latestStatus: "Delivered",
    deliveryDate: "2021-11-04",
    shipmentId: 24999540,
    firstName: "Eleanor",
    lastName: "Martin",
    address: {
      addressLine1: "2723 Shipley Rd",
      addressLine2: "",
      city: "Wilmington",
      state: "DE",
      zipcode: 19810,
    },
    deliveryEvents: [
      {
        status: "Processing",
        dateOfEvent: "2021-11-03T12:12:00",
      },
      {
        status: "Shipped",
        dateOfEvent: "2021-11-04T03:54:00",
      },
      {
        status: "Delivered",
        dateOfEvent: "2021-11-04T12:46:00",
        note: "Jh",
      },
    ],
  },
  {
    latestStatus: "DELIVERED",
    deliveryDate: getParsedDate(-1),
    shipmentId: 125,
    firstName: null,
    lastName: "Last name",
    address: {
      addressLine1: "1234 Broadway",
      addressLine2: "Bldg C APT 3",
      city: "Westville",
      state: "NJ",
      zipcode: "8093",
    },
    deliveryEvents: [
      {
        status: "Processing",
        dateOfEvent: getParsedDate(-10),
        note: null,
      },
      {
        status: "Shipped",
        dateOfEvent: getParsedDate(-1),
        note: "This a sample note. It can grow into multiple lines",
      },
    ],
  },
  {
    latestStatus: "DELIVERED",
    deliveryDate: getParsedDate(0),
    shipmentId: 126,
    firstName: "John",
    lastName: "Doe",
    address: {
      addressLine1: "1234 Broadway",
      addressLine2: "Bldg C APT 3",
      city: "Westville",
      state: "NJ",
      zipcode: "8093",
    },
    deliveryEvents: [
      {
        status: "Processing",
        dateOfEvent: getParsedDate(10),
        note: null,
      },
      {
        status: "Shipped",
        dateOfEvent: getParsedDate(0),
        note: "This a sample note. It can grow into multiple lines",
      },
    ],
  },
  {
    latestStatus: "DELIVERED",
    deliveryDate: getParsedDate(-2),
    shipmentId: 127,
    firstName: "John",
    lastName: "Doe",
    address: {
      addressLine1: "1234 Broadway",
      addressLine2: "Bldg C APT 3",
      city: "Westville",
      state: "NJ",
      zipcode: "8093",
    },
    deliveryEvents: [
      {
        status: "Processing",
        dateOfEvent: getParsedDate(-10),
        note: null,
      },
      {
        status: "Shipped",
        dateOfEvent: getParsedDate(-2),
        note: "This a sample note. It can grow into multiple lines",
      },
      {
        status: "delivered",
        dateOfEvent: getParsedDate(-1),
        note: "This a sample note. It can grow into multiple lines",
      },
    ],
  },
];

export const DELIVERY_RESPONSE = {
  data: {
    totalNoOfRecords: 12,
    currentPageNumber: 1,
    nextPageNumber: 2,
    limit: 5,
    deliveries: [...ARRIVING, ...DELIVERED],
  },
};
