import { DialogContent, DialogTitle, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { CONFIGURATION } from "constants/configuration";
import { useState } from "react";

import PhoneIcon from "./phone.svg";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    margin: theme.customMixins.pxToRem(8, 0),
    width: "100%",
    boxShadow: "none",
  },
  questionButton: {
    margin: theme.customMixins.pxToRem(8, 0),
    color: theme.palette.primary.main,
    width: "100%",
    boxShadow: "none",
  },
  contactInfoContainer: {
    display: "flex",
    alignItems: "center",
  },
  contactTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    "& h3": {
      font: theme.typography.sectionSubtitle.bold.font,
      color: theme.palette.textPrimary.emphasisLow,
      marginBottom: theme.customMixins.pxToRem(0),
      marginTop: theme.customMixins.pxToRem(0),
    },
    "& svg": {
      marginLeft: "0.2rem",
      color: theme.palette.textPrimary.emphasisLow,
    },
  },
  contactIcon: {
    marginRight: theme.customMixins.pxToRem(8),
  },
  contactNumber: {
    font: theme.typography.body.medium.font,
    textDecoration: "none",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

function DeliveryCardFooter({ showDetailsButton, showDetailsHandler }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpenToggle = () => setOpen((v) => !v);
  return (
    <Grid container justifyContent="space-around" alignItems="center">
      {showDetailsButton && (
        <Grid item xs={12}>
          <Button
            className={classes.actionButton}
            variant="contained"
            color="primary"
            onClick={showDetailsHandler}
          >
            View medication
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          data-testid="question-button"
          onClick={handleOpenToggle}
          className={classes.questionButton}
          variant="outlined"
        >
          Question about delivery?
        </Button>
        <Dialog data-testid="dialog-box" onClose={handleOpenToggle} open={open}>
          <DialogTitle onClose={handleOpenToggle}>
            <div className={classes.contactTitleContainer}>
              <div>
                <Typography color="primary" variant="h3">
                  Question about your delivery?
                </Typography>
                <Typography color="primary" variant="h3">
                  Contact us at
                </Typography>
              </div>
              <CloseIcon
                data-testid="dialog-box-close"
                onClick={handleOpenToggle}
                style={{ cursor: "pointer" }}
              />
            </div>
          </DialogTitle>
          <DialogContent>
            <div className={classes.contactInfoContainer}>
              <img
                className={classes.contactIcon}
                src={PhoneIcon}
                alt="PhoneIcon"
              ></img>
              <Typography
                className={classes.contactNumber}
                color="primary"
                variant="h2"
              >
                <a href={"tel:" + CONFIGURATION.pharmacyContact.call}>
                  {CONFIGURATION.pharmacyContact.display}
                </a>
              </Typography>
            </div>
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
}

export default DeliveryCardFooter;
