export const sortReferrals = (refs, searched, filters, sort) => {
  if (searched !== "") {
    refs = refs.filter((ref) => {
      return (
        ref.referralFirstName.toLowerCase().includes(searched.toLowerCase()) ||
        ref.referralLastName?.toLowerCase().includes(searched.toLowerCase())
      );
    });
  }

  Object.keys(filters).forEach((k) => {
    refs = refs.filter((ref) => {
      return ref[k] === filters[k];
    });
  });

  if (sort === "Name A – Z") {
    return refs.sort((a, b) => {
      if (a.referralLastName?.toLowerCase() < b.referralLastName?.toLowerCase())
        return -1;
      if (a.referralLastName?.toLowerCase() > b.referralLastName?.toLowerCase())
        return 1;
      return 0;
    });
  } else if (sort === "New – Old") {
    return refs.sort((a, b) => {
      return b.latestUpdateAt - a.latestUpdateAt;
    });
  } else {
    return refs;
  }
};
