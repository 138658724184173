import { PREFERENCE_SAMPLE } from "samples/preferences";

import { get, put } from "../communication";

export const ROUTE_URL = "/med-mgmt/member/{current}/preferences";
export const API_DEV_HASH = "devMode##getUserPreferences";

const processPreferenceResponse = (respArray) => {
  let response = {
    enableAdherenceTracking: false,
    enableReminderTracking: false,
    enableForgotReminder: false,
    forgotReminderValue: 0,
    enableShippingNotifications: false,
  };

  for (let index = 0; index < respArray.length; index++) {
    const item = respArray[index];
    if (item.preferenceType === "ADHERENCE") {
      response.enableAdherenceTracking = !!item.preferenceValue;
    } else if (item.preferenceType === "REMINDER") {
      response.enableReminderTracking = !!item.preferenceValue;
    } else if (item.preferenceType === "FORGOT_REMINDER") {
      const value = !!parseInt(item.preferenceValue);
      response.enableForgotReminder = value;
      response.forgotReminderValue = parseInt(item.preferenceValue);
    } else if (item.preferenceType === "SHIPPING_NOTIFICATION") {
      response.enableShippingNotifications = !!item.preferenceValue;
    }
  }

  return response;
};

export const getUserPreferences = (user = "{current}") => {
  if (localStorage.getItem(API_DEV_HASH) === "true") {
    return new Promise((resolve) => {
      const data = processPreferenceResponse(PREFERENCE_SAMPLE);
      setTimeout(() => {
        resolve(data);
      }, 2 * 1000);
    });
  } else {
    return get(ROUTE_URL.replace("{current}", user)).then((response) =>
      Promise.resolve(processPreferenceResponse(response.data)),
    );
  }
};

export const updateUserPreferences = (data) => {
  let apiPromise;

  if (localStorage.getItem(API_DEV_HASH) === "true") {
    console.log({ data });
    apiPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: {} });
      }, 1000);
    });
  } else {
    // Todo: Remove After demo
    apiPromise = put(ROUTE_URL, data);
  }

  return apiPromise;
};
