import { DELIVERY_RESPONSE } from "samples/delivery";

import { fetchCustomPayload, get } from "../communication";
import { allDeliveriesSchema } from "./schema/getAllDeliveries.schema";

export const ROUTE_URL = "/med-mgmt/member/{current}/deliveries";
export const API_DEV_HASH = "devMode##getAllDeliveries";

export const getAllDeliveries = (page, limit = 4, user = "{current}") => {
  if (localStorage.getItem(API_DEV_HASH) === "true") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(fetchCustomPayload(API_DEV_HASH, DELIVERY_RESPONSE.data));
      }, 1000);
    });
  } else {
    return get(
      ROUTE_URL.replace("{current}", user),
      {
        page,
        limit,
      },
      allDeliveriesSchema,
    ).then((response) => Promise.resolve(response.data));
  }
};
