export function groupBy(array, keys) {
  let grouped = {};
  if (!Array.isArray(array)) return {};
  array.forEach((a) => {
    keys
      .reduce((o, g, i) => {
        o[a[g]] = o[a[g]] || (i + 1 === keys.length ? [] : {});
        return o[a[g]];
      }, grouped)
      .push(a);
  });
  return grouped;
}
