import { EventType } from "@azure/msal-browser";

import { postLog, userEnrollmentRequest } from "./api";
import { manageSessionOnAuthErrors } from "./authErrors";

export const clearSessionAndReloadWindow = (timeoutInSeconds) => {
  setTimeout(() => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
  }, timeoutInSeconds);
};

export default function init(msalInstance, dispatch) {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    if (accounts[0].idTokenClaims.extension_Type) {
      msalInstance.setActiveAccount(accounts[0]);
      msalInstance
        .acquireTokenSilent({ scopes: [] })
        .then((res) => {
          dispatch({ type: "ADD_TOKEN", token: res.idToken });
          dispatch({
            type: "SET_USER_TYPE",
            userType: accounts[0].idTokenClaims.extension_Type,
          });
          localStorage.setItem("idToken", res.idToken);
          dispatch({ type: "TOGGLE_LOADER", loading: false });
        })
        .catch((error) => {
          // TODO: Needs to be tested
          manageSessionOnAuthErrors(msalInstance, error);
        });
    } else {
      clearSessionAndReloadWindow(10000);
    }
  }

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      postLog("login");
      console.log("I GOT MY ACCOUNT!!!!!!!!!!!!!!!!!");
      console.log("payload: ", event.payload);
      if (event.payload.idTokenClaims.extension_Type) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
        dispatch({ type: "ADD_TOKEN", token: event.payload.idToken });
        dispatch({
          type: "SET_USER_TYPE",
          userType: event.payload.idTokenClaims.extension_Type,
        });
        // dispatch({ type: "SET_USER_TYPE", userType: 'CLINICIAN' });
        localStorage.setItem("idToken", event.payload.idToken);
        dispatch({ type: "TOGGLE_LOADER", loading: false });
      } else {
        localStorage.setItem("idToken", event.payload.idToken);
        userEnrollmentRequest()
          .then((enrollmentResponse) => {
            console.log({ enrollmentResponse });
            setTimeout(() => {
              sessionStorage.clear();
              window.location.reload();
            }, 10000);
          })
          .catch((enrollmentError) => {
            const status = Number(enrollmentError?.response?.status);
            let globalError = "Unknown Error: Failed to register user.";
            console.error({ enrollmentError, status, globalError });

            dispatch({ type: "TOGGLE_LOADER", loading: false });
            dispatch({
              type: "SET_GLOBAL_ERROR",
              globalError: enrollmentError,
            });
          });
      }
    }
  });
}
