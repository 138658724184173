export const referralFormDefaultInputValues = {
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  dateOfBirth: "",
  phoneNumber: "",
  email: null,
  preferredLanguage: "English",
  numberOfMedications: null,
  comment: "",
  preferredContactTime: "Any time",
  careGiverFirstName: "",
  careGiverLastName: "",
  careGiverPhoneNumber: "",
  careGiverPatientRelationship: "",
  isCareGiverPreferredContact: false,
};

export const careGiverRelationshipOptions = [
  "Self",
  "Caregiver",
  "Son",
  "Daughter",
  "Brother",
  "Sister",
  "Parent",
  "Spouse",
  "Daughter in law",
  "Son in law",
  "Other",
];
