export const FONT_SIZES = {
  display: 64,
  title: 48,
  subtitle: 36,
  sectionTitle: 24,
  sectionSubtitle: 18,
  body: 16,
  description: 14,
  legal: 12,
  label: 10,
};

export const FONT_WEIGHTS = {
  regular: 400,
  medium: 500,
  bold: 700,
  link: 400,
};

const FONT_FAMILIES = ["Inter", "Helvetica", "Arial", "sans-serif"];

const generateTypographyVariations = () => {
  let allTypography = {};
  for (const sizeKey in FONT_SIZES) {
    const size = FONT_SIZES[sizeKey];
    let typography = {};
    for (const weightKey in FONT_WEIGHTS) {
      const weight = FONT_WEIGHTS[weightKey];
      const comment = `/* ${sizeKey} ${weightKey} */`;
      typography = {
        ...typography,
        [weightKey]: { font: `${weight} ${size}px Inter ${comment}` },
      };
    }
    allTypography = { ...allTypography, [sizeKey]: typography };
  }
  return allTypography;
};

export const TYPOGRAPHY = {
  fontFamily: FONT_FAMILIES.join(","),
  ...generateTypographyVariations(),
  button: {
    textTransform: "none",
  },
};
