import { Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { Store } from "context/Context";
import {
  getPatientProfile,
  setHighRiskStatus,
  setMyPatientStatus,
} from "helpers/api";
import GlobalApiError from "pages/common/ErrorScreens/GlobalApiError";
import SimplePageLoader from "pages/common/PageLoaders/SimplePageLoader";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { generateFullName } from "utility";

import PhoneIcon from "./assets/phone.svg";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  separator: {
    margin: theme.customMixins.pxToRem(16, 0),
  },
  phoneContainer: {
    marginBottom: theme.customMixins.pxToRem(20),
  },
  phoneTitleContainer: {
    marginBottom: theme.customMixins.pxToRem(8),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& > img": {
      marginRight: theme.customMixins.pxToRem(8),
    },
    "& > h4": {
      display: "inline-block",
      font: theme.typography.sectionTitle.regular.font,
      color: theme.palette.primary.A900,
    },
  },
  phoneDetails: {
    color: theme.palette.textPrimary.emphasisLow,
    "& > a": {
      color: theme.palette.primary.A900,
      marginTop: theme.customMixins.pxToRem(4),

      font: theme.typography.body.regular.font,
      textDecoration: "none",
    },
  },
  userName: {
    color: theme.palette.primary.A900,
    font: theme.typography.sectionSubtitle.bold.font,
  },
  settingsContainer: {
    marginBottom: theme.customMixins.pxToRem(24),
    "& > p": {
      color: theme.palette.textPrimary.emphasisLow,
    },
  },
  toggleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > h2": {
      color: theme.palette.primary.A900,
      font: theme.typography.sectionSubtitle.medium.font,
    },
  },
  snackbar: theme.customComponents.snackbar.error,
}));

function PatientProfile({
  patientPhoneNumber,
  patientFirstName,
  patientLastName,
}) {
  const classes = useStyles();
  const [isHighRisk, setIsHighRisk] = useState(true);
  const [isHighRiskDisabled, setIsHighRiskDisabled] = useState(true);
  const { state } = useContext(Store);
  const [isMyPatient, setIsMyPatient] = useState(true);
  const [isMyPatientDisabled, setIsMyPatientDisabled] = useState(true);

  const patientProfile = useQuery(
    ["patientProfile", patientPhoneNumber],
    (args) => {
      return getPatientProfile(args.queryKey[1]).then((profile) => {
        console.log({ profile });
        if (isHighRisk !== profile.isHighRisk)
          setIsHighRisk(profile.isHighRisk);
        if (isMyPatient !== profile.isMyPatient)
          setIsMyPatient(profile.isMyPatient);
        if (isMyPatientDisabled) setIsMyPatientDisabled(false);
        if (isHighRiskDisabled) setIsHighRiskDisabled(false);
        return Promise.resolve(profile);
      });
    },
  );

  const handleIsHighRisk = (v) => {
    const newValue = !isHighRisk;

    setIsHighRiskDisabled(true);
    setHighRiskStatus(patientPhoneNumber, newValue)
      .then((_) => {
        setIsHighRisk((v) => !v);
      })
      .catch((error) => {
        console.error({ error });
      })
      .finally((_) => {
        patientProfile.refetch();
      });
  };

  const handleIsMyPatient = (v) => {
    const newValue = !isMyPatient;
    setIsMyPatientDisabled(true);
    setMyPatientStatus(patientPhoneNumber, newValue)
      .then((_) => {
        setIsMyPatient((v) => !v);
      })
      .catch((error) => {
        console.error({ error });
      })
      .finally((_) => {
        patientProfile.refetch();
      });
  };

  return (
    <div>
      {patientProfile.isError && (
        <GlobalApiError
          showErrorDetails={!state.isProduction}
          error={patientProfile.error}
        />
      )}
      <SimplePageLoader hidden={!patientProfile.isLoading} />
      {!patientProfile.isError && !patientProfile.isLoading && (
        <div data-testid="root-container" className={classes.rootContainer}>
          <Typography className={classes.userName} variant="h2">
            {generateFullName({
              firstName: patientFirstName,
              lastName: patientLastName,
            })}
          </Typography>

          <Divider className={classes.separator} />

          <div className={classes.phoneContainer}>
            <div className={classes.phoneTitleContainer}>
              <img src={PhoneIcon} alt="PhoneIcon" />
              <Typography variant="h4" color="textPrimary">
                Call
              </Typography>
            </div>

            <div className={classes.phoneDetailsContainer}>
              <Typography
                className={classes.phoneDetails}
                variant="body1"
                color="textPrimary"
              >
                Patient phone number&nbsp;
                <a
                  className={classes.phoneNumber}
                  href={"tel:" + patientPhoneNumber}
                >
                  {patientPhoneNumber}
                </a>
              </Typography>
            </div>
          </div>

          <SimplePageLoader hidden={!patientProfile.loading} />

          {patientProfile.data && (
            <div className={classes.settingsContainer}>
              <div className={classes.toggleContainer}>
                <Typography
                  className={classes.title}
                  color="primary"
                  variant="h2"
                >
                  This is my patient
                </Typography>
                <Switch
                  name="isMyPatient"
                  checked={isMyPatient}
                  onChange={handleIsMyPatient}
                  disabled={isMyPatientDisabled}
                  color="primary"
                />
              </div>
              <Typography color="primary" variant="body1">
                Patients marked “This is my patient” will appear in the “My
                patients” section.
                <br />
                <br />
                This selection will not impact the view of other clinicians.
              </Typography>
            </div>
          )}

          {patientProfile.data && (
            <div className={classes.settingsContainer}>
              <div className={classes.toggleContainer}>
                <Typography color="primary" variant="h2">
                  This patient is high risk
                </Typography>
                <Switch
                  name="isHighRisk"
                  checked={isHighRisk}
                  onChange={handleIsHighRisk}
                  disabled={isHighRiskDisabled}
                  color="primary"
                />
              </div>
              <Typography color="primary" variant="body1">
                This patient’s medicine adherence should be closely monitored
                for non-compliance.
                <br />
                <br />
                This selection will not impact the view of other clinicians.
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PatientProfile;
