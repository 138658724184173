import { Card, CardContent, Divider } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import { patientRoutes } from "helpers/routeConfig";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  checkIfFutureDate,
  getEpochTime,
  isDateInWithInXDay,
  checkPastFuture,
  TimeUtility,
} from "utility";

import { getDailyMedicationSample } from "./../../../samples/dailyMedications";
import { getCardAdherenceState } from "./helper";
import MedicalCardRow from "./MedicalCardRow";
import MedicalCardTitle from "./MedicalCardTitle";
import MedicationCardBadges from "./MedicationCardBadges";
import MedicationCardFooterAction from "./MedicationCardFooterAction";

const timeUtility = new TimeUtility();

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: theme.customMixins.pxToRem(0),
    },
  },
  cardTitle: {
    marginBottom: theme.customMixins.pxToRem(0),
    marginTop: theme.customMixins.pxToRem(14),
  },
  mainDivider: {
    margin: theme.customMixins.pxToRem(2, 0),
    height: theme.customMixins.pxToRem(2),
    backgroundColor: theme.palette.secondary.A700,
  },
  accordionSummary: {
    padding: theme.customMixins.pxToRem(0),
    margin: theme.customMixins.pxToRem(0),
    "& .MuiAccordionSummary-content": {
      padding: theme.customMixins.pxToRem(0),
      margin: theme.customMixins.pxToRem(0),
      display: "block",
    },
  },
  accordionDetails: {
    padding: theme.customMixins.pxToRem(0),
    margin: theme.customMixins.pxToRem(0),
    display: "block",
  },
}));

function MedicalCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const rows = Array.isArray(props.rows) ? props.rows : [];

  let isExpandable =
    props.enableAdherenceTracking || props.enableReminderTracking;

  const [cardAdherenceState, setCardAdherenceState] = useState(
    getCardAdherenceState(props),
  );

  function showSkippedMedicationForm() {
    history.push(
      `${patientRoutes.skippedMedication}?date=${rows[0]?.consumptionDate}&time=${rows[0]?.timeOfDay}`,
    );
  }

  function setSelectedMedicine(medication) {
    history.push(
      patientRoutes.viewMedication + `?code=${medication.drugCode}`,
      {
        drugCode: medication.drugCode,
        drugName: medication.drugName,
        consumptionDate: medication.consumptionDate,
        splId: medication.splId,
      },
    );
  }

  const updateAdherenceAsPositive = (medications, isRefetch = true) => {
    if (!(Array.isArray(medications) && medications.length > 0)) return;

    // Taken Time's date should be same as consumptionDate
    const newTakenTime = new Date();
    const consumptionDate = rows[0].consumptionDate;
    const [yyyy, mm, dd] = consumptionDate.split("-");
    newTakenTime.setMonth(mm - 1);
    newTakenTime.setUTCDate(dd);
    newTakenTime.setFullYear(yyyy);
    const newEpochTakenTime = getEpochTime(newTakenTime);

    const data = medications.map((medRowItem) => {
      const { uniqueIdentifier } = medRowItem;
      const { adherenceTracking } = medRowItem;
      const { takenTime } = adherenceTracking;

      return {
        uniqueIdentifier,
        isTaken: true,
        takenTime: takenTime ? takenTime : newEpochTakenTime,
        missedReasonCode: null,
        missedNotes: null,
        notes: null,
      };
    });

    if (!isRefetch) {
      setCardAdherenceState((adherenceState) => {
        return {
          ...adherenceState,
          skippedCount: adherenceState.skippedCount - 1,
          takenCount: adherenceState.takenCount + 1,
          stateMessage: `(${adherenceState.takenCount + 1}/${
            adherenceState.totalCount
          })`,
        };
      });
    }
    props.adherenceTrackingResponse({ data, consumptionDate }, isRefetch);
  };

  let isExpanded = false;

  if (props.enableAdherenceTracking) {
    isExpanded = props.expanded;
  }
  if (props.enableReminderTracking && !isExpanded) {
    const isToday = isDateInWithInXDay(props.medicationDate, 1);
    isExpanded = isToday && timeUtility.checkTimeInRange(props.reminderTime, 2);
  }

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setTimeout(() => setExpanded(isExpanded), 500);
  }, [isExpanded]);

  const showDosageDetails = !!props.showDosageDetails;
  const showFooterAction =
    props.enableAdherenceTracking &&
    !cardAdherenceState.isActionTaken &&
    !props.readOnly;

  function isOverdue(reminderTime) {
    if (cardAdherenceState.isActionTaken) return false;
    // check if it is today
    const todayObj = new Date();
    const days = checkPastFuture(todayObj, props.medicationDate);
    if (days === 0) {
      return timeUtility.checkTimeOverdue(reminderTime, 1);
    } else if (days < 0) {
      return true;
    }
  }

  const rowsJsx = rows.map((rowData, rowIndex) => {
    const isUserActionReceived =
      rowData.adherenceTracking && rowData.adherenceTracking.isTaken !== null;
    const showLabeling = props.enableAdherenceTracking && isUserActionReceived;
    return (
      <MedicalCardRow
        key={rowData.drugCode}
        rowData={rowData}
        showDetails={(medication) => setSelectedMedicine(medication)}
        showDosageDetails={showDosageDetails}
        showLabeling={showLabeling}
        showRowSeparators={props.showRowSeparators}
        onLabelClicked={(value) =>
          value
            ? showSkippedMedicationForm()
            : updateAdherenceAsPositive([rowData], false)
        }
        showMissedReason={
          props.enableAdherenceTracking && props.showMissedReason
        }
        readOnly={props.readOnly}
        missedReasons={props.missedReasons}
      ></MedicalCardRow>
    );
  });

  if (!isExpandable) {
    return (
      <Card data-testid="card" square={true} key={props.id} variant="elevation">
        <MedicalCardTitle
          key={props.title}
          title={props.title}
          showIcons={!isExpandable}
          enableAdherenceTracking={props.enableAdherenceTracking}
          enableReminderTracking={props.enableReminderTracking}
          cardAdherenceState={cardAdherenceState}
        ></MedicalCardTitle>
        {props.title && (
          <Divider className={classes.mainDivider} variant="middle" />
        )}
        <CardContent className={classes.cardContent}>{rowsJsx}</CardContent>
      </Card>
    );
  } else {
    return (
      <Accordion data-testid="accordion" expanded={expanded} square={true}>
        <AccordionSummary className={classes.accordionSummary}>
          <MedicalCardTitle
            title={props.title}
            expanded={expanded}
            key={props.title}
            reminderTime={props.reminderTime}
            showIcons={!isExpandable}
            toggleExpansion={() => setExpanded((v) => !v)}
            enableAdherenceTracking={props.enableAdherenceTracking}
            enableReminderTracking={props.enableReminderTracking}
            cardAdherenceState={cardAdherenceState}
            consumptionDate={rows[0]?.consumptionDate}
          ></MedicalCardTitle>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.cardContent}>
            <MedicationCardBadges
              overdue={isOverdue(props.reminderTime)}
              enableAdherenceTracking={props.enableAdherenceTracking}
              enableReminderTracking={props.enableReminderTracking}
              onAdherenceTrackingClick={() =>
                props.onAdherenceTrackingClick({
                  isReminderUpdate: false,
                  time: cardAdherenceState.epochTime,
                  timeOfDay: props.title,
                  rows: rows,
                })
              }
              onReminderTrackingClick={() =>
                props.onReminderTrackingClick({
                  isReminderUpdate: true,
                  time: props.reminderTime,
                  timeOfDay: props.title,
                  rows: rows,
                })
              }
              consumptionDate={rows[0]?.consumptionDate}
              reminderTime={props.reminderTime}
              takenTime={cardAdherenceState.takenTime}
            ></MedicationCardBadges>

            <Divider className={classes.mainDivider} variant="middle" />

            {rowsJsx}

            {showFooterAction && (
              <MedicationCardFooterAction
                onAccept={() => updateAdherenceAsPositive(rows)}
                onReject={showSkippedMedicationForm}
                isDisabled={checkIfFutureDate(props.medicationDate)}
              ></MedicationCardFooterAction>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
}

MedicalCard.defaultProps = {
  medicationDate: new Date(),
  id: null,
  rows: [...getDailyMedicationSample("2021-11-11").medications],
  title: "Medical Card",
  expanded: false,
  enableAdherenceTracking: false,
  enableReminderTracking: false,
  showRowSeparators: false,
  onAdherenceTrackingClick: console.table,
  onReminderTrackingClick: console.table,
  adherenceTrackingResponse: console.table,
  showMissedReason: false,
  readOnly: false,
  missedReasons: {},
};

MedicalCard.propTypes = {
  /**
   * Medication Date
   */
  medicationDate: PropTypes.instanceOf(Date),

  /**
   * Card Title Text
   */
  title: PropTypes.string,

  /**
   *  Medication list
   */
  rows: PropTypes.array,

  /**
   * Epoch Time to be shown in reminder Batch
   */
  reminderTime: PropTypes.number,

  /**
   * Is card expanded by default?
   *
   * Applicable for expandable version of card.
   */
  expanded: PropTypes.bool,

  /**
   * Show row separators?
   */
  showRowSeparators: PropTypes.bool,

  /**
   * Turn on Adherence Tracking Mode
   */
  enableAdherenceTracking: PropTypes.bool,

  /**
   * Turn on Reminder Tracking Mode
   */
  enableReminderTracking: PropTypes.bool,

  /**
   * Function called when a [Adherence Tracking] action is triggered.
   */
  onAdherenceTrackingClick: PropTypes.func,

  /**
   * Function called when a [Reminder Tracking] action is triggered.
   */
  onReminderTrackingClick: PropTypes.func,

  /**
   * Function called when a [Adherence Tracking update] action is triggered.
   */
  adherenceTrackingResponse: PropTypes.func,

  /**
   * is showing missed Reasons for medications skipped?
   */
  showMissedReason: PropTypes.bool,

  /**
   * Is card clickable to respond to actions actions?
   */
  readOnly: PropTypes.bool,

  /**
   * List of missed reasons to choose from.
   */
  missedReasons: PropTypes.object,
};

export default MedicalCard;
