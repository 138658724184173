import { DialogContent, DialogTitle, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialogTitle-root": {
      padding: theme.customMixins.pxToRem(16, 0),
    },
    "& .MuiDialogContent-root": {
      paddingTop: 0,
    },
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > h3": {
      font: theme.typography.sectionSubtitle.bold.font,
      color: theme.palette.textPrimary.emphasisHigh,
    },
  },
  description: {
    font: theme.typography.body.font,
    color: theme.palette.textPrimary.emphasisLow,
  },
  closeIcon: {
    cursor: "pointer",
  },
  buttons: {
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  backButton: {
    margin: theme.customMixins.pxToRem(8, 0),
    color: theme.palette.primary.A700,
    boxShadow: "none",
  },
  leaveButton: {
    margin: theme.customMixins.pxToRem(8, 0),
    backgroundColor: theme.palette.primary.A700,
    color: theme.palette.white.high,
    boxShadow: "none",
  },
}));

const DiscardPrompt = (props) => {
  const classes = useStyles();

  const onCancel = () => props.cleanUp(false);
  const onDiscardChanges = () => props.cleanUp(true);

  return (
    <Dialog
      data-testid="dialog-container"
      open={props.open}
      onClose={onCancel}
      className={classes.dialog}
    >
      <DialogContent>
        <DialogTitle onClose={onCancel}>
          <div className={classes.headerContainer}>
            <Typography data-testid="dialog-title" variant="h3">
              Discard changes
            </Typography>
            <CloseIcon className={classes.closeIcon} onClick={onCancel} />
          </div>
        </DialogTitle>
        <Typography
          id="alert-dialog-description"
          className={classes.description}
        >
          {props.message}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <Button
          data-testid="cancel-button"
          onClick={onCancel}
          className={classes.backButton}
          variant="outlined"
        >
          Go back
        </Button>
        <Button
          data-testid="discard-changes-button"
          onClick={onDiscardChanges}
          variant="contained"
          className={classes.leaveButton}
        >
          Discard and leave
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DiscardPrompt.propTypes = {
  /**
   * Message to be shown in discard Prompt
   */
  message: PropTypes.string,

  /**
   * Set Dialog to be open or close
   */
  open: PropTypes.bool,

  /**
   * Function called when a prompt action is triggered.
   * onCancel -> false
   * onDiscardChanges -> false
   */
  cleanUp: PropTypes.func.isRequired,
};

DiscardPrompt.defaultProps = {
  open: true,
  message: "",
  cleanUp: console.log,
};

export default DiscardPrompt;
