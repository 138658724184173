import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CONFIGURATION } from "constants/configuration";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  errorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.customMixins.pxToRem(24),
    flexDirection: "column",
    "& > h6": {
      textAlign: "center",
      ont: theme.typography.sectionSubtitle.medium.font,
      color: theme.palette.primary.A800,
      padding: theme.customMixins.pxToRem(8),
      margin: theme.customMixins.pxToRem(16 * 3, 0),
      "& > a": {
        color: "inherit",
        textDecoration: "none",
      },
    },
  },
  errorMessage: {},
  errorDetails: {
    font: theme.typography.legal.medium.font,
    color: theme.palette.primary.A900,
  },
}));

function GlobalApiError({ hidden, showErrorDetails, error, customMessage }) {
  const classes = useStyles();
  if (hidden) return <></>;

  return (
    <div className={classes.errorContainer}>
      {error && (
        <>
          {customMessage}
          {showErrorDetails && (
            <Typography
              className={classes.errorDetails}
              color="textPrimary"
              variant="h4"
            >
              ERROR CODE: {error.errorCode ? error.errorCode : "UNKNOWN"}
            </Typography>
          )}
        </>
      )}
    </div>
  );
}

GlobalApiError.defaultProps = {
  customMessage: (
    <Typography color="textPrimary" variant="h6">
      Sorry, we encountered a problem.
      <br />
      Please try again later or contact us at&nbsp;
      <a href={"tel:" + CONFIGURATION.pharmacyContact.call}>
        {CONFIGURATION.pharmacyContact.display}
      </a>
    </Typography>
  ),
  showErrorDetails: false,
  hidden: false,
  error: {},
};

GlobalApiError.propTypes = {
  /**
   * Custom Error message
   */
  customMessage: PropTypes.node,

  /**
   * Show Error Details
   */
  showErrorDetails: PropTypes.bool,

  /**
   * Show or hide error message
   */
  hidden: PropTypes.bool,

  /**
   * Error message object
   */
  error: PropTypes.object,
};

export default GlobalApiError;
