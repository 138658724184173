import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { generateFullName } from "utility/stringModifications";

import DeliveryCardAddress from "./DeliveryCardAddress";
import { getFormattedDeliveryDate, getOrdering } from "./helper";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    padding: theme.customMixins.pxToRem(16),
  },
  listItems: {
    borderLeftWidth: theme.customMixins.pxToRem(2),
    borderLeftStyle: "dashed",
    borderLeftColor: "transparent",

    "&:not(:last-child)": {
      borderLeftColor: theme.palette.primary.A400,
      paddingBottom: theme.customMixins.pxToRem(20),
    },
    "& span": {
      border: "2px solid",
      borderColor: theme.palette.textPrimary.emphasisLowest,
      borderRadius: "50%",
      padding: theme.customMixins.pxToRem(0, 3),
      color: theme.palette.white.high,
      marginTop: theme.customMixins.pxToRem(-10),
      marginLeft: theme.customMixins.pxToRem(-11.5),
      background: theme.palette.white.high,
      width: theme.customMixins.pxToRem(20),
      height: theme.customMixins.pxToRem(20),
      display: "inline-block",
    },
    "& h4": {
      font: theme.typography.body.bold.font,
      color: theme.palette.textPrimary.emphasisLow,
      marginBottom: theme.customMixins.pxToRem(8),
      display: "inline-block",
      marginLeft: theme.customMixins.pxToRem(23),
      textTransform: "capitalize",
    },
    "& h5": {
      marginLeft: theme.customMixins.pxToRem(32),
    },
  },
  itemSelected: {
    borderLeftStyle: "solid",
    borderLeftWidth: theme.customMixins.pxToRem(3),
    "& h4": {
      color: theme.palette.textPrimary.emphasisHigh,
    },
    "& span": {
      borderColor: theme.palette.primary.A400,
      color: theme.palette.primary.A400,
      background: theme.palette.primary.A400,
    },
  },
  deliveryDate: {
    font: theme.typography.description.regular.font,
    opacity: "0.6",
    marginBottom: theme.customMixins.pxToRem(8),
  },
  noteOfEventLabel: {
    font: theme.typography.description.bold.font,
    color: theme.palette.primary.A900,
    marginTop: theme.customMixins.pxToRem(16),
  },
  noteOfEvent: {
    font: theme.typography.description.regular.font,
    color: theme.palette.primary.A900,
    marginTop: theme.customMixins.pxToRem(8),
  },
}));

const PROGRESS_BAR_ORDERING = [
  ...["SHIPPING", "SHIPPED"],
  ...["DELIVERY", "DELIVERED"],
];

export function DeliveryCardDetails({ deliveryData }) {
  const classes = useStyles();

  const deliveryEvents = deliveryData.deliveryEvents;
  const deliveryAddress = deliveryData.address;
  const deliveryLatestStatus = deliveryData.latestStatus;

  const ordering = getOrdering(deliveryLatestStatus.toUpperCase());

  const fullName = generateFullName({
    firstName: deliveryData.firstName,
    lastName: deliveryData.lastName,
  });

  let deliveryEventsMap = {};
  for (const deliveryEvent of deliveryEvents)
    deliveryEventsMap[deliveryEvent.status.toUpperCase()] = deliveryEvent;

  return (
    <Grid
      container
      data-testid="list-container"
      className={classes.listContainer}
      justifyContent="space-around"
      alignItems="center"
    >
      {ordering.map((status) => {
        const data = deliveryEventsMap[status];
        const latestStatusIndex = PROGRESS_BAR_ORDERING.indexOf(status);

        let displayDate = null;

        if (data && data.dateOfEvent) {
          displayDate = getFormattedDeliveryDate(data.dateOfEvent);
        } else if (
          deliveryData.deliveryDate &&
          ["DELIVERY", "DELIVERED"].indexOf(status) >= 0
        ) {
          displayDate = getFormattedDeliveryDate(deliveryData.deliveryDate);
        }

        return (
          <Grid
            item
            key={status}
            className={`${classes.listItems} ${
              latestStatusIndex % 2 === 1 ? classes.itemSelected : null
            }`}
            xs={12}
          >
            <span> * </span>
            <Typography color="textPrimary" variant="h4">
              {status.toLowerCase()}
            </Typography>

            {
              <Typography
                className={classes.deliveryDate}
                color="textPrimary"
                variant="h5"
              >
                {displayDate}
              </Typography>
            }

            {["DELIVERY", "DELIVERED"].indexOf(status) >= 0 && (
              <DeliveryCardAddress
                fullName={fullName}
                deliveryAddress={deliveryAddress}
              />
            )}
            {data && ["DELIVERY", "DELIVERED"].indexOf(status) >= 0 && (
              <>
                <Typography
                  hidden={!data.note}
                  className={classes.noteOfEventLabel}
                  color="secondary"
                  variant="h5"
                >
                  Delivery notes
                </Typography>
                <Typography
                  hidden={!data.note}
                  className={classes.noteOfEvent}
                  color="secondary"
                  variant="h5"
                >
                  {data.note}
                </Typography>
              </>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}

export default DeliveryCardDetails;

DeliveryCardDetails.defaultProps = {
  deliveryData: {
    address: {
      addressLine1: "1234 Broadway",
      addressLine2: "Bldg C APT 3",
      city: "Westville",
      state: "NJ",
      zipcode: 80932,
    },
    deliveryDate: null,
    deliveryEvents: [
      { status: "Processing", dateOfEvent: "2021/8/2", note: null },
      { status: "Shipped", dateOfEvent: "2021/8/16", note: null },
    ],
    firstName: "John",
    lastName: "Doe",
    latestStatus: "PROCESSING",
    shipmentId: "1234",
  },
};

DeliveryCardDetails.propTypes = {
  deliveryData: PropTypes.shape({
    address: PropTypes.shape({
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    deliveryEvents: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string.isRequired,
        dateOfEvent: PropTypes.string,
        note: PropTypes.string,
      }).isRequired,
    ).isRequired,
    deliveryDate: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    latestStatus: PropTypes.string.isRequired,
    shipmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  }).isRequired,
};
