import * as Joi from "joi";

const addressData = Joi.object({
  addressLine1: Joi.string().allow(null, ""),
  addressLine2: Joi.string().allow(null, ""),
  city: Joi.string().allow(null, ""),
  state: Joi.string().allow(null, ""),
  zipcode: Joi.number().allow(null),
})
  .required()
  .unknown();

const deliveries = Joi.object({
  latestStatus: Joi.string().required(),
  deliveryDate: Joi.string().allow(null),
  shipmentId: Joi.number().allow(null), //check
  firstName: Joi.string().allow(null, ""),
  lastName: Joi.string().allow(null, ""),
  address: addressData,
  deliveryEvents: Joi.array().items(
    Joi.object({
      status: Joi.string().required(),
      // .valid("Processing", "Shipped", "Delivered"),
      dateOfEvent: Joi.string().allow(null),
      note: Joi.string().allow(null, ""),
    }),
  ),
}).unknown();

export const allDeliveriesSchema = Joi.object({
  deliveries: Joi.array().items(deliveries),
  totalNoOfRecords: Joi.number().allow(null),
  currentPageNumber: Joi.number().allow(null),
  nextPageNumber: Joi.number().allow(null),
  limit: Joi.number().allow(null),
})
  .required()
  .unknown();
