import { ALL_MEDICATIONS } from "samples/allMedications";
import { formatToStandardDate, groupBy, validURL } from "utility";

import { fetchCustomPayload, get } from "../communication";
import { TransformationError } from "./../errors";
import { allMedicationSchema } from "./schema/getAllMedications.schema";

export const ROUTE_URL = "/med-mgmt/member/{current}/medication/all";
export const API_DEV_HASH = "devMode##getAllMedications";

const dataCleaner = (response) => {
  let allMedications = response.map((medication) => {
    if (!validURL(medication.drugImageUrl)) medication.drugImageUrl = null;
    if (["AS NEEDED", "DAILY"].indexOf(medication.consumptionCadence) < 0) {
      medication.consumptionCadence = "LESS FREQUENTLY";
    }
    return medication;
  });

  allMedications = allMedications.sort(function (a, b) {
    a = a.drugName.toLowerCase();
    b = b.drugName.toLowerCase();
    if (a === b) return 0;
    if (a > b) return 1;
    return -1;
  });
  return allMedications;
};

const dataGrouping = (allMedications) => {
  const groupedCardData = groupBy(allMedications, ["consumptionCadence"]);
  for (const cadence of ["DAILY", "LESS FREQUENTLY", "AS NEEDED"]) {
    if (!Array.isArray(groupedCardData[cadence])) {
      groupedCardData[cadence] = [];
    }
  }

  return groupedCardData;
};

export const getAllMedications = async (
  date = new Date(),
  user = "{current}",
) => {
  let response = null;

  if (localStorage.getItem(API_DEV_HASH) === "true") {
    response = await fetchCustomPayload(API_DEV_HASH, {
      data: ALL_MEDICATIONS,
    });
  } else {
    if (date instanceof Date) date = formatToStandardDate(date);
    else date = date.toString();
    response = await get(
      ROUTE_URL.replace("{current}", user),
      {
        date: date,
      },
      API_DEV_HASH,
      allMedicationSchema,
    );
  }
  try {
    const allMedications = dataCleaner(response.data.medications);
    const groupedCardData = dataGrouping(allMedications);
    const count = response.data.medications.length;
    return Promise.resolve({ groupedCardData, count });
  } catch (transformationError) {
    throw new TransformationError(transformationError);
  }
};
