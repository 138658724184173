import { makeStyles, Typography } from "@material-ui/core";
import { CONFIGURATION } from "constants/configuration";
import { CLINICIAN_GUIDE_PDF } from "constants/userGuides";
import { Store } from "context/Context";
import SupportDetails from "pages/common/SupportDetails/SupportDetails";
import { useContext, useEffect } from "react";

import DownloadIcon from "./../assets/icons/download.svg";

const useStyles = makeStyles((theme) => ({
  supportRootContainer: {
    display: "flex",
    overflowY: "auto",
    background: theme.palette.gradient.linear,
    width: "100%",
    justifyContent: "center",
    "& > *": {
      width: "100%",
      maxWidth: theme.pageDimensions.mobileMaxWidth,
      padding: theme.customMixins.pxToRem(24),
    },
  },
}));

export const SupportViewComponent = () => {
  const classes = useStyles();

  const { dispatch } = useContext(Store);

  useEffect(() => {
    dispatch({ type: "APP_BAR_TITLE", title: "Support" });
  }, [dispatch]);

  return (
    <div className={classes.supportRootContainer} data-testid="support-page">
      <SupportDetails
        callDetails={
          <>
            <Typography variant="h4" color="textPrimary">
              Customer service{" "}
              <a href={"tel:" + CONFIGURATION.pharmacyContact.call}>
                {CONFIGURATION.pharmacyContact.display}
              </a>
            </Typography>
            <Typography variant="h4" color="textPrimary">
              HomeFree pharmacy patients Ext. 1
            </Typography>
            <Typography variant="h4" color="textPrimary">
              Referral partner or HomeFree Clinician Ext. 2
            </Typography>
            <Typography variant="h4" color="textPrimary">
              Prescriber or doctors office Ext. 3
            </Typography>
            <Typography variant="h4" color="textPrimary">
              Billing Ext. 4
            </Typography>
          </>
        }
        faxDetails={
          <Typography variant="h4" color="textPrimary">
            Fax number for prescriptions <br />
            <a href="fax: 856-291-7009"> 856-291-7009 </a>
          </Typography>
        }
        timingDetails={
          <Typography variant="h4" color="textPrimary">
            Mon – Fri: 8am – 5pm
          </Typography>
        }
        emailDetails={
          <Typography variant="h4" color="textPrimary">
            <a href="mailto:support@homefreerx.com"> support@homefreerx.com </a>
          </Typography>
        }
        pdfGuide={
          <Typography variant="h4" color="textPrimary">
            View our Application Support Guide for detailed instructions on how
            to use this app <br />
            <br />
            <br />
            <a
              target="_blank"
              href={CLINICIAN_GUIDE_PDF}
              download
              rel="noreferrer"
            >
              <img src={DownloadIcon} alt="DownloadIcon" />
              <span>Download Guide</span>
            </a>
          </Typography>
        }
      />
    </div>
  );
};

export default SupportViewComponent;
