import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import CircleIcon from "./assets/circle.svg";
import CircleOutlineIcon from "./assets/circleOutline.svg";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "inline-block",
    font: theme.typography.sectionSubtitle.bold.font,
    color: theme.palette.primary.A900,
  },
  disabledTitle: {
    display: "inline-block",
    font: theme.typography.sectionSubtitle.regular.font,
    color: theme.palette.textPrimary.emphasisHigh,
  },
  circle: {
    position: "relative",
    right: theme.customMixins.pxToRem(8),
    bottom: theme.customMixins.pxToRem(-5),
    marginRight: theme.customMixins.pxToRem(5),
  },
}));

const ReferralTimelineTitle = ({ label, isEmpty }) => {
  const classes = useStyles();

  const title = (
    <>
      <img
        data-testid="circle"
        src={CircleIcon}
        alt="Bullet point"
        className={classes.circle}
      />
      <Typography data-testid="title" variant="h2" className={classes.title}>
        {label}
      </Typography>
    </>
  );

  const emptyTitle = (
    <>
      <img
        data-testid="disabled-circle"
        src={CircleOutlineIcon}
        alt="Bullet blank"
        className={classes.circle}
      />
      <Typography
        data-testid="disabled-title"
        className={classes.disabledTitle}
        variant="h2"
      >
        {label}
      </Typography>
    </>
  );

  return isEmpty ? emptyTitle : title;
};

export default ReferralTimelineTitle;

ReferralTimelineTitle.propTypes = {
  label: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool.isRequired,
};

ReferralTimelineTitle.defaultProps = {
  label: "unknown",
  isEmpty: false,
};
