import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Store } from "context/Context";
import PropTypes from "prop-types";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

import helpIcon from "./assets/help.svg";

const useStyles = makeStyles((theme) => ({
  appBarToolBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.customMixins.pxToRem(0, 12),
    minHeight: theme.customMixins.pxToRem(16 * 4),
    background: theme.palette.primary.A800,
    width: "100%",
    zIndex: 100,
  },
  backArrowIcon: {
    color: theme.palette.white.high,
    cursor: "pointer",
  },
  appBarLeftGadget: {
    width: theme.customMixins.pxToRem(16 * 3),
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    cursor: "pointer",
  },
  appBarRightGadget: {
    width: theme.customMixins.pxToRem(16 * 3),
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  appBarMiddleGadget: {
    textAlign: "center",
    font: theme.typography.sectionSubtitle.bold.font,
    color: theme.palette.white.high,
  },
}));

function ApplicationBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useContext(Store);

  return (
    <div className={classes.appBarToolBar}>
      <div className={classes.appBarLeftGadget}>
        {props?.children}
        {state.showBackButton && (
          <ArrowBackIcon
            className={classes.backArrowIcon}
            onClick={() => history.goBack()}
            data-testid="test-back-button"
          />
        )}
      </div>
      <Typography
        className={classes.appBarMiddleGadget}
        variant="h2"
        data-testid="test-title"
      >
        {state.title}
      </Typography>
      <div data-testid="test-avatar" className={classes.appBarRightGadget}>
        {false && <img src={helpIcon} alt="helpIcon" />}
      </div>
    </div>
  );
}

ApplicationBar.defaultProps = {
  children: null,
};

ApplicationBar.propTypes = {
  /**
   * JSX Object to be inserted.
   */
  children: PropTypes.node,
};

export default ApplicationBar;
