import {
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { unauthenticatedRoutes } from "helpers/routeConfig";

const useStyles = makeStyles((theme) => ({
  appContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "hidden",
    alignItems: "center",
    background: theme.palette.gradient.linear,
    "&> :nth-child(2)": {
      flexGrow: 1,
    },
  },
  "@supports (-webkit-touch-callout: none)": {
    appContainer: {
      height: "87vh",
    },
  },
  pageContent: {
    display: "flex",
    maxWidth: theme.pageDimensions.mobileMaxWidth,
    flexDirection: "column",
    flexGrow: 1,
    padding: theme.customMixins.pxToRem(24),
    overflowY: "scroll",
  },
  sectionTitle: {
    font: theme.typography.sectionTitle.medium.font,
    color: theme.palette.primary.A900,
  },
  title: {
    marginTop: theme.customMixins.pxToRem(24),
    color: theme.palette.textPrimary.emphasisLow,
    font: theme.typography.description.bold.font,
  },
  italicTitle: {
    marginTop: theme.customMixins.pxToRem(24),
    color: theme.palette.textPrimary.emphasisLow,
    font: theme.typography.description.bold.font,
    fontStyle: "italic",
  },
  bodyText: {
    marginTop: theme.customMixins.pxToRem(8),
    font: theme.typography.description.regular.font,
    color: theme.palette.textPrimary.emphasisLow,
    padding: 0,
  },
}));

function TermsOfService() {
  const classes = useStyles();

  return (
    <div
      data-testid="terms-of-service-container"
      className={classes.appContainer}
    >
      <div className={classes.pageContent}>
        <Typography variant="h2" className={classes.sectionTitle}>
          Terms & Conditions
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          Effective Date: [insert date posted to the service]
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          These Terms of Use (the <strong>“Terms”</strong>) describe the terms
          and conditions on which Integrated Medication Management, LLC d/b/a
          HomeFree Pharmacy Services (<strong>“HomeFree”</strong>,{" "}
          <strong>“we”</strong>, <strong>“us”</strong> or <strong>“our”</strong>
          ) provides access to, and govern the use of, our HomeFree website
          located at{" "}
          <Link
            rel="noopener noreferrer"
            href="https://prod.app.homefreerx.com"
            target="_blank"
          >
            HomeFree
          </Link>{" "}
          (the <strong>“Website”</strong>), the HomeFree mobile applications
          (the <strong>“Apps”</strong>, and together with the Website, the
          <strong>“Services”</strong>), to you. These Terms are important,
          contain legal obligations and affect your legal rights, so please read
          them carefully.{" "}
          <strong>
            THESE TERMS CONTAIN IMPORTANT INFORMATION REGARDING LIMITATIONS OF
            OUR LIABILITY, YOUR INDEMNIFICATION OBLIGATIONS, AND A MANDATORY
            ARBITRATION OF DISPUTES PROVISION THAT REQUIRES THE USE OF
            ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN
            JURY TRIALS OR CLASS ACTIONS.
          </strong>{" "}
          <br />
          <br />
          <strong>
            By clicking “I Accept”, by downloading the Apps, or by accessing or
            using our Services (including by creating an account or paying your
            bill via the Services), you agree to be bound by these Terms, and
            our{" "}
            <Link
              rel="noopener noreferrer"
              href={unauthenticatedRoutes.privacyPolicy}
              target="_blank"
            >
              Privacy Policy
            </Link>
            , which is hereby incorporated herein by reference. If you do not
            want to accept all of the terms, conditions, and notices of these
            Terms, including the mandatory arbitration and the class action
            waiver provisions below, you must immediately discontinue your use
            and access of the Services.
          </strong>
          <br />
          <br />
          The parties agree as follows:
        </Typography>

        <List disablePadding>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>
                1. Our Collection and Use of Your Data is Governed by Our
                Privacy Policy
              </strong>
              <br />
              <br />
              HomeFree believes in protecting your privacy. Our Privacy Policy
              describes how we collect and use the information that you submit
              in connection with your use of the Services. Please review our
              Privacy Policy carefully before using the Services.{" "}
              <strong>
                Note that our Privacy Policy does not describe how we treat your
                Protected Health Information. If you have any questions about
                how we treat your Protected Health Information, please review
                the Notice of{" "}
                <Link
                  rel="noopener noreferrer"
                  href={unauthenticatedRoutes.privacyPolicy}
                  target="_blank"
                >
                  Privacy Practices
                </Link>
              </strong>
              . By using the Services or by clicking to accept or agree to these
              Terms when this option is made available to you, you consent to
              our use of your information and our contacting you, in each case,
              in compliance with our Privacy Policy.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>
                2. The Services are not Intended as a Substitute for Medical
                Advice
              </strong>
              <br />
              <br />
              The text, graphics, images, software applications, information and
              other material contained on the Services (
              <strong>“Content“</strong>) are for informational purposes only.
              <strong>
                {" "}
                NO CONTENT IS INTENDED TO DIAGNOSE, TREAT, CURE, MITIGATE, OR
                PREVENT ANY DISEASE OR HEALTH CONDITION.
              </strong>{" "}
              The Content is not intended to replace or be a substitute for
              professional medical advice, diagnosis, or treatment. It is your
              sole responsibility to check product information, including drug
              packets inserts, regarding dosage, precautions, warnings,
              interactions, and contraindications before administering or using
              any drug or supplement discussed on the Services. Always seek the
              advice of your physician, pharmacist, or other qualified health
              provider with any questions you may have regarding a medical
              condition, before staring any new medication or treatment or the
              proper use of pharmaceutical products. Never disregard
              professional medical advice or delay in seeking it because of
              something you have read on these Services.
              <br />
              <br />
              <strong>
                If you are experiencing a medical emergency immediately call 911
                or go to the nearest emergency room.
              </strong>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>
                3. Accounts, Account Security and Communication Preferences
              </strong>
              <br />
              <br />
              As a condition of your use of the Services, you warrant that you:
              <List disablePadding>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    • are at least 18 years of age; and
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    • reside in the United States of America.
                  </ListItemText>
                </ListItem>
              </List>
              <strong>
                If you do not meet all of the following, you must immediately
                discontinue your use and access of the Services.
              </strong>
              <br />
              <br />
              You may need to register for an account (
              <strong>“Account”</strong>) in order to access some or all of our
              Services. It is your responsibility to select a robust password
              and to prevent disclosure of your login and password, and to
              change your password if you feel that its security has been
              compromised. You may change your password at any time. We are not
              responsible for any damages that result from your failure to keep
              your password secure. You are responsible for all activity in your
              Account. If you permit others to use your Account credentials, you
              are responsible for the activities of such users that occur in
              connection with your Account.
              <br />
              <br />
              As part of activating your Account, you are required to provide us
              with a valid email address and to update your email address if it
              changes.
              <br />
              <br />
              By creating an Account, you also consent to receive electronic
              communications from HomeFree (e.g., via email, text message, or by
              posting notices to the Services). These communications may include
              operational notices about your Account (e.g., payment
              authorizations, password changes and other transactional
              information) and are part of your relationship with us. You agree
              that any communications that we send to you electronically will
              satisfy any legal communication requirements, including that such
              communications be in writing. When permitted by law, we may also
              send you promotional communications via email, including
              newsletters, special offers, surveys and other news and
              information we think will be of interest to you. You may opt out
              of receiving these promotional emails at any time by following the
              unsubscribe instructions provided.
              <br />
              <br />
              IF YOU HAVE AGREED THAT WE CAN SEND YOU MESSAGES CONTAINING
              ADVERTISEMENTS OR OFFERS FOR GOODS AND SERVICES, WE MAY DO SO FROM
              TIME TO TIME AND MAY SEND SUCH OFFERS BY VOICE OR SMS (OR TEXT)
              MESSAGE TO THE MOBILE PHONE NUMBER YOU DESIGNATE FOR SUCH PURPOSE.
              Message and data rates for such SMS messages may apply. Your
              consent to receipt of such messages is not, and will not be, a
              condition to any purchase. If at any time you change your mind
              regarding your consent to such messages, you must contact us in
              accordance with these Terms.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>4. Additional Terms</strong>
              <List disablePadding>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    a. Bill Pay
                    <br />
                    <br />
                    By providing a credit card or other payment method that we
                    accept, you represent that you are authorized to use that
                    payment method and that you authorize us (or our third-party
                    payment processor) to charge your payment method for the
                    total amount of your bill (including any applicable taxes
                    and other charges) (each, a <strong>“Transaction”</strong>).
                    If the payment method cannot be verified, is invalid or is
                    otherwise not acceptable, your Transaction may be suspended
                    or cancelled. You must resolve any payment method problems
                    before we proceed with your Transaction. If you want to
                    change or update your payment method information, you can do
                    so at any time by logging into your Account. If a payment is
                    not successfully settled and you do not edit your payment
                    method information, you remain responsible for any
                    uncollected amounts and authorize us to continue billing the
                    payment method, as it may be updated.
                    <br />
                    <br />
                    You acknowledge that the origination of Automated Clearing
                    House (<strong>“ACH”</strong>) transactions to your Account
                    must comply with applicable provisions of law. In the case
                    of an ACH Transaction rejected for insufficient funds, we
                    may at our discretion attempt to process the charge again at
                    any time within 30 days. If you have sent an insufficient
                    amount of funds to complete the Transaction, we may in our
                    discretion cancel the entire Transaction or fulfill a
                    partial Transaction using the amount of funds available, but
                    we have no obligation to alert you to the insufficiency.
                  </ListItemText>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>
                5. HomeFree Does Not Endorse or Recommend any Specific Products
                or Services
              </strong>
              <br />
              <br />
              HomeFree contracts with various pharmaceutical companies to
              provide coupons, discounts and other Content to users of the
              Services. References to, or descriptions or images of, products or
              services (or related coupons and discounts) on the Services are
              not endorsements of such products or services and such products or
              services may be available by third parties. HomeFree does not
              recommend or endorse any specific tests, physicians, pharmacist,
              products, services, procedures, opinions, or other information
              that may be mentioned on the Services.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>6. Proprietary Rights</strong>
              <br />
              <br />
              HomeFree grants you a limited right to use the Services for your
              personal use. All Content is owned by HomeFree and/or its
              licensors and may be protected by U.S. and foreign copyright,
              trademark and other intellectual property laws. Subject to your
              compliance with these Terms, we grant you a limited,
              non-exclusive, non-transferable right and license to access and
              use the Services and Content solely for your personal,
              non-commercial use; provided, however, that such license does not
              include any right to (a) sell, resell our Services and the
              Content; (b) copy, reproduce, distribute, publicly perform or
              publicly display Content, except as expressly permitted by us or
              our licensors; (c) modify the Content, remove any proprietary
              rights notices or markings, or otherwise make any derivative uses
              of our Services and the Content; (d) use any data mining, medical
              robots or similar data gathering or extraction methods; and (e)
              use our Services and the Content other than for their intended
              purposes. Except for this limited license granted to you, we
              reserve all other rights. This license may be revoked and
              terminated by us at any time and for any reason. Any unauthorized
              use, reproduction or distribution of the Services or Content is
              strictly prohibited and may result in termination of the license
              granted herein, as well as civil and/or criminal penalties.
              <br />
              <br />
              Moreover, “HomeFree Pharmacy” and all related names, logos,
              product and service names, designs and slogans are trademarks or
              service marks of HomeFree. All other names, logos product and
              service names, designs and slogans on the Services are the
              trademarks or service marks of their respective owners. You may
              not use or display any HomeFree trademarks, trade names, or logos
              without our prior written permission. We reserve all rights.
              <br />
              <br />
              If you choose to provide us with any comments, suggestions, ideas
              or other feedback (<strong>“User Feedback”</strong>), you agree
              that we have an unrestricted right to use it, and you are not
              entitled to receive any compensation.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>
                7. HomeFree is Not Responsible for Third-Party Content
              </strong>
              <br />
              <br />
              The Services may contain links to third-party web sites, products,
              services, and may redirect you to third party applications on your
              mobile device (each, a{" "}
              <strong>“Linked Third-Party Service”</strong>). HomeFree is not
              responsible for the content of Linked Third-Party Services, and
              does not make any representations or warranties regarding the
              content or accuracy of any such content. When you access and use a
              Linked Third-Party Service, you are subject to that third party's
              terms and conditions of use and privacy policy and you agree that
              HomeFree is not responsible for and has made no representations or
              warranties, express or implied, regarding any Linked Third-Party
              Service and that HomeFree shall have no liability relating to such
              Linked Third-Party Service. Your use of any Linked Third-Party
              Service is at your own risk and subject to the terms and
              conditions of use for such offerings.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>
                8. We May Update or Otherwise Revise These Terms at Any Time
              </strong>
              <br />
              <br />
              We reserve the right to change or otherwise modify these Terms at
              any time. All changes are effective immediately when we post them,
              and apply to all access to and use of the Services thereafter. We
              may also notify you by sending an email notification to the
              address associated with your Account or providing notice through
              our Services. Your continued access or use of the Services after
              receiving notice of any the update, modification or other change
              to these Terms signifies your acceptance thereof.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>
                9. You are Prohibited from Using the Services for Certain
                Purposes
              </strong>
              <br />
              <br />
              You may use the Services only for lawful purposes and in
              accordance with these Terms. You agree not to, and you must not
              use the Services:
              <List disablePadding>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    • In any way that violates any applicable federal, state,
                    local, or international law or regulation (including,
                    without limitation, any laws regarding the export of data or
                    software to and from the US or other countries).
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    • For the purpose of exploiting, harming, threatening,
                    harassing or defaming any person, or attempting to do any of
                    the foregoing.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    • In any manner that could disable, overburden, damage, or
                    impair the Services or interfere with any other person’s use
                    of the Services, including their ability to engage in real
                    time activities through the Services.
                  </ListItemText>
                </ListItem>
              </List>
              Additionally, you agree not to, and you must not:
              <List disablePadding>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    • Use any robot, spider, or other automatic device, process,
                    or means to access the Services for any purpose, including
                    monitoring or copying any of the material on the Services.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    • Use any manual process to monitor or copy any of the
                    material on the Services or for any other unauthorized
                    purpose without our prior written consent.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    • Use any device, software, or routine that interferes with
                    the proper working of the Services.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    • Introduce any viruses, Trojan horses, worms, logic bombs,
                    or other material that is malicious or technologically
                    harmful.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    • Attempt to gain unauthorized access to, interfere with,
                    damage, or disrupt any parts of the Services, the server on
                    which the Services are stored, or any server, computer, or
                    database connected to the Services.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    • Attack the Services via a denial-of-service attack or a
                    distributed denial-of-service attack.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    • “Frame,” “mirror” or otherwise incorporate any part of the
                    Services into any other website.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    • Otherwise attempt to interfere with the proper working of
                    the Services.
                  </ListItemText>
                </ListItem>
              </List>
              In addition to any rights we may have or remedies we may pursue,
              we may deny your access to and use of the Services if we believe
              you have engaged in any activity prohibited under this Section
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>10. Disclaimer Regarding International Use</strong>
              <br />
              <br />
              HomeFree makes no claim that the Services are appropriate or
              lawful for use or access outside the United States. Furthermore,
              our databases are located in the United States. If you access the
              Services from outside the United States, you do so at your own
              risk. You are solely responsible for complying with all local
              laws, rules and regulations regarding online conduct and website
              access. By sending us your data, you consent to its transfer to
              and storage within the United States.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>11. Limitations of Liability </strong>
              <br />
              <br />
              Unless expressly otherwise stated by HomeFree and except where,
              and only to the extent, prohibited by applicable law:
              <List disablePadding>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    <strong>
                      a. In no event shall HomeFree or any of its affiliates or
                      any of its or their respective officers, directors,
                      employees, agents, representatives, advisors and
                      consultants (collectively, “HomeFree Parties”) be liable
                      to you (whether under contract, tort, negligence, strict
                      liability, warranty or any other legal or equitable
                      theory) for any indirect, incidental, exemplary, special,
                      speculative, punitive or consequential damages (including,
                      without limitation, loss of use, data or information of
                      any kind) in any way arising out of or related to the
                      operation, or your use, of the Services or any Content,
                      even if any HomeFree Parties have been advised of the
                      possibility of such loss or damages.
                    </strong>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    <strong>
                      b. To the fullest extent permitted by applicable law, you
                      understand and agree that the HomeFree Parties’ maximum
                      aggregate liability for any type of damages in any way
                      arising out of or related to the operation, or your use,
                      of the Services or any Content hereunder shall be limited
                      to (i) the total fees paid by you to access or use the
                      Services during the three (3) months preceding the event
                      giving rise to the liability or (ii) if no fees are
                      payable by you for such period, one hundred U.S. dollars.
                    </strong>
                  </ListItemText>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>12. Disclaimer of Warranty</strong>
              <br />
              <br />
              The Services and the Content are provided on an “as is” and “as
              available” basis. To the fullest extent permitted by law, the
              HomeFree Parties disclaim all warranties, either express or
              implied, statutory or otherwise, including but not limited to the
              implied warranties of merchantability, non-infringement of third
              parties’ rights, and fitness for particular purpose. Your use of
              the Services and the Content is entirely at your own risk.
              <br />
              <br />
              Without limiting the foregoing, the HomeFree Parties make no
              representations or warranties about the following:
              <List disablePadding>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    a. The accuracy, reliability, completeness, currency, or
                    timeliness of the Content, products, software,
                    functionality, text, graphics, links, or communications
                    provided on or through the use of the Services or HomeFree,
                    including the substance, accuracy, or sufficiency of any
                    service or product information listed on the Services.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    b. The satisfaction of any government regulations requiring
                    disclosure of information on prescription drug products or
                    the approval or compliance of any software tools with regard
                    to the Content contained on the Services.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    c. That the Services will operate without interruption or
                    error.
                  </ListItemText>
                </ListItem>
              </List>
              Some states may not allow the exclusion of implied warranties, so
              the above exclusion may not apply to you. But, to the extent
              permitted by applicable law, we exclude all warranties.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>13. Release </strong>
              <br />
              <br />
              To the fullest extent permitted by applicable law, you release
              HomeFree and the other HomeFree Parties from responsibility,
              liability, claims, demands, and/or damages of every kind and
              nature, in any way arising out of or related to the operation, or
              your use, of the Services or any Content that in any way arise out
              of or related to the acts or omissions of third parties (
              <strong>“Third Party Disputes”</strong>).{" "}
              <strong>
                If you are a California resident, you hereby waive any rights
                you may have arising out of these Terms under California Civil
                Code Section 1542, which says "A general release does not extend
                to claims which the creditor or releasing party does not know or
                suspect to exist in his or her favor at the time of executing
                the release and that, if known by him or her would have
                materially affected his or her settlement with the debtor or
                released party.” You also waive any rights you may have with
                respect to Third Party Disputes under any other statute or
                common law principles that would otherwise limit the coverage of
                this release to include only those claims which you may know or
                suspect to exist in your favor at the time of agreeing to this
                release.
              </strong>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>14. Indemnification</strong>
              <br />
              <br />
              You will indemnify, defend, and hold harmless the HomeFree Parties
              from and against any and all claims, causes of action, demands,
              liabilities, losses, costs or expenses (including, reasonable
              attorneys’ fees and expenses) arising out of or relating to any of
              the following matters:
              <List disablePadding>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    a. your access to or use of the Services, or the Content;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    b. your User Feedback;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    c. your violation of any of the provisions of these Terms;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    d. any activity related to your registration by you or any
                    other person accessing the Services through your Account,
                    including, without limitation, negligent or wrongful
                    conduct; or
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    e. your violation of any third party right, including any
                    intellectual property right, publicity, confidentiality,
                    property or privacy right.
                  </ListItemText>
                </ListItem>
              </List>
              HomeFree reserves the right, at our own expense, to assume the
              exclusive defense and control of any matter otherwise subject to
              indemnification by you, in which event you will cooperate with us
              in asserting any available defenses.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>15. Dispute Resolution</strong>
              <br />
              <br />
              <strong>
                Please read the following sections carefully because they
                require you to arbitrate certain disputes, claims, suits,
                actions, causes of action, demands or proceedings against any
                HomeFree Parties that in any way arise out of or relate to the
                operation, or your use, of the Services or Content (“Disputes”)
                and limit the manner in which you can seek relief from us in
                such Disputes.
              </strong>
              <List disablePadding>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    <strong>
                      a. Waiver of Right to Pursue Class Arbitration or Class
                      Action Claims
                    </strong>
                    <br />
                    <br />
                    <strong>
                      You and HomeFree agree that each may bring claims or
                      otherwise resolve Disputes against the other party only on
                      an individual basis, and waive any right to pursue any
                      claims as a plaintiff of class member in any purported
                      class or representative action or proceeding.{" "}
                    </strong>{" "}
                    Further, you and HomeFree agree that a Dispute will not be
                    brought as a class or other type of representative action,
                    whether within or outside of arbitration, or on behalf of
                    you or any other individual or group of individuals.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    <strong>b. Limitation on Time to Bring an Action</strong>
                    <br />
                    <br />
                    You agree that regardless of any statute or law to the
                    contrary, any Dispute brought by you must be filed within
                    one (1) year after such claim or cause of action arose, and
                    that thereafter such claim or cause of action will forever
                    be barred.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    <strong>
                      c. Agreement to Arbitrate Claims; Waiver of Jury Trial
                    </strong>
                    <br />
                    <br />
                    <strong>
                      By agreeing to these Terms, both you and HomeFree are
                      waiving the right to a jury trial on certain Disputes that
                      may arise.
                    </strong>
                    <br />
                    <br />
                    Except for Disputes (i) arising out of or related to a
                    violation of Appropriate Use of the Services (
                    <strong>“Appropriate Use Dispute”</strong>); (ii) in which
                    either party seeks to bring an individual action in small
                    claims court and that qualifies to be filed in small claims
                    court (<strong>“Small Claims Dispute”</strong>); or (iii) in
                    which either party seeks injunctive or other equitable
                    relief for the alleged unlawful use of intellectual
                    property, including copyrights, trademarks, trade names,
                    logos, trade secrets or patents (
                    <strong>“IP Injunctive Dispute”</strong>), you and HomeFree
                    agree to arbitrate all Disputes between you and us. If a
                    Dispute arises between you and us relating to the Services
                    or these Terms, you and we agree that the Dispute shall be
                    resolved by final and binding arbitration administered by
                    the American Arbitration Association under its{" "}
                    <Link
                      rel="noopener noreferrer"
                      href="https://www.adr.org/consumer"
                      target="_blank"
                    >
                      rules for consumer arbitrations
                    </Link>{" "}
                    (<strong>“AAA Rules”</strong>).
                    <br />
                    <br />
                    {/* insert */}
                    You and HomeFree agree that each party will notify the other
                    party in writing of any arbitrable or small claims Dispute
                    within thirty (30) days of the date it arises, so that the
                    parties can attempt in good faith to resolve the Dispute
                    informally. Notice to HomeFree shall be sent by certified
                    mail or courier to HomeFree, [insert address]. Your notice
                    must include (a) your name, postal address, telephone
                    number, the email address you use or used for your Account
                    and, if different, an email address at which you can be
                    contacted, (b) a description in reasonable detail of the
                    nature or basis of the Dispute, and (c) the specific relief
                    that you are seeking. Our notice to you will be sent
                    electronically in accordance with the section titled
                    “Accounts, Account Security, and Communication Preferences”,
                    and will include (x) our name, postal address, telephone
                    number and an email address at which we can be contacted
                    with respect to the Dispute, (y) a description in reasonable
                    detail of the nature or basis of the Dispute, and (z) the
                    specific relief that we are seeking. If you and HomeFree
                    cannot agree how to resolve the Dispute within thirty (30)
                    days after the date notice is received by the receiving
                    party, then either you or HomeFree may, as appropriate and
                    in accordance with this section, commence an arbitration
                    proceeding or, solely in the case of an Appropriate Use
                    Dispute, Small Claims Dispute, or IP Injunctive Dispute,
                    file such action in court.
                    <br />
                    <br />
                    You and we agree to the following rules relating to any
                    arbitration proceeding:
                    <br />
                    <br />
                    <strong>
                      You and HomeFree waive any right to bring Disputes before
                      any court of law, (except in the case of an Appropriate
                      Use Dispute, Small Claims Dispute, or IP Injunctive
                      Dispute).
                    </strong>{" "}
                    Rights that you would have if you went to court, such as
                    access to discovery, may be unavailable or limited in
                    arbitration.
                    <br />
                    <br />
                    The venue for all Disputes arising under these Terms
                    (including both arbitrated Disputes and court actions in any
                    Appropriate Use Dispute, Small Claims Dispute, or IP
                    Injunctive Dispute) shall be in [insert county], but you and
                    we may agree to conduct the arbitration by telephone, online
                    and/or solely based on written submissions. You hereby waive
                    any right to claim that such location is an inconvenient
                    forum and covenant not to sue us in any other forum.
                    <br />
                    <br />
                    The arbitrator will have the power to grant whatever relief
                    would be available in court under law or in equity
                    (including attorney’s fees) and any award of the arbitrator
                    will be final and binding on each of the parties. The
                    arbitrator will not, however, have the power to award
                    punitive or exemplary damages, the right to which each party
                    hereby waives. The arbitrator also does not have the power
                    to vary the class action waiver provisions.
                    <br />
                    <br />
                    These Terms affect interstate commerce and the
                    enforceability of this Section shall be both substantively
                    and procedurally governed by and construed and enforced in
                    accordance with the Federal Arbitration Act, 9 U.S.C. § 1 et
                    seq. (the <strong>“FAA”</strong>), to the maximum extent
                    permitted by applicable law.
                    <br />
                    <br />
                    Any judgment on the award rendered by the arbitrator may be
                    entered in any court of competent jurisdiction. All
                    arbitration proceedings will be confidential and all records
                    relating thereto will be permanently sealed to the fullest
                    extent possible under applicable law. Payment of all filing,
                    administration and arbitrator fees will be governed by the
                    AAA Rules. If, however, you are able to demonstrate that the
                    costs of arbitration will be prohibitive for you as compared
                    to the costs of litigation, HomeFree will pay as much of the
                    filing, administration and arbitrator fees as the arbitrator
                    deems necessary to prevent the arbitration from being
                    cost-prohibitive for you.
                    <br />
                    <br />
                    If any term, clause or provision of this Section is held
                    invalid or unenforceable, it will be so held to the minimum
                    extent required by law, and all other terms, clauses and
                    provisions of this section will remain valid and
                    enforceable. Further, the waivers set forth in this section
                    are severable from the other provisions of these Terms and
                    will remain valid and enforceable, except as prohibited by
                    applicable law.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    <strong>d. Governing Law</strong>
                    <br />
                    <br />
                    You and HomeFree agree that these Terms and any Dispute
                    between you and HomeFree relating to your use of the
                    Services will be governed by and construed in accordance
                    with the laws of the State of [insert state], without regard
                    to its conflict-of-laws provisions. You and HomeFree each
                    agree that any Dispute between the parties that is not
                    subject to arbitration or cannot be heard in small claims
                    court, shall be resolved on an individual basis exclusively
                    in the U.S. District Court for the [insert venue], or the
                    state courts located in [insert county and state]. You and
                    HomeFree each consent to the personal jurisdiction of these
                    courts and waives any and all objections to the exercise of
                    jurisdiction by these courts and to this venue.
                    Notwithstanding the foregoing, however, you and HomeFree
                    agree that HomeFree may commence and maintain an action or
                    proceeding seeking injunctive or other equitable relief in
                    any court of competent jurisdiction. If any provision of
                    these Terms is found by a court of competent jurisdiction to
                    be invalid, the parties nevertheless agree that the court
                    should endeavor to give effect to the parties’ intentions as
                    reflected in the provision, and the other provisions of the
                    Terms remain in full force and effect.
                  </ListItemText>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>16. Termination</strong>
              <br />
              <br />
              Either you or we may terminate our relationship with regard to the
              Services at any time, with or without cause. In addition, we may
              deactivate, terminate or suspend your account at any time: (a) if
              we, in our sole discretion, determine that you are or have been in
              violation of these Terms; (b) if we, in our sole discretion,
              determine that you have created risk or possible legal exposure
              for HomeFree; (c) in response to requests by law enforcement or
              other government agencies; (d) upon discontinuance or material
              modification of the Services, or (e) due to unexpected technical
              issues or problems. We may also stop providing the Services or
              create limits on use of the Services (in each case, whether
              specifically to you or generally). Termination will not limit any
              of our other rights or remedies. Any provision that must survive
              in order to give proper effect to the intent and purpose of these
              Terms shall survive termination.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>17. Apple Device Additional Terms</strong>
              <br />
              <br />
              If you access an App via a mobile device or tablet branded by
              Apple, Inc. (<strong>“Apple”</strong>) and running Apple’s iOS (an{" "}
              <strong>“Apple Device”</strong>), the following terms (
              <strong>“Apple Terms”</strong>) are hereby made part of these
              Terms:
              <br />
              <br />
              If these Apple Terms conflict with any other provision of these
              Terms, then the Apple Terms control with respect to access and use
              of the Services via an Apple Device. Apple is not a party to these
              Terms and does not own and is not responsible for any App you
              access via your Apple Device (<strong>“iOS App”</strong>) and the
              content thereof. The Apple App Store Terms of Service (the{" "}
              <strong>“App Store Terms of Service</strong>”) control to the
              extent of any conflict with these Terms with respect to an iOS
              App. The license granted to use an iOS App is limited to a
              non-transferable license to use the App on an Apple Device that
              you own or control and as permitted by the usage rules set forth
              in the App Store Terms of Service. Apple has no obligation
              whatsoever to furnish any maintenance and support services with
              respect to any iOS App. In the event of any failure of the iOS App
              to conform to any applicable warranty provided by HomeFree in
              these Terms, you may notify Apple and Apple will refund the
              purchase price for the iOS App (if any). To the maximum extent
              permitted by applicable law, Apple will have no other warranty
              obligation whatsoever with respect to the iOS App and will not be
              responsible for any other claims, losses, liabilities, damages,
              costs or expenses attributable to any failure to conform to any
              warranty. Apple is not responsible for addressing your or any
              third-party claims relating to an iOS App and/or use of an iOS
              App, including, but not limited to: (i) product liability claims;
              (ii) any claim that an iOS App fails to conform to any applicable
              legal or regulatory requirement; and (iii) claims arising under
              consumer protection or similar legislation. It is not the intent
              of these Terms to limit HomeFree’s liability beyond what is
              permitted by applicable law. In the event of any third party claim
              that an iOS App or your possession and use (in accordance with
              these Terms) of an iOS App infringes that third party’s
              intellectual property rights, Apple will not be responsible for
              the investigation, defense, settlement and discharge of any such
              intellectual property infringement claim. In addition to the
              requirements of Section 3, you represent and warrant that (i) you
              are not located in a country that is subject to a U.S. Government
              embargo, or that has been designated by the U.S. Government as a
              “terrorist supporting” country; and (ii) you are not listed on any
              U.S. Government list of prohibited or restricted parties. Apple,
              and Apple’s subsidiaries, are third-party beneficiaries of these
              Terms with respect to any iOS App, and that, upon your acceptance
              of these Terms, Apple has the right (and deemed to have accepted
              the right) to enforce the Terms against you with respect to the
              iOS App as a third-party beneficiary thereof. Please direct any
              questions, complaints or claims with respect to an iOS App to
              HomeFree at the contact information provided below.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>18. General Legal Terms</strong>
              <br />
              <br />
              You and HomeFree further agree to be bound by the following
              general terms:
              <List disablePadding>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    a. These Terms, along with any additional terms and
                    conditions incorporated herein, constitute the entire
                    understanding by and between HomeFree and you with respect
                    to the matters contained herein.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    b. Our failure to exercise or enforce any right or provision
                    of these Terms shall not constitute a waiver of such right
                    or provision. Waivers are effective only if in writing and
                    signed by us.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    c. Except as described in the section titled Changes to
                    these Terms, these Terms may not be amended unless in
                    writing by us.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    d. If any provision of these Terms is or becomes
                    unenforceable or invalid, the remaining provisions will
                    continue with the same effect as if such unenforceable or
                    invalid provision had not been used.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    e. These Terms inure to the benefit of and will be binding
                    upon our and your permitted successors and assigns.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    f. You must not transfer any of your rights or obligations
                    under these Terms to anyone else without our prior written
                    consent. We may assign or delegate any of our rights and
                    obligations under these Terms.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    g. A printed version of these Terms and of any related
                    notice given in electronic form shall be admissible in
                    arbitral, judicial, or administrative proceedings based upon
                    or relating to these Terms to the same extent and subject to
                    the same conditions as other business documents and records
                    originally generated and maintained in printed form.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    h. You agree that we may send you notice via email to the
                    email address you have provided, and we are not responsible
                    for your failure to receive notice if email is quarantined
                    by your email security system (e.g., “junk” or “spam”
                    folder) or if you fail to update your email address. You
                    also agree that we may send you notice through the Services
                    or your Account.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    i. Headings and captions are for convenience only.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    j. Use of the term “including” shall construed without any
                    limitation.
                  </ListItemText>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>19. DMCA Notice</strong>
              <br />
              <br />
              The Digital Millennium Copyright Act of 1998 (the "DMCA") provides
              recourse for copyright owners who believe that material appearing
              on the Internet infringes their rights under U.S. copyright law.
              If you believe in good faith that materials available on the
              Service infringe your rights under U.S. copyright law, you (or
              your agent) may send us a written notice by mail or e-mail
              requesting that we remove such material or block access to it. If
              you believe in good faith that someone has wrongly filed a notice
              of copyright infringement against you, the DMCA permits you to
              send us a counter-notice. Notices and counter-notices must be sent
              in writing and meet the then-current statutory requirements
              imposed by the DMCA (see http://www.copyright.gov/ for details),
              which, with respect to notices of infringement, currently include,
              among other requirements, the following:
              <br />
              <br />
              <List disablePadding>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    1. Sufficient information identifying the copyrighted
                    work(s) believed to be infringed.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    2. Sufficient information identifying the allegedly
                    infringing material(s) and the location of such material(s)
                    in order to permit us to locate such material(s).
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    3. A statement from the owner (or the owner's authorized
                    representative) of the copyrighted work(s) believed to be
                    infringed that such owner or authorized representative has a
                    good faith belief that the allegedly infringing materials
                    are used in a manner not authorized by the copyright owner,
                    its agent, or the law.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    4. Contact information for the complaining party, including
                    a mailing address, a telephone number and, if available, an
                    email address.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    5. A statement that the information in the notification is
                    accurate and, under penalty of perjury, that the complaining
                    party is authorized to act on the copyright owner's behalf.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText className={classes.bodyText} disableTypography>
                    6. A signature or the electronic equivalent from the owner
                    (or the owner's authorized representative) of the
                    copyrighted work(s) believed to be infringed.
                  </ListItemText>
                </ListItem>
              </List>
              Notices and counter-notices must be sent in writing to our DMCA
              agent using the contact information below.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText className={classes.bodyText} disableTypography>
              <strong>20. How to Contact Us</strong>
              <br />
              <br />
              For questions or comments regarding these Terms, please contact us
              at [insert contact information, including a physical address,
              email and telephone number (preferably toll-free)].
            </ListItemText>
          </ListItem>
        </List>
      </div>
    </div>
  );
}

export default TermsOfService;
