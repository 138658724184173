import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import PropTypes from "prop-types";
import { generateFullName } from "utility";

import ChevRight from "./assets/icons/chev-right.svg";
import StatusLine from "./StatusLine";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: theme.customMixins.pxToRem(18),
    margin: theme.customMixins.pxToRem(16, 0),
    boxShadow: theme.customCss.cardShadow,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  card: {
    width: "90%",
  },
  referralName: {
    font: theme.typography.sectionSubtitle.bold.font,
    marginBottom: theme.customMixins.pxToRem(8),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "90%",
  },
  latestUpdateAt: {
    color: theme.palette.textPrimary.emphasisLow,
    font: theme.typography.description.regular.font,
    paddingLeft: theme.customMixins.pxToRem(22),
  },
}));

function getFormattedDeliveryDate(deliveryEventDate) {
  const dateLocales = "en-US";
  const dateOfEvent = new Date(deliveryEventDate);
  const date = dateOfEvent.toLocaleDateString(dateLocales, {
    day: "numeric",
  });
  const month = dateOfEvent.toLocaleDateString(dateLocales, {
    month: "long",
  });
  return `${month} ${date}`;
}

export const ReferralCard = ({ referral, onClick }) => {
  const classes = useStyles();
  const theme = useTheme();
  let statusKey = "";
  if (referral.referralStatus !== null) {
    statusKey = referral.referralStatus.replace(" ", "_").toLowerCase();
  }

  let backgroundColor =
    statusKey === "consult_declined"
      ? theme.palette.background.grey
      : theme.palette.white.high;

  const fullName = generateFullName({
    firstName: referral.referralFirstName,
    lastName: referral.referralLastName,
    separator: ", ",
    reverse: true,
  });

  return (
    <div
      onClick={onClick}
      data-testid="referral-card"
      className={classes.cardContainer}
      style={{ background: backgroundColor }}
    >
      <div className={classes.card}>
        <Typography variant="h2" className={classes.referralName}>
          {fullName}
        </Typography>
        <StatusLine status={referral.referralStatus} />
        <Typography className={classes.latestUpdateAt}>
          Updated {getFormattedDeliveryDate(referral.latestUpdateAt)}
        </Typography>
      </div>
      <img src={ChevRight} alt="No data available" />
    </div>
  );
};

ReferralCard.propTypes = {
  /**
   * Referral Card details for a patient.
   */
  referral: PropTypes.shape({
    referralFirstName: PropTypes.string,
    referralLastName: PropTypes.string,
    latestUpdateAt: PropTypes.any,
    voicemailLeft: PropTypes.number,
  }).isRequired,

  /**
   * Returns a response when the card is clicked.
   */
  onClick: PropTypes.func,
};

ReferralCard.defaultProps = {
  referral: {
    referralFirstName: "John",
    referralLastName: "Doe",
    referralStatus: "No Status",
    latestUpdateAt: new Date(),
    voicemailLeft: 0,
  },

  onClick: console.log,
};

export default ReferralCard;
