export function truncateString(str, n) {
  return str.length > n ? str.substr(0, n - 2) + "..." : str;
}

export const capitalizeFirstLetter = (string) => {
  if (typeof string !== "string") return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const generateFullName = ({
  firstName,
  lastName,
  separator = " ",
  reverse = false,
} = {}) => {
  firstName = typeof firstName === "string" ? firstName.trim() : "";
  lastName = typeof lastName === "string" ? lastName.trim() : "";

  let namePartList = [firstName, lastName].filter((v) => !!v);
  if (reverse) namePartList.reverse();

  const fullName = namePartList.join(separator);
  return fullName;
};

export const generateHash = function (s) {
  if (!s) s = "r1234567890";
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

export const generateCaseInsensitiveName = (name) => {
  if (name !== null) {
    return name.split(" ").join("_").split("-").join("_").toLowerCase();
  } else {
    return "";
  }
};
