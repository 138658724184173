import { REMINDER_TIMING } from "samples/remindersTimings";
import { formatToStandardDate } from "utility";

import { get, put } from "../communication";

export const ROUTE_URL = "/med-mgmt/member/{current}/reminders";
export const API_DEV_HASH = "devMode##reminderTracking";

export const updateReminderTimings = (data) => {
  let apiPromise;

  if (localStorage.getItem(API_DEV_HASH) === "true") {
    apiPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: {} });
      }, 1000);
    });
  } else {
    apiPromise = put(ROUTE_URL, data);
  }

  return apiPromise;
};

export const getAllReminderTimes = (date = new Date(), user = "{current}") => {
  let apiPromise = null;
  if (localStorage.getItem(API_DEV_HASH) === "true") {
    apiPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve(REMINDER_TIMING);
      }, 1000);
    });
  } else {
    // Todo: Remove After demo
    if (date instanceof Date) date = formatToStandardDate(date);
    else date = date.toString();
    apiPromise = get(ROUTE_URL.replace("{current}", user), { date }).then(
      (response) => {
        const reminderTimes = response.data.message;
        return Array.isArray(reminderTimes) ? reminderTimes : [];
      },
    );
  }
  return apiPromise;
};
