import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import CloseIcon from "./assets/close.svg";

const useStyles = makeStyles((theme) => ({
  dialogBox: {
    "& .MuiPaper-root": {
      padding: theme.customMixins.pxToRem(16),
    },
  },
  dialogCloseTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.customMixins.pxToRem(16),
    "& > h3": {
      font: theme.typography.sectionSubtitle.bold.font,
      color: theme.palette.textPrimary.emphasisHigh,
    },
    "& > img": {
      cursor: "pointer",
      width: theme.customMixins.pxToRem(24),
      height: theme.customMixins.pxToRem(24),
      marginLeft: theme.customMixins.pxToRem(16),
    },
  },
  dialogCloseDescriptionContainer: {
    "& > h4": {
      font: theme.typography.body.regular.font,
      color: theme.palette.textPrimary.emphasisLow,
    },
  },
  dialogActionButton: {
    marginTop: theme.customMixins.pxToRem(16),
    padding: theme.customMixins.pxToRem(6, 20),
  },
}));

function SignOutConfirmation(props) {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      className={classes.dialogBox}
      keepMounted
      onClose={props.onClose}
      data-testid="sign-out-dialog"
    >
      <div>
        <div className={classes.dialogCloseTitleContainer}>
          <Typography
            className={classes.dialogCloseTitle}
            data-testid="test-title"
            variant="h3"
          >
            Sign out
          </Typography>
          <img
            onClick={props.onClose}
            data-testid="close-button"
            src={CloseIcon}
            alt="CloseIcon"
          />
        </div>
      </div>
      <div className={classes.dialogCloseDescriptionContainer}>
        <Typography className={classes.dialogCloseTitle} variant="h4">
          Are you sure you want to sign out?
        </Typography>
      </div>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="outlined"
          className={classes.dialogActionButton}
          data-testid="cancel-button"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={props.onSignOut}
          variant="contained"
          className={classes.dialogActionButton}
          data-testid="sign-out-button"
          color="primary"
        >
          Sign out
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SignOutConfirmation.defaultProps = {
  open: false,
  onClose: console.log,
  onSignOut: console.log,
};

SignOutConfirmation.propTypes = {
  /**
   * Set Dialog to be open or close
   */
  open: PropTypes.bool,

  /**
   * Function call on close action click
   */
  onClose: PropTypes.func,

  /**
   * Function call on sign out action click
   */
  onSignOut: PropTypes.func,
};

export default SignOutConfirmation;
