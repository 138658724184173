import { isDateInWithInXDay } from "utility";

export const generateCardTitleText = (latestStatus, deliveryDate) => {
  const dateLocales = "en-US";

  // Case 1: Delivery Date not specified.
  if (!deliveryDate) return latestStatus;

  const deliveryDateObj = new Date(deliveryDate);

  const day = deliveryDateObj.toLocaleDateString(dateLocales, {
    weekday: "long",
  });

  const inThisWeek = isDateInWithInXDay(deliveryDateObj, 6);
  const inTomorrow = isDateInWithInXDay(deliveryDateObj, 2);
  const inToday = isDateInWithInXDay(deliveryDateObj, 1);
  const inYesterday = isDateInWithInXDay(deliveryDateObj, 0);

  let specialShippedStatus = null;
  if (latestStatus.toUpperCase() === "SHIPPED") {
    specialShippedStatus = "Shipped";
    if (inThisWeek) specialShippedStatus = `Arriving ${day}`;
    if (inTomorrow) specialShippedStatus = "Arriving tomorrow";
    if (inToday) specialShippedStatus = "Arriving today";
  } else if (latestStatus.toUpperCase() === "DELIVERED") {
    specialShippedStatus = "Delivered";
    if (inYesterday) specialShippedStatus = "Delivered yesterday";
    if (inToday) specialShippedStatus = "Delivered today";
  } else if (latestStatus.toUpperCase() === "PROCESSING") {
    specialShippedStatus = "Processing";
  } else {
    specialShippedStatus = latestStatus;
  }

  return specialShippedStatus;
};

export const generateCardSubtitleText = (deliveryDate) => {
  const dateLocales = "en-US";

  // Case 1: Delivery Date not specified.
  if (!deliveryDate) return "Coming soon";

  const deliveryDateObj = new Date(deliveryDate);

  const date = deliveryDateObj.toLocaleDateString(dateLocales, {
    day: "numeric",
  });
  const month = deliveryDateObj.toLocaleDateString(dateLocales, {
    month: "long",
  });

  return `${month} ${date}`;
};

export function getOrdering(deliveryLatestStatus) {
  let ordering = [];

  switch (deliveryLatestStatus.toUpperCase()) {
    case "PROCESSING":
      ordering = ["SHIPPING", "DELIVERY"];
      break;
    case "SHIPPED":
      ordering = ["SHIPPED", "DELIVERY"];
      break;
    case "DELIVERED":
      ordering = ["SHIPPED", "DELIVERED"];
      break;
    default:
      ordering = [];
  }

  return ordering;
}

export function getFormattedDeliveryDate(deliveryEventDate) {
  const dateLocales = "en-US";
  const dateOfEvent = new Date(deliveryEventDate);
  const day = dateOfEvent.toLocaleDateString(dateLocales, {
    weekday: "long",
  });
  const date = dateOfEvent.toLocaleDateString(dateLocales, {
    day: "numeric",
  });
  const month = dateOfEvent.toLocaleDateString(dateLocales, {
    month: "long",
  });

  return `${day}, ${month} ${date}`;
}
