import { Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import NoDataSvg from "./no-data.svg";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.customMixins.pxToRem(16, 0),
    paddingTop: theme.customMixins.pxToRem(24),
    opacity: "0.74",
    borderRadius: theme.customMixins.pxToRem(16),
  },
  cardIcon: {
    marginBottom: theme.customMixins.pxToRem(24),
  },
  rootContainer: {
    marginTop: theme.customMixins.pxToRem(16),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    "& h5": {
      font: theme.typography.body.regular.font,
      color: theme.palette.textPrimary.emphasisLow,
    },
  },
}));

function NoDelivery({ message, hidden }) {
  const classes = useStyles();
  if (hidden) return <></>;

  return (
    <Card
      data-testid="no-data-root-container"
      className={classes.card}
      variant="elevation0"
    >
      <CardContent>
        <div className={classes.rootContainer}>
          <img
            className={classes.cardIcon}
            src={NoDataSvg}
            alt="No data available"
          />
          <Typography variant="h5">{message}</Typography>
        </div>
      </CardContent>
    </Card>
  );
}

NoDelivery.defaultProps = {
  message: "You have no past deliveries",
  hidden: false,
};

NoDelivery.propTypes = {
  /**
   * Message text to be shown on UI
   */
  message: PropTypes.string.isRequired,

  /**
   * Set UI visibility
   */
  hidden: PropTypes.bool,
};

export default NoDelivery;
