import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { TimeUtility } from "utility";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > h3": {
      font: theme.typography.body.bold.font,
      color: theme.palette.primary.A900,
    },
  },
  closeIcon: {},
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.customMixins.pxToRem(16),
  },
  timeContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.customMixins.pxToRem(24),
    marginBottom: theme.customMixins.pxToRem(24),
    borderBottom: theme.customCss.border,
  },
  periodSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  timeSeparator: {
    font: theme.typography.title.regular.font,
    color: theme.palette.primary.main,
  },
  inputStyle: {
    font: theme.typography.title.regular.font,
    display: "flex",
    alignItems: "center",
    width: theme.customMixins.pxToRem(72),
    height: theme.customMixins.pxToRem(72),
    borderRadius: theme.customMixins.pxToRem(4),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.textPrimary.emphasisHighG,
    border: 0,
    "&:before": {
      content: "none",
    },
    "& input": {
      textAlign: "center",
    },
  },
  timeSelectionSection: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    margin: theme.customMixins.pxToRem(0, 18, 0, 0),
    alignItems: "center",
  },
  periodButton: {
    font: theme.typography.sectionTitle.regular.font,
    width: theme.customMixins.pxToRem(74),
    height: theme.customMixins.pxToRem(34),
    borderRadius: theme.customMixins.pxToRem(4),
    backgroundColor: theme.palette.textPrimary.emphasisHighG,
    textTransform: "lowercase",
  },
  selectedPeriodButton: {
    color: theme.palette.system.W,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  notesSection: {
    width: "100%",
    height: theme.customMixins.pxToRem(108),
    backgroundColor: theme.palette.white.high,
    padding: theme.customMixins.pxToRem(14, 18),
    font: theme.typography.description.regular.font,
    border: theme.customCss.border,
    borderRadius: theme.customMixins.pxToRem(4),
    color: theme.palette.system.text,
    resize: "none",
    margin: theme.customMixins.pxToRem(24, 0),
  },
  saveButton: {
    width: "100%",
    height: theme.customMixins.pxToRem(36),
    borderRadius: theme.customMixins.pxToRem(4),
    border: `${theme.customMixins.pxToRem(1)} solid`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.system.W,

    "&:disabled": {
      color: theme.palette.textPrimary.emphasisMedium,
      backgroundColor: theme.palette.shades.A1,
      borderColor: theme.palette.system.line,
    },

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.system.W,
    },
  },
}));

const getTimeIn12HrFormat = (timeString) => {
  const timeUtility = new TimeUtility();
  const timeIn12HourFormat = timeUtility.convertTimeTo12HrFormat(timeString);
  const { hh, mm, amPm } = timeUtility.split12HourFormat(timeIn12HourFormat);
  return { hour: hh, minutes: mm, period: amPm.toLowerCase() };
};

const hourOptions = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
const minutesOptions = ["00", "10", "20", "30", "40", "50"];

function UpdateReminderTime({ title, onClose, onSave, currentTime }) {
  const classes = useStyles();
  const { hour, minutes, period } = getTimeIn12HrFormat(currentTime.time);
  const [selectedHour, setSelectedHour] = useState(
    hour < 10 ? "0" + hour : hour,
  );
  const [selectedMinutes, setSelectedMinutes] = useState(
    minutes < 10 ? "0" + minutes : minutes,
  );
  const [selectedPeriod, setSelectedPeriod] = useState(period);

  const [anchorHourEl, setAnchorHourEl] = useState(null);
  const openHour = Boolean(anchorHourEl);

  const [anchorMinutesEl, setAnchorMinutesEl] = useState(null);
  const openMinutes = Boolean(anchorMinutesEl);

  const handleCloseHourChange = () => {
    setAnchorHourEl(null);
  };

  const handleCloseMinutesChange = () => {
    setAnchorMinutesEl(null);
  };

  const handleHourChange = (event) => {
    setSelectedHour(event);
    setAnchorHourEl(null);
  };

  const handleMinutesChange = (event) => {
    setSelectedMinutes(event);
    setAnchorMinutesEl(null);
  };

  const handleSaveButton = () => {
    const timeUtility = new TimeUtility();
    const timeIn12HourFormat = `${selectedHour}:${selectedMinutes} ${selectedPeriod}`;
    const timeIn24hrFormat =
      timeUtility.convertTimeTo24HrFormat(timeIn12HourFormat);
    const epochTime =
      timeUtility.convertLocalTimeStringToEpoch(timeIn24hrFormat);
    onSave(epochTime);
  };

  return (
    <Dialog onClose={onClose} open={true}>
      <DialogTitle onClose={onClose}>
        <div className={classes.headerContainer}>
          <Typography data-testid="test-title" variant="h3">
            Change reminder time
          </Typography>
          <CloseIcon
            className={classes.closeIcon}
            onClick={onClose}
            style={{ cursor: "pointer" }}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <section className={classes.bodyContainer}>
          <section className={classes.timeContainer}>
            <div className={classes.timeSelectionSection}>
              <div>
                <Button
                  id="hour-button"
                  data-testid="hour-button"
                  className={classes.inputStyle}
                  aria-expanded={openHour ? "true" : undefined}
                  onClick={(e) => setAnchorHourEl(e.currentTarget)}
                >
                  {selectedHour}
                </Button>

                <Menu
                  anchorEl={anchorHourEl}
                  open={openHour}
                  onClose={handleCloseHourChange}
                  MenuListProps={{
                    "aria-labelledby": "hour-button",
                    role: "listbox",
                  }}
                >
                  {hourOptions.map((option) => (
                    <MenuItem
                      key={option}
                      data-testid={`hour-options-${option}`}
                      selected={option === selectedHour}
                      onClick={() => handleHourChange(option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
              <span className={classes.timeSeparator}>:</span>
              <div>
                <Button
                  id="minute-button"
                  data-testid="minute-button"
                  className={classes.inputStyle}
                  aria-expanded={openMinutes ? "true" : undefined}
                  onClick={(e) => setAnchorMinutesEl(e.currentTarget)}
                >
                  {selectedMinutes}
                </Button>

                <Menu
                  anchorEl={anchorMinutesEl}
                  open={openMinutes}
                  onClose={handleCloseMinutesChange}
                  MenuListProps={{
                    "aria-labelledby": "minutes-button",
                    role: "listbox",
                  }}
                >
                  {minutesOptions.map((option) => (
                    <MenuItem
                      key={option}
                      data-testid={`minute-options-${option}`}
                      selected={option === selectedMinutes}
                      onClick={() => handleMinutesChange(option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>
            <div className={classes.periodSection}>
              <Button
                data-testid="am-button"
                className={`${classes.periodButton} ${
                  selectedPeriod === "am" ? classes.selectedPeriodButton : ""
                }`}
                onClick={() => setSelectedPeriod("am")}
              >
                am
              </Button>
              <Button
                data-testid="pm-button"
                className={`${classes.periodButton} ${
                  selectedPeriod === "pm" ? classes.selectedPeriodButton : ""
                }`}
                onClick={() => setSelectedPeriod("pm")}
              >
                pm
              </Button>
            </div>
          </section>
          <Button
            className={classes.saveButton}
            data-testid="save-button"
            onClick={handleSaveButton}
            disabled={
              (selectedHour === hour &&
                selectedMinutes === minutes &&
                selectedPeriod === period) ||
              !parseInt(selectedHour)
            }
          >
            Save
          </Button>
        </section>
      </DialogContent>
    </Dialog>
  );
}

UpdateReminderTime.defaultProps = {
  onClose: () => {},
};

UpdateReminderTime.propTypes = {
  onClose: PropTypes.func,
  currentTime: PropTypes.shape({
    time: PropTypes.number.isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default UpdateReminderTime;
