import * as Joi from "joi";

const dailyMedicationItems = Joi.object({
  consumptionDate: Joi.string().required(),
  drugName: Joi.string().required(),
  drugCode: Joi.string().allow(null, ""),
  drugImageUrl: Joi.string().allow(null, ""),
  dosageQty: Joi.alternatives(Joi.string(), Joi.number()).allow(null, ""),
  unitOfDosage: Joi.string().allow(null, ""),
  isPartOfPillPack: Joi.string().allow(null, ""),
  beforeAfterMealFlag: Joi.string().allow(null, ""),
  strength: Joi.string().allow(null, ""),
  timeOfDay: Joi.string().allow(null, ""),
  splId: Joi.string().allow(null, ""),
  description: Joi.string().allow(null, ""),
  consumptionCadence: Joi.string().allow(null, ""), //.valid("DAILY", "AS_NEEDED"),
  uniqueIdentifier: Joi.string().allow(null, ""),
  adherenceTracking: Joi.object({
    isTaken: Joi.boolean().allow(null),
    takenTime: Joi.number().allow(null),
    missedReasonCode: Joi.string().allow(null, ""),
    missedNotes: Joi.string().allow(null, ""),
    reminderDateTime: Joi.string().allow(null, ""),
  }),
}).unknown();

export const dailyMedicationSchema = Joi.object({
  medications: Joi.array().items(dailyMedicationItems),
})
  .required()
  .unknown();
