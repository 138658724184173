import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

export const CARD_RENDER_ERROR_MESSAGE =
  "Sorry, failed to render this card. Please try again later.";

const styles = (theme) => ({
  blankCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.customMixins.pxToRem(16),
    backgroundColor: "white",
    color: theme.palette.error.secondary,
  },
});

class CardErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(boundaryError, errorBoundaryInfo) {
    console.log({ boundaryError, errorBoundaryInfo });
  }
  render() {
    const { classes, message } = this.props;
    if (this.state.hasError) {
      return (
        <>
          {typeof message === "string" && (
            <div className={classes.blankCard}>{message}</div>
          )}
        </>
      );
    }
    return this.props.children;
  }
}

CardErrorBoundary.propTypes = {
  message: PropTypes.string,
};

CardErrorBoundary.defaultProps = {
  message: CARD_RENDER_ERROR_MESSAGE,
};

export default withStyles(styles)(CardErrorBoundary);
