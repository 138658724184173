import {
  PENDING_INITIAL_CALL_DATA,
  REFERRAL_DETAILS,
} from "samples/referralDetails";
import { groupBy } from "utility";

import { fetchCustomPayload, get } from "../communication";
import { referralDetailsSchema } from "./schema/getReferralDetails.schema";

export const ROUTE_URL = "/user-mgmt/clinician/referral/{referralId}/events";
export const API_DEV_HASH = "devMode##getReferralDetails";

export const groupData = (referralDetailItems) => {
  const groupedItems = groupBy(referralDetailItems, ["eventCategory"]);

  let consult = groupedItems["Consult"];
  let pharmacyServices = groupedItems["Pharmacy service"];
  let reachOut = groupedItems["Reach out"];

  consult = consult ? consult : [];
  pharmacyServices = pharmacyServices ? pharmacyServices : [];
  reachOut = reachOut ? reachOut : [];

  return { consult, pharmacyServices, reachOut };
};

export const getReferralDetails = (referralId, updatedAt) => {
  if (localStorage.getItem(API_DEV_HASH) === "true") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(fetchCustomPayload(API_DEV_HASH, groupData(REFERRAL_DETAILS)));
      }, 1000);
    });
  } else if (referralId === null) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(groupData(PENDING_INITIAL_CALL_DATA(updatedAt)));
      }, 100);
    });
  } else {
    const routeUrl = ROUTE_URL.replace("/{referralId}", `/${referralId}`);
    return get(routeUrl, null, API_DEV_HASH, referralDetailsSchema).then(
      (response) => Promise.resolve(groupData(response.data)),
    );
  }
};
