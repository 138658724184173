import { TIME_OPTIONS } from "constants/timeOfDay";
import {
  getAllReminderTimes,
  getDailyMedications,
  getUserPreferences,
  updateAdherence,
} from "helpers/api";

export const getDailyMedicationPageData = async (args) => {
  const promises = [
    getAllReminderTimes(args.queryKey[1], args.queryKey[2]),
    getDailyMedications(args.queryKey[1], args.queryKey[2]),
  ];

  return Promise.all(promises).then(([timing, medication]) => {
    TIME_OPTIONS.forEach((key, index) => {
      if (!medication.groupedTimeData[key]) return;
      const reminderTiming = timing?.find(({ timeOfDay }) => timeOfDay === key);
      const groupedData = {
        rows: medication.groupedTimeData[key],
        expanded: medication.cardDefaultExpansion[index],
        reminderTiming: reminderTiming ? reminderTiming.reminderTime : null,
      };

      medication.groupedTimeData[key] = groupedData;
    });

    return Promise.resolve(medication);
  });
};

export const updateAdherenceTracking = (medicationItems, time) => {
  const consumptionDate = medicationItems[0]?.consumptionDate;
  const drugList = medicationItems.map((row) => {
    const {
      uniqueIdentifier,
      adherenceTracking: { isTaken, takenTime, missedReasonCode, missedNotes },
    } = row;
    return {
      uniqueIdentifier,
      isTaken: isTaken === null ? true : isTaken,
      takenTime: takenTime ? time : null,
      missedReasonCode,
      missedNotes,
      notes: null,
    };
  });
  return updateAdherence({
    consumptionDate,
    data: drugList,
  });
};

export const getPreferences = async (args) => {
  return getUserPreferences(args.queryKey[1]);
};

export const getParsedDate = (date, day) => {
  return new Date(date.setDate(date.getDate() + day));
};
