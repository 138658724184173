import { put } from "../communication";

export const ROUTE_URL = "/med-mgmt/member/{current}/adherence";
export const API_DEV_HASH = "devMode##adherenceTracking";

export const updateAdherence = (data) => {
  let apiPromise;

  if (localStorage.getItem(API_DEV_HASH) === "true") {
    apiPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: {} });
      }, 1000);
    });
  } else {
    // Todo: Remove After demo
    apiPromise = put(ROUTE_URL, data);
  }

  return apiPromise;
};
