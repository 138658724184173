import { MsalProvider } from "@azure/msal-react";
import DateFnsUtils from "@date-io/date-fns";
import { Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import { Store } from "context/Context";
import { manageSessionOnAuthErrors } from "helpers/authErrors";
import init from "helpers/init";
import { unauthenticatedRoutes } from "helpers/routeConfig";
import PrivacyPolicy from "pages/common/UserAgreement/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "pages/common/UserAgreement/TermsOfService/TermsOfService";
import Developer from "pages/developer/Developer";
import React, { useContext, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { parseJwt } from "utility";

import RouteComponent from "./RouteComponent";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.white.high,
  },
  loaderMessage: {
    marginTop: theme.customMixins.pxToRem(16),
    color: theme.palette.white.high,
    font: theme.typography.body.bold.font,
  },
}));

const queryClient = new QueryClient();

function App({ pca }) {
  const classes = useStyles();
  const { state, dispatch } = useContext(Store);

  useEffect(() => {
    dispatch({ type: "TOGGLE_LOADER", loading: true });
    if (window.location.pathname.includes("no-auth")) {
      dispatch({ type: "TOGGLE_LOADER", loading: false });
    } else {
      init(pca, dispatch);
    }
  }, [pca, dispatch]);

  const accounts = pca.getAllAccounts();
  const serverRoot = process.env.REACT_APP_SERVER_ROOT;
  const nonProdEnv = ["dev.", "sandbox."];
  const isProd = !nonProdEnv.some((p) => serverRoot.includes(p));

  const setNewIdTokenToken = async () => {
    console.info("AUTH", "Refreshing, the Id Token.");
    pca.setActiveAccount(accounts[0]);

    const { idToken } = await pca.acquireTokenSilent({
      scopes: [],
    });
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("idTokenValidFrom", (+new Date() / 1000).toString());
  };

  setInterval(async () => {
    const idTokenExpirationSeconds = 30 * 60;
    const idTokenValidFrom = localStorage.getItem("idTokenValidFrom");
    const currentTime = +new Date() / 1000;
    const lastUpdated = idTokenValidFrom ? parseInt(idTokenValidFrom) : 0;
    const isExpired = currentTime - lastUpdated > idTokenExpirationSeconds;

    if (isExpired) {
      try {
        await setNewIdTokenToken();
      } catch (idTokenRefetchError) {
        manageSessionOnAuthErrors(pca, idTokenRefetchError);
        // Dispatching State cleanup
        // dispatch({ type: "ADD_TOKEN", token: null });
        // dispatch({ type: "TOGGLE_LOADER", loading: true });
      }
    }
  }, 5 * 60 * 1000);

  axios.interceptors.request.use(async (config) => {
    const idTokenExpirationSeconds = 30 * 60;
    const idTokenValidFrom = localStorage.getItem("idTokenValidFrom");
    const currentTime = +new Date() / 1000;
    const lastUpdated = idTokenValidFrom ? parseInt(idTokenValidFrom) : 0;
    const isExpired = currentTime - lastUpdated > idTokenExpirationSeconds;

    const isUpdateRoleRequest = config.url.endsWith(
      "user-mgmt/enrollment/user",
    );

    if (isExpired && !isUpdateRoleRequest) {
      // Take 1 sec to execute
      console.info("AUTH", "Refreshing, the Id Token.");
      pca.setActiveAccount(accounts[0]);
      try {
        await setNewIdTokenToken();
      } catch (error) {
        manageSessionOnAuthErrors(pca, error);
      }
    }

    const token = localStorage.getItem("idToken");
    if (!token) throw new Error("ID Token not found.");

    const { extension_PhoneNumber } = parseJwt(token);
    config.url = config.url.replace("/{current}", `/${extension_PhoneNumber}`);

    config.headers.Authorization = `Bearer ${token}`;
    config.headers["content-type"] = "application/json";
    return config;
  });

  useEffect(() => {
    dispatch({ type: "IS_PRODUCTION", isProduction: isProd });
  }, [dispatch, isProd]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <QueryClientProvider client={queryClient}>
        {/* unauthenticated routes */}
        <Router>
          <Switch>
            {!isProd && (
              <Route
                component={Developer}
                path={unauthenticatedRoutes.developer}
                exact
              />
            )}
            <Route
              component={TermsOfService}
              path={unauthenticatedRoutes.termsOfService}
              exact
            />
            <Route
              component={PrivacyPolicy}
              path={unauthenticatedRoutes.privacyPolicy}
              exact
            />
          </Switch>
        </Router>
        {/* authenticated routes */}
        {!window.location.pathname.includes("no-auth") && (
          <MsalProvider instance={pca}>
            <Backdrop className={classes.backdrop} open={state.loading}>
              <CircularProgress color="inherit" />
              <Typography variant="h2" className={classes.loaderMessage}>
                Initializing
              </Typography>
            </Backdrop>

            <div className={classes.appContainer}>
              {<RouteComponent></RouteComponent>}
            </div>
            {!isProd && (
              <ReactQueryDevtools initialIsOpen={false}></ReactQueryDevtools>
            )}
          </MsalProvider>
        )}
      </QueryClientProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
