import { useMsal } from "@azure/msal-react";
import { Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Face, LocalLibraryOutlined, SendOutlined } from "@material-ui/icons";
import { Store } from "context/Context";
import { getUserDetails, postLog } from "helpers/api";
import { BASIC_CACHE } from "helpers/cacheConfiguration";
import { clinicianRoutes } from "helpers/routeConfig";
import ErrorMessage from "pages/common/ErrorMessage/ErrorMessage";
import SignOutConfirmation from "pages/common/SignOutConfirmation/SignOutConfirmation";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import {
  generateFullName,
  postSignOutFailure,
  postSignOutSuccess,
} from "utility";

import Logout from "../assets/icons/logout.svg";
import Logo from "../assets/images/logo.svg";

const useStyles = makeStyles((theme) => ({
  navContainer: {
    width: theme.customMixins.pxToRem(400),
    maxWidth: "80vw",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  navHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    flexGrow: 1,
    padding: theme.customMixins.pxToRem(15),
  },
  userName: {
    font: theme.typography.sectionSubtitle.medium.font,
  },
  titleIconContainer: {
    width: theme.customMixins.pxToRem(74),
    height: theme.customMixins.pxToRem(84),
    marginBottom: theme.customMixins.pxToRem(15),
  },
  navFooter: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: theme.customMixins.pxToRem(24),
  },
  navBody: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 6,
    padding: theme.customMixins.pxToRem(24),
  },
  navBodyItem: {
    padding: theme.customMixins.pxToRem(12, 0),
    display: "flex",
    cursor: "pointer",
  },
  navBodyItemTitle: {
    marginLeft: theme.customMixins.pxToRem(6),
  },
  navFooterItem: {
    display: "flex",
    cursor: "pointer",
  },
  divider: {
    background: theme.palette.secondary.main,
  },
}));

export const SideNavigation = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { instance } = useMsal();
  const [signOutDialog, setSignOutDialog] = useState(false);
  const { dispatch } = useContext(Store);

  const {
    data: userDetails,
    isLoading,
    isError,
  } = useQuery(["UserDetails"], getUserDetails, BASIC_CACHE);

  const fullName =
    !isLoading &&
    !isError &&
    generateFullName({
      firstName: userDetails.data.firstName,
      lastName: userDetails.data.lastName,
    });

  const handleOnSignOut = () => {
    postLog("logout");
    instance
      .logout()
      .then((_) => {
        // Dispatching State cleanup
        dispatch({ type: "ADD_TOKEN", token: null });
        dispatch({ type: "TOGGLE_LOADER", loading: true });

        // Post Sign Out Operations
        postSignOutSuccess();
      })
      .catch((logoutError) => {
        console.error({ logoutError });
        postSignOutFailure();
      });
  };
  return (
    <div data-testid="nav-container" className={classes.navContainer}>
      <div className={classes.navHeader}>
        <img src={Logo} alt="Logo" className={classes.titleIconContainer} />
        {isError && <ErrorMessage />}
        {!isError && (
          <Typography className={classes.userName} variant="h2" color="primary">
            {fullName}
          </Typography>
        )}
      </div>
      <Divider className={classes.divider} />
      <div className={classes.navBody}>
        <div
          className={classes.navBodyItem}
          onClick={() => {
            props.onRequestClose();
            history.push(clinicianRoutes.patientView);
          }}
          data-testid="nav-patients"
        >
          <Face color="primary" />
          <Typography color="primary" className={classes.navBodyItemTitle}>
            Patients
          </Typography>
        </div>
        <div
          className={classes.navBodyItem}
          onClick={() => {
            props.onRequestClose();
            history.push(clinicianRoutes.referrals);
          }}
          data-testid="nav-referrals"
        >
          <SendOutlined color="primary" />
          <Typography color="primary" className={classes.navBodyItemTitle}>
            Referrals
          </Typography>
        </div>
        <div
          className={classes.navBodyItem}
          onClick={() => {
            props.onRequestClose();
            history.push(clinicianRoutes.support);
          }}
          data-testid="nav-support"
        >
          <LocalLibraryOutlined color="primary" />
          <Typography color="primary" className={classes.navBodyItemTitle}>
            Support
          </Typography>
        </div>
      </div>
      <div className={classes.navFooter}>
        <div
          onClick={() => setSignOutDialog(true)}
          className={classes.navFooterItem}
          data-testid="nav-sign-out"
        >
          <img src={Logout} alt="Logout" />
          <Typography color="primary" className={classes.navBodyItemTitle}>
            Sign out
          </Typography>
        </div>
      </div>
      <SignOutConfirmation
        open={signOutDialog}
        onSignOut={() => handleOnSignOut()}
        onClose={() => setSignOutDialog(false)}
      />
    </div>
  );
};

SideNavigation.propTypes = {
  onRequestClose: PropTypes.func,
};

SideNavigation.defaultProps = {
  onRequestClose: () => {},
};

export default SideNavigation;
