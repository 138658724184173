import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { useGlobalNoDataStyles } from "./../global.styles";

const useStyles = makeStyles((theme) => ({
  text: {
    font: theme.typography.body.medium.font,
    color: theme.palette.primary.main,
    marginTop: theme.customMixins.pxToRem(24),
  },
  message: {
    color: theme.palette.primary.A800,
    font: theme.typography.body.bold.font,
    textAlign: "center",
    marginBottom: theme.customMixins.pxToRem(24),
  },
  guideInstructionTitle: {
    color: theme.palette.primary.A800,
    font: theme.typography.body.regular.font,
    textAlign: "left",
    "&:not(:first-child)": {},
  },
  guideInstructions: {
    color: theme.palette.primary.A800,
    font: theme.typography.body.regular.font,
    textAlign: "left",
    paddingLeft: theme.customMixins.pxToRem(8),
  },
}));

function NoMyPatients({ hidden, message }) {
  const globalClasses = useGlobalNoDataStyles();
  const classes = useStyles();

  if (hidden) return <></>;

  return (
    <div
      data-testid="no-data-root-container"
      className={globalClasses.noDataRootContainer}
    >
      <div className={classes.guideContainer}>
        <Typography variant="h4" className={classes.message}>
          {message}
        </Typography>

        <Typography className={classes.guideInstructionTitle}>
          To add patients to "My Patient" list:
        </Typography>

        <Typography variant="body1" className={classes.guideInstructions}>
          • Select a Patient"
        </Typography>
        <Typography variant="body1" className={classes.guideInstructions}>
          • Go to their profile
        </Typography>
        <Typography variant="body1" className={classes.guideInstructions}>
          • Turn on toggle for "My Patient"
        </Typography>
      </div>
    </div>
  );
}

export default NoMyPatients;

NoMyPatients.defaultProps = {
  message: 'You have not yet added patients to your "My Patient" list.',
  hidden: false,
};

NoMyPatients.propTypes = {
  /**
   * Message text to be shown on UI
   */
  message: PropTypes.string.isRequired,

  /**
   * Set UI visibility
   */
  hidden: PropTypes.bool,
};
