import { ALL_MEDICATIONS } from "samples/allMedications";
import { getDailyMedicationSample } from "samples/dailyMedications";
import { DELIVERY_RESPONSE } from "samples/delivery";
import { SAMPLE_PATIENT_LIST } from "samples/listPatients";
import { SAMPLE_MEDICATION_MANUAL } from "samples/medicationManual";
import { PREFERENCE_SAMPLE } from "samples/preferences";
import { REASON_CODE_MAPPING } from "samples/reasonCodeMapping";
import { REFERRAL_DETAILS } from "samples/referralDetails";
import { SAMPLE_REFERRALS } from "samples/referrals";
import { DELIVERY_DETAILS } from "samples/deliveryDetails";

import {
  API_DEV_HASH as adherenceTrackingApiHash,
  ROUTE_URL as adherenceTrackingApiRoute,
} from "./adherenceTracking";
import {
  API_DEV_HASH as getAllDeliveriesApiHash,
  ROUTE_URL as getAllDeliveriesApiRoute,
} from "./getAllDeliveries";
import {
  API_DEV_HASH as getAllMedicationsApiHash,
  ROUTE_URL as getAllMedicationsApiRoute,
} from "./getAllMedications";
import {
  API_DEV_HASH as getAllSmsConfigurationApiHash,
  ROUTE_URL as getAllSmsConfigurationApiRoute,
} from "./getAllSmsConfiguration";
import {
  API_DEV_HASH as getDailyMedicationApiHash,
  ROUTE_URL as getDailyMedicationApiRoute,
} from "./getDailyMedication";
import {
  API_DEV_HASH as getMedicationDataApiHash,
  ROUTE_URL as getMedicationDataApiRoute,
} from "./getMedicationData";
import {
  API_DEV_HASH as getMedicationManualApiHash,
  ROUTE_URL as getMedicationManualApiRoute,
} from "./getMedicationManual";
import {
  API_DEV_HASH as getMedicationSkipReasonsApiHash,
  ROUTE_URL as getMedicationSkipReasonsApiRoute,
} from "./getMedicationSkipReasons";
import {
  API_DEV_HASH as getPatientListApiHash,
  ROUTE_URL as getPatientListApiRoute,
} from "./getPatientList";
import {
  API_DEV_HASH as getPatientProfileApiHash,
  ROUTE_URL as getPatientProfileApiRoute,
} from "./getPatientProfile";
import {
  API_DEV_HASH as getReferralDetailsApiHash,
  ROUTE_URL as getReferralDetailsApiRoute,
} from "./getReferralDetails";
import {
  API_DEV_HASH as getReferralListApiHash,
  ROUTE_URL as getReferralListApiRoute,
} from "./getReferralList";
import {
  API_DEV_HASH as getUserDetailsApiHash,
  ROUTE_URL as getUserDetailsApiRoute,
} from "./getUserDetails";
import {
  API_DEV_HASH as getUserPreferencesApiHash,
  ROUTE_URL as getUserPreferencesApiRoute,
} from "./getUserPreferences";
import {
  API_DEV_HASH as reminderTrackingApiHash,
  ROUTE_URL as reminderTrackingApiRoute,
} from "./reminderTracking";
import {
  API_DEV_HASH as sendReferralDataApiHash,
  ROUTE_URL as sendReferralDataApiRoute,
} from "./sendReferralData";
import {
  API_DEV_HASH as updatePatientProfileApiHash,
  ROUTE_URL as updatePatientProfileApiRoute,
} from "./updatePatientProfile";
import {
  API_DEV_HASH as userEnrollmentRequestApiHash,
  ROUTE_URL as userEnrollmentRequestApiRoute,
} from "./userEnrollment";
import {
  API_DEV_HASH as getDeliveryDetailsApiHash,
  ROUTE_URL as getDeliveryDetailsApiRoute,
} from "./getDeliveryDetails";
import {
  API_DEV_HASH as postLogApiHash,
  ROUTE_URL as postLogApiRoute,
} from "./postLog";

export const API_DEV_HASHES = [
  {
    name: "getAllDeliveries",
    description: "Get all delivery for a given patient.",
    hashKey: getAllDeliveriesApiHash,
    route: getAllDeliveriesApiRoute,
    methods: ["GET"],
    sample: DELIVERY_RESPONSE,
  },
  {
    name: "adherenceTracking",
    hashKey: adherenceTrackingApiHash,
    route: adherenceTrackingApiRoute,
    methods: ["GET"],
    sample: {},
  },
  {
    name: "getAllMedications",
    hashKey: getAllMedicationsApiHash,
    route: getAllMedicationsApiRoute,
    methods: ["GET"],
    sample: ALL_MEDICATIONS,
  },
  {
    name: "getDailyMedication",
    hashKey: getDailyMedicationApiHash,
    route: getDailyMedicationApiRoute,
    methods: ["GET"],
    sample: getDailyMedicationSample("2021-10-11"),
  },
  {
    name: "reminderTracking",
    hashKey: reminderTrackingApiHash,
    route: reminderTrackingApiRoute,
    methods: ["GET"],
    sample: {},
  },
  {
    name: "getUserPreferences",
    hashKey: getUserPreferencesApiHash,
    route: getUserPreferencesApiRoute,
    methods: ["GET", "PUT"],
    sample: PREFERENCE_SAMPLE,
  },
  {
    name: "getMedicationSkipReasons",
    hashKey: getMedicationSkipReasonsApiHash,
    route: getMedicationSkipReasonsApiRoute,
    methods: ["GET"],
    sample: REASON_CODE_MAPPING,
  },
  {
    name: "getMedicationData",
    hashKey: getMedicationDataApiHash,
    route: getMedicationDataApiRoute,
    methods: ["GET"],
    sample: {},
  },
  {
    name: "getUserDetails",
    hashKey: getUserDetailsApiHash,
    route: getUserDetailsApiRoute,
    methods: ["GET"],
    sample: {},
  },
  {
    name: "sendReferralData",
    hashKey: sendReferralDataApiHash,
    route: sendReferralDataApiRoute,
    methods: ["GET"],
    sample: {},
  },
  {
    name: "getReferralList",
    hashKey: getReferralListApiHash,
    route: getReferralListApiRoute,
    methods: ["GET"],
    sample: SAMPLE_REFERRALS,
  },
  {
    name: "getReferralDetails",
    hashKey: getReferralDetailsApiHash,
    route: getReferralDetailsApiRoute,
    methods: ["GET"],
    sample: REFERRAL_DETAILS,
  },
  {
    name: "getPatientList",
    hashKey: getPatientListApiHash,
    route: getPatientListApiRoute,
    methods: ["GET"],
    sample: SAMPLE_PATIENT_LIST,
  },
  {
    name: "getAllSmsConfiguration",
    hashKey: getAllSmsConfigurationApiHash,
    route: getAllSmsConfigurationApiRoute,
    methods: ["GET"],
    sample: {},
  },
  {
    name: "getPatientProfile",
    hashKey: getPatientProfileApiHash,
    route: getPatientProfileApiRoute,
    methods: ["GET"],
    sample: {},
  },
  {
    name: "updatePatientProfile",
    hashKey: updatePatientProfileApiHash,
    route: updatePatientProfileApiRoute,
    methods: ["GET"],
    sample: {},
  },
  {
    name: "userEnrollmentRequest",
    hashKey: userEnrollmentRequestApiHash,
    route: userEnrollmentRequestApiRoute,
    methods: ["POST"],
    sample: {},
  },
  {
    name: "getMedicationManual",
    hashKey: getMedicationManualApiHash,
    route: getMedicationManualApiRoute,
    methods: ["GET"],
    sample: SAMPLE_MEDICATION_MANUAL,
  },
  {
    name: "getDeliveryDetails",
    hashKey: getDeliveryDetailsApiHash,
    route: getDeliveryDetailsApiHash,
    methods: ["GET"],
    sample: DELIVERY_DETAILS,
  },
  {
    name: "postLog",
    hashKey: postLogApiHash,
    route: postLogApiRoute,
    methods: ["POST"],
    sample: {},
  },
];
