import { PALETTE } from "./colorPalette";
import { MIXINS } from "./mixins";
import { SNACKBAR } from "./snackbar";
import { TYPOGRAPHY } from "./typography";

export const THEME_ATTRIBUTES = {
  shadows: Array(25).fill("none"),
  pageDimensions: {
    mobileMaxWidth: "480px",
  },
  typography: TYPOGRAPHY,
  palette: PALETTE,
  customMixins: MIXINS,
  customComponents: {
    snackbar: SNACKBAR,
  },
  customCss: {
    cardShadow: "2px 2px 4px rgb(0 0 0 / 10%)",
    navShadow: "0px 3px 14px rgb(0 0 0 / 38%)",
    boxShadow: "1px 1px 3px rgb(0 0 0 / 25%)",
    border: "1px solid #C8C8C8",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "MuiButton-root": {},
      },
    },
  },
};
