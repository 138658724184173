import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";

const useUnsavedChangesWarning = (isDirty) => {
  useEffect(() => {
    window.onbeforeunload = isDirty;
    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);
  const routerPrompt = <Prompt when={isDirty} message="" />;
  return [routerPrompt];
};

export default useUnsavedChangesWarning;
