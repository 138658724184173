import { post } from "../communication";
import { parseJwt } from "../../utility";

export const ROUTE_URL = "/user-mgmt/logging";
export const API_DEV_HASH = "devMode##logging";

export const postLog = (name, msg) => {
  let apiPromise;
  const message = msg || {};

  const token = localStorage.getItem("idToken");
  if (token) {
    const { extension_PhoneNumber } = parseJwt(token);
    message.user_phone = extension_PhoneNumber;
  }

  if (localStorage.getItem(API_DEV_HASH) === "true") {
    apiPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: { name, message } });
      }, 1000);
    });
  } else {
    apiPromise = post(ROUTE_URL, { name, message });
  }

  return apiPromise;
};
