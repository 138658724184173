import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { UI_ERROR_MESSAGES } from "constants/errorMessages";
import PropTypes from "prop-types";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  snackbar: theme.customComponents.snackbar.error,
}));

function ErrorMessage({ errorMessage, vertical, horizontal }) {
  const classes = useStyles();
  const [openSnackBar, setOpenSnackBar] = useState(true);

  const handleClose = () => {
    setOpenSnackBar(false);
  };

  return (
    <Snackbar
      data-testid="snackbar"
      anchorOrigin={{
        vertical: vertical,
        horizontal: horizontal,
      }}
      open={openSnackBar}
      className={classes.snackbar}
      autoHideDuration={5000}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
      message={<span id="message-id">{errorMessage}</span>}
    />
  );
}

ErrorMessage.propTypes = {
  /**
   * Error Message
   */

  errorMessage: PropTypes.string,
  /**
   * Vertical position
   */
  vertical: PropTypes.string,

  /**
   * Horizontal position
   */
  horizontal: PropTypes.string,
};

ErrorMessage.defaultProps = {
  errorMessage: UI_ERROR_MESSAGES.generic,
  vertical: "top",
  horizontal: "center",
};

export default ErrorMessage;
