import { makeStyles } from "@material-ui/core/styles";

import NoDataSvg from "./no-data.svg";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    marginTop: "8rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  text: {
    font: theme.typography.body.regular,
    color: theme.palette.primary.main,
    marginTop: theme.customMixins.pxToRem(24),
  },
}));

function NoMedication() {
  const classes = useStyles();

  return (
    <div data-testid="root-container" className={classes.rootContainer}>
      <img src={NoDataSvg} alt="No data available" />
      <p className={classes.text}>
        Our pharmacist is reviewing your medication. Please check back in 3-5
        days to view your medication.
      </p>
    </div>
  );
}

export default NoMedication;
