import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.customMixins.pxToRem(24),
  },
}));

function SimplePageLoader({ hidden }) {
  const classes = useStyles();

  return (
    <>
      {!hidden && (
        <Zoom in={true} style={{ transitionDelay: "10ms" }}>
          <div data-testid="loader" className={classes.loaderContainer}>
            <CircularProgress color="inherit" />
          </div>
        </Zoom>
      )}
    </>
  );
}

SimplePageLoader.defaultProps = {
  hidden: false,
};

SimplePageLoader.propTypes = {
  hidden: PropTypes.bool,
};

export default SimplePageLoader;
