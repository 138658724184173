import { postLog } from "helpers/api";

export const postSignOutSuccess = () => {
  console.log("Logout Action", { success: true });

  // Browser Storage Cleanup
  localStorage.clear();
  sessionStorage.clear();

  // Log last Sign Out Request
  localStorage.setItem("lastSignOutRequest", +new Date());

  postLog("logout");

  // Refresh page
  window.location.replace("/");
};

export const postSignOutFailure = () => {
  console.log("Logout Action", { success: false });

  postLog("logout");

  // Log last Sign Out Request
  localStorage.setItem("lastSignOutRequest", +new Date());
};

let isMonitoringSignOut = false;
export const monitorSignOut = () => {
  if (isMonitoringSignOut) return;
  console.log("INIT | monitorSignOut");
  isMonitoringSignOut = true;
  const lastSignOutRequest = localStorage.getItem("lastSignOutRequest");

  if (lastSignOutRequest) {
    const elapsedTime = (+new Date() - lastSignOutRequest) / 3600;

    console.log({ elapsedTime });

    if (elapsedTime < 30) {
      console.info("Insecure");
    }
  } else {
    console.warn("not monitoring SignOut", { lastSignOutRequest });
  }
};
