export const SAMPLE_REFERRALS = {
  referrals: [
    {
      referralFirstName: "MARK",
      referralLastName: null,
      referralStatus: "Consult Declined",
      latestUpdateAt: 1632321025000,
      isMyReferral: false,
      referralId: "",
    },
    {
      referralFirstName: null,
      referralLastName: "DRUKER",
      referralStatus: "Left Voicemail",
      latestUpdateAt: 1632343208000,
      isMyReferral: true,
      referralId: "0065G00000dAuO3QAK",
    },
    {
      referralFirstName: "CHRISTINE",
      referralLastName: "VENTRESCA",
      referralStatus: "Consult Declined",
      latestUpdateAt: 1632411774000,
      isMyReferral: false,
      referralId: "0065G00000dBc7DQAS",
    },
    {
      referralFirstName: "ALINA",
      referralLastName: "LYSIUK",
      referralStatus: "",
      latestUpdateAt: 1632428423000,
      isMyReferral: true,
      referralId: "0065G00000dB7cmQAC",
    },
    {
      referralFirstName: "HENRY",
      referralLastName: "HENNESSY",
      referralStatus: null,
      latestUpdateAt: 1632494922000,
      isMyReferral: false,
      referralId: "0065G00000dBIspQAG",
    },
    {
      referralFirstName: "AVRA",
      referralLastName: "PRESSMAN",
      referralStatus: "Call Back Request",
      latestUpdateAt: 1632939796000,
      isMyReferral: false,
      referralId: "0065G00000dBc6xQAC",
    },
    {
      referralFirstName: "PAUL",
      referralLastName: "NICE",
      referralStatus: "Call Back Request",
      latestUpdateAt: 1633011644000,
      isMyReferral: false,
      referralId: "0065G00000dBzSVQA0",
    },
    {
      referralFirstName: "JOAN",
      referralLastName: "WHITNEY",
      referralStatus: "Consult Declined",
      latestUpdateAt: 1633025972000,
      isMyReferral: false,
      referralId: "0065G00000dBvq9QAC",
    },
    {
      referralFirstName: "JAMES",
      referralLastName: "STUART",
      referralStatus: "Left Voicemail",
      latestUpdateAt: 1633026486000,
      isMyReferral: false,
      referralId: "0065G00000dBzPvQAK",
    },
    {
      referralFirstName: "SANTINA",
      referralLastName: "SCARPRONE",
      referralStatus: "Call Back Request",
      latestUpdateAt: 1633097864000,
      isMyReferral: false,
      referralId: "0065G00000dC472QAC",
    },
    {
      referralFirstName: "R.B.",
      referralLastName: "REED",
      referralStatus: "Left Voicemail",
      latestUpdateAt: 1633111883000,
      isMyReferral: false,
      referralId: "0065G00000dC41zQAC",
    },
    {
      referralFirstName: "RUTH",
      referralLastName: "GIERING",
      referralStatus: "Call Back Request",
      latestUpdateAt: 1633362385000,
      isMyReferral: false,
      referralId: "0065G00000dBzOmQAK",
    },
    {
      referralFirstName: "DORIS",
      referralLastName: "MOYER",
      referralStatus: "Consult Scheduled",
      latestUpdateAt: 1633094089000,
      isMyReferral: false,
      referralId: "0065G00000dC3yoQAC",
    },
    {
      referralFirstName: "John",
      referralLastName: "Doe",
      referralStatus: "Pending Initial Call",
      latestUpdateAt: 1634726293416,
      isMyReferral: false,
      referralId: null,
    },
    {
      referralFirstName: "first",
      referralLastName: "last",
      referralStatus: "Pending Initial Call",
      latestUpdateAt: 1634809806225,
      isMyReferral: false,
      referralId: null,
    },
  ],
};
