import { SAMPLE_MEDICATION_MANUAL } from "../../samples/medicationManual";
import { getFromExternal } from "../communication";
import { ValidationError } from "./../errors";

export const ROUTE_URL =
  "https://api.fda.gov/drug/label.json?search=openfda.spl_id.exact";
export const API_DEV_HASH = "devMode##getMedicationManual";

const UI_CONFIGURATION = [
  {
    title: "Indication and usage",
    key: "indications_and_usage",
  },
  {
    title: "Dosage and administration",
    key: "dosage_and_administration",
  },
  {
    title: "Dosage forms and strengths",
    key: "dosage_forms_and_strengths",
  },
  {
    title: "Contradictions",
    key: "contraindications",
  },
  {
    title: "Warnings and precautions",
    key: "warnings_and_cautions",
  },
  {
    title: "Adverse reactions",
    key: "adverse_reactions",
  },
  {
    title: "Use in specific populations",
    key: "use_in_specific_populations",
  },
  {
    title: "Overdosage",
    key: "overdosage",
  },
  {
    title: "Description",
    key: "description",
  },
];

export const getMedicationManual = async (splId) => {
  if (!splId) {
    throw new ValidationError("INVALID SPL_ID", {
      value: { splId },
    });
  }
  const route = `${ROUTE_URL}:${splId}`;
  let response;

  if (localStorage.getItem(API_DEV_HASH) === "true") {
    response = await new Promise((resolve) => {
      setTimeout(() => {
        resolve(SAMPLE_MEDICATION_MANUAL);
      }, 1000);
    });
  } else {
    response = await getFromExternal(route).then((response) =>
      Promise.resolve(response.data),
    );
  }

  // Todo: Error Management
  const medicationManual = response.results[0];
  const transformedResponse = UI_CONFIGURATION.map((item) => {
    const contentArray = medicationManual[item.key];
    const content = Array.isArray(contentArray) ? contentArray[0] : "";
    return { ...item, content };
  });

  return transformedResponse;
};
