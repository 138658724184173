import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    margin: theme.customMixins.pxToRem(16 * 4, 0),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  text: {
    opacity: "0.74",
  },
}));

function NoMedicationDetails({ hidden, message }) {
  const classes = useStyles();
  if (hidden) return <></>;

  return (
    <div data-testid="no-data-root-container" className={classes.rootContainer}>
      <Typography className={classes.text}>{message}</Typography>
    </div>
  );
}

NoMedicationDetails.defaultProps = {
  message: "Medication details not found.",
};

NoMedicationDetails.propTypes = {
  /**
   * Message text to be shown on UI
   */
  message: PropTypes.string.isRequired,

  /**
   * Set UI visibility
   */
  hidden: PropTypes.bool,
};

export default NoMedicationDetails;
