import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getUserDetails } from "helpers/api";
import { BASIC_CACHE } from "helpers/cacheConfiguration";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme) => ({
  greeting: {
    font: theme.typography.sectionSubtitle.bold.font,
    color: theme.palette.primary.A900,
    margin: theme.customMixins.pxToRem(24, 0),
  },
}));

export const getDayGreeting = (prefix = "Good", ts = new Date()) => {
  let hour = ts.getHours();
  prefix = prefix.trim();

  if (hour < 12) return `${prefix} morning`;
  else if (hour < 16) return `${prefix} afternoon`;
  else return `${prefix} evening`;
};

const DailyGreeting = ({ hidden }) => {
  const classes = useStyles();

  const {
    isLoading,
    data: greetingMessage,
    isError,
  } = useQuery(
    ["UserDetails-greeting-message"],
    () => {
      return getUserDetails().then((resp) => {
        const firstName = resp.data.firstName;
        const formattedUserFirstName = firstName ? `, ${firstName}` : "";
        return `${
          getDayGreeting() + formattedUserFirstName
        }. Here is your medication plan for today.`;
      });
    },
    BASIC_CACHE,
  );

  if (hidden) return <></>;

  return (
    <>
      {!isLoading && !isError && (
        <Typography
          data-testid="greeting-message"
          className={classes.greeting}
          variant="h2"
        >
          {greetingMessage}
        </Typography>
      )}
    </>
  );
};

export default DailyGreeting;
