import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CONFIGURATION } from "constants/configuration";
import PropTypes from "prop-types";
import { useState } from "react";
import { capitalizeFirstLetter } from "utility";

import RedCircle from "./assets/redCircle.svg";
import SolidGreenTick from "./assets/solidGreenTick.svg";

const useStyles = makeStyles((theme) => ({
  cardRow: {
    display: "flex",
    alignItems: "center",

    padding: theme.customMixins.pxToRem(12),
    margin: theme.customMixins.pxToRem(12, 0),
    borderLeft: `${theme.customMixins.pxToRem(4)} solid`,
    borderLeftColor: "transparent",
    flexWrap: "nowrap",
    "&.success-state": {
      borderLeftColor: theme.palette.success.main,
    },
    "&.warning-state": {
      borderLeftColor: theme.palette.error.secondary,
      "& h5": {
        color: theme.palette.error.secondary,
      },
    },
  },
  cardRowHighlighted: {
    paddingLeft: theme.customMixins.pxToRem(16),
    background: theme.palette.error.light,
    opacity: "0.7",
  },
  cardRowBordered: {
    border: theme.customCss.border,
    margin: theme.customMixins.pxToRem(0),
    "&:last-child": {
      borderBottom: `none`,
      marginBottom: theme.customMixins.pxToRem(0),
    },
  },
  cardRowThumbnail: {
    width: theme.customMixins.pxToRem(25),
    height: theme.customMixins.pxToRem(25),
    marginRight: theme.customMixins.pxToRem(16),
  },
  cardDetailsContainer: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    "& :nth-child(2)": {
      marginTop: theme.customMixins.pxToRem(8),
    },
  },
  drugName: {
    textTransform: "capitalize",
    font: theme.typography.body.medium.font,
    color: theme.palette.textPrimary.emphasisHigh,
  },
  drugDetails: {
    font: theme.typography.body.medium.font,
    color: theme.palette.textPrimary.emphasisLow,
  },
  missedReason: {
    padding: theme.customMixins.pxToRem(0, 14, 14, 14),
  },
  missedReasonCodeContainer: {
    textAlign: "center",
    marginBottom: theme.customMixins.pxToRem(8),
    "& *": {
      font: theme.typography.description.medium.font,
      color: theme.palette.textPrimary.emphasisHigh,
    },
  },
  missedNotesContainer: {
    border: theme.customCss.border,
    borderRadius: theme.customMixins.pxToRem(4),
    textAlign: "center",
    padding: theme.customMixins.pxToRem(14),

    "& *": {
      font: theme.typography.description.regular.font,
      color: theme.palette.textPrimary.emphasisLow,
    },
  },
}));

const modifyQuantity = (dosageQty, unitOfDosage) => {
  if (isNaN(dosageQty)) return null;

  const dosageQtyNumber = Number(dosageQty);

  // Data Cleaning
  if (dosageQtyNumber === 0) return null;

  // Display Not Required
  if (dosageQtyNumber === 1) return null;

  return `${dosageQtyNumber.toString()} ${unitOfDosage}`;
};

export function MedicalCardRow(props) {
  const classes = useStyles();
  const medicationData = props.rowData;
  const adherenceTrackingData = medicationData.adherenceTracking;
  const [isTaken, setIsTaken] = useState(
    adherenceTrackingData && adherenceTrackingData.isTaken,
  );

  const labelingStateClass = isTaken ? "success-state" : "warning-state";

  const showMissedReason = !!props.showMissedReason;
  const displayQuantity = modifyQuantity(
    medicationData.dosageQty,
    medicationData.unitOfDosage,
  );

  const handleMarkAsNotTaken = () => {
    if (!props.readOnly) {
      props.onLabelClicked(true);
    }
  };

  const handleMarkAsTaken = () => {
    if (!props.readOnly) {
      props.onLabelClicked(false);
      setIsTaken(true);
    }
  };

  return (
    <>
      <Grid
        key={classes.drugCode}
        container
        justifyContent="space-around"
        className={`${classes.cardRow} ${
          props.showLabeling ? labelingStateClass : null
        } ${props.showRowSeparators ? classes.cardRowBordered : null}`}
      >
        {CONFIGURATION.showMedicationImages && (
          <>
            {medicationData.drugImageUrl && (
              <img
                className={classes.cardRowThumbnail}
                src={
                  medicationData.drugImageUrl
                    ? medicationData.drugImageUrl
                    : "https://via.placeholder.com/150"
                }
                alt="ico"
              ></img>
            )}
          </>
        )}
        <Grid
          className={classes.cardDetailsContainer}
          onClick={
            props.readOnly ? undefined : () => props.showDetails(medicationData)
          }
          item
          xs={11}
          data-testid="show-details"
        >
          <Typography
            className={classes.drugName}
            data-testid="drug-name"
            color="textPrimary"
            variant="h5"
          >
            {medicationData.drugName.toLowerCase()} &nbsp;
            {medicationData.strength?.toLowerCase()}
          </Typography>
          {displayQuantity && (
            <Typography
              className={classes.drugDetails}
              color="textPrimary"
              variant="h6"
            >
              {displayQuantity}
            </Typography>
          )}
          {props.showDosageDetails && (
            <Typography
              className={classes.drugDetails}
              color="textPrimary"
              variant="h6"
            >
              {capitalizeFirstLetter(medicationData.description?.toLowerCase())}
            </Typography>
          )}
        </Grid>

        {props.showLabeling && isTaken && (
          <img
            onClick={handleMarkAsNotTaken}
            src={SolidGreenTick}
            alt="solidGreenTick"
          />
        )}
        {props.showLabeling && !isTaken && (
          <img onClick={handleMarkAsTaken} src={RedCircle} alt="RedCircle" />
        )}
      </Grid>
      {showMissedReason && adherenceTrackingData.missedReasonCode && !isTaken && (
        <Grid
          key={classes.missedReason}
          container
          justifyContent="center"
          className={classes.missedReason}
        >
          {adherenceTrackingData?.missedReasonCode && (
            <Grid
              className={classes.missedReasonCodeContainer}
              onClick={
                props.readOnly
                  ? undefined
                  : () => props.showDetails(medicationData)
              }
              item
              xs={12}
              data-testid="missed-reasonCode-container"
            >
              <Typography
                className={classes.missedReasonCode}
                data-testid="missed-reason-code"
                color="textPrimary"
                variant="h4"
              >
                {props.missedReasons[adherenceTrackingData.missedReasonCode]}
              </Typography>
            </Grid>
          )}
          {adherenceTrackingData.missedNotes && (
            <Grid
              className={classes.missedNotesContainer}
              onClick={
                props.readOnly
                  ? undefined
                  : () => props.showDetails(medicationData)
              }
              item
              xs={12}
              data-testid="missed-notes-container"
            >
              <Typography
                className={classes.missedNotes}
                data-testid="missed-notes"
                variant="h5"
              >
                {adherenceTrackingData.missedNotes}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

MedicalCardRow.defaultProps = {
  showRowSeparators: false,
  showDosageDetails: true,
  showMissedReason: false,
  showDetails: () => {},
  showLabeling: false,
  onLabelClicked: () => {},
  readOnly: false,
  missedReasons: {},
};

MedicalCardRow.propTypes = {
  rowData: PropTypes.shape({
    drugImageUrl: PropTypes.oneOfType([PropTypes.string]),
    drugName: PropTypes.string.isRequired,
    adherenceTracking: PropTypes.object,
    description: PropTypes.string,
    dosageQty: PropTypes.string,
    unitOfDosage: PropTypes.string,
  }).isRequired,

  showDosageDetails: PropTypes.bool,
  showRowSeparators: PropTypes.bool,
  showMissedReason: PropTypes.bool,

  showDetails: PropTypes.func,

  showLabeling: PropTypes.bool,
  onLabelClicked: PropTypes.func,
  readOnly: PropTypes.bool,
  missedReasons: PropTypes.object,
};

export default MedicalCardRow;
