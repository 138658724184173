export const ALL_MEDICATIONS = {
  medications: [
    {
      drugName: "A Testing Med", // Required (UI sorting and display)
      drugCode: 12345667, // Required {used for drug details}
      drugImageUrl: null,
      dosageQty: null,
      unitOfDosage: null,
      strength: null,
      unitOfStrength: null,
      description: null,
      isPartOfPillPack: null,
      consumptionCadence: "DAILY", // Required {categorization} ["DAILY", "LESS FREQUENTLY", "AS NEEDED"]
    },
    {
      drugName: "Centrum Chewable Tablet",
      drugCode: 5452840,
      drugImageUrl: "xyz",
      dosageQty: "1",
      unitOfDosage: "Tablet, Chewable",
      strength: "0.4 mg-18 mg-3,500 unit",
      unitOfStrength: "to be removed",
      description: "Rx Pricing: OTC pricing",
      isPartOfPillPack: "yes",
      consumptionCadence: "DAILY",
    },
    {
      drugName: "Loratadine 10 Mg Tablet",
      drugCode: 45802100000,
      drugImageUrl: "xyz",
      dosageQty: "1",
      unitOfDosage: "Tablet",
      strength: "10 mg",
      unitOfStrength: "to be removed",
      description: "Rx Pricing: OTC pricing",
      isPartOfPillPack: "yes",
      consumptionCadence: "DAILY",
    },
    {
      drugName: "Atenolol 50 Mg Tablet",
      drugCode: 93075200,
      drugImageUrl: "xyz",
      dosageQty: "1",
      unitOfDosage: "Tablet",
      strength: "50 mg",
      unitOfStrength: "to be removed",
      description: "Generic Bottles",
      isPartOfPillPack: "yes",
      consumptionCadence: "TAKE ONE TABLET BY MOUTH IN THE EVENING",
    },
    {
      drugName: "Loratadine 10 Mg Tablet",
      drugCode: 45802100000,
      drugImageUrl: "xyz",
      dosageQty: "1",
      unitOfDosage: "Tablet",
      strength: "10 mg",
      unitOfStrength: "to be removed",
      description: "Rx Pricing: OTC pricing",
      isPartOfPillPack: "yes",
      consumptionCadence: "DAILY",
    },
    {
      drugName: "Fluticasone Prop 50 Mcg Spray",
      drugCode: 60505100000,
      drugImageUrl: "xyz",
      dosageQty: "2 SPRAYS",
      unitOfDosage: "Spray, Suspension",
      strength: "50 mcg/actuation",
      unitOfStrength: "to be removed",
      description: "Rx Pricing: OTC pricing",
      isPartOfPillPack: "yes",
      consumptionCadence: "DAILY",
    },
    {
      drugName: "Atenolol 50 Mg Tablet",
      drugCode: 65862000000,
      drugImageUrl: "xyz",
      dosageQty: "1",
      unitOfDosage: "Tablet",
      strength: "50 mg",
      unitOfStrength: "to be removed",
      description: "Generic Bottles",
      isPartOfPillPack: "yes",
      consumptionCadence: "DAILY",
    },
    {
      drugName: "Ipratropium-Albuterol 0.5-3(2.5) Mg/3 Ml",
      drugCode: 487020000,
      drugImageUrl: "xyz",
      dosageQty:
        "INHALE 3MLS (ONE VIAL) VIA NEBULIZER FOUR TIMES DAILY AS NEEDED",
      unitOfDosage: "Solution for Nebulization",
      strength: "0.5 mg-3 mg (2.5 mg base)/3 mL",
      unitOfStrength: "to be removed",
      description: "Inhalers",
      isPartOfPillPack: "yes",
      consumptionCadence:
        "INHALE 3MLS (ONE VIAL) VIA NEBULIZER FOUR TIMES DAILY AS NEEDED",
    },
  ],
  success: true,
};
