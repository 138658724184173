import { UtilityValidationError } from "helpers/errors";

import { formatToStandardDate } from "./dateValidations";

export class TimeUtility {
  capsLock = true;

  getElapsedMinutes = (hh, mm, ss, amPm = null) => {
    let offset = 0;
    [hh, mm, ss] = [parseInt(hh), parseInt(mm), parseInt(ss)];

    if (typeof amPm === "string") {
      amPm = amPm.toUpperCase();
      if (amPm === "PM" && hh !== 12) offset = 12;
      else if (amPm === "AM" && hh === 12) offset = -12;
    }

    const elapsedMinutes = hh * 60 + mm + ss / 60 + offset * 60;
    return elapsedMinutes;
  };

  split24HourFormat = (timeIn24hrFormat) => {
    // Check 1: Datatype
    if (typeof timeIn24hrFormat !== "string") {
      throw new UtilityValidationError("Invalid time format");
    }

    // Check 2: Time Format
    const timeParts = timeIn24hrFormat.split(":");
    if (timeParts.length < 2 || timeParts.length > 3) {
      throw new UtilityValidationError("Invalid time format");
    } else if (timeParts.length === 2) {
      timeParts.push("00");
    }

    // Check 3: Invalid Time Values
    const [hh, mm, ss] = timeParts.map((v) => parseInt(v));
    if (hh < 0 || hh > 24)
      throw new UtilityValidationError("Invalid time format");
    if (mm < 0 || mm > 59)
      throw new UtilityValidationError("Invalid time format");
    if (ss < 0 || ss > 59)
      throw new UtilityValidationError("Invalid time format");

    return { hh, mm, ss };
  };

  split12HourFormat(timeIn12hrFormat) {
    // Check 1: Datatype
    if (typeof timeIn12hrFormat !== "string") {
      throw new UtilityValidationError("Invalid time format.");
    }

    const [time, amPm] = timeIn12hrFormat.split(" ");
    const timeParts = time.split(":");
    if (timeParts.length < 2 || timeParts.length > 3) {
      throw new UtilityValidationError("Invalid time format");
    } else if (timeParts.length === 2) {
      timeParts.push("00");
    }

    const [hh, mm, ss] = timeParts.map((v) => parseInt(v));
    if (hh < 0 || hh > 12)
      throw new UtilityValidationError("Invalid time format");
    if (mm < 0 || mm > 59)
      throw new UtilityValidationError("Invalid time format");
    if (ss < 0 || ss > 59)
      throw new UtilityValidationError("Invalid time format");

    return { hh, mm, ss, amPm };
  }

  convertEpochToLocalTimeString = (epochTime) => {
    const dateObject = new Date(epochTime * 1000);
    const timeString = dateObject.toTimeString()?.split(" ");
    return timeString[0];
  };

  convertLocalTimeStringToEpoch = (timeString, date = new Date()) => {
    const dateString = formatToStandardDate(date, "-");
    return +new Date(`${dateString}T${timeString}`) / 1000;
  };

  convertTimeTo12HrFormat = (epochTime, includeSeconds = false) => {
    if (!epochTime) return;
    const timeIn24hrFormat = this.convertEpochToLocalTimeString(epochTime);
    let { hh, mm, ss } = this.split24HourFormat(timeIn24hrFormat);

    let amPm = hh < 12 ? "AM" : "PM";
    if (hh > 12) hh = hh - 12;
    if (hh === 0) hh = 12;

    hh = this._padDigits(hh);
    mm = this._padDigits(mm);
    ss = this._padDigits(ss);

    if (includeSeconds) {
      return `${hh}:${mm}:${ss} ${amPm}`;
    } else {
      return `${hh}:${mm} ${amPm}`;
    }
  };

  /**
   * @description Converts 12hr Format to 24hr format
   *  | [10:00 AM, 10:00am] are valid inputs
   * @param {string} time12HrFormat
   * @returns {string} time24HrFormat
   */
  convertTimeTo24HrFormat = (time12HrFormat) => {
    if (typeof time12HrFormat !== "string") return "null";
    let [time, period] = time12HrFormat.split(" ");
    let [hh, mm] = time.split(":").map((v) => parseInt(v));
    period = period.toLowerCase();

    if (period === "pm" && hh !== 12) hh += 12;
    else if (period === "am" && hh === 12) hh = 0;

    return `${this._padDigits(hh)}:${this._padDigits(mm)}:00`;
  };

  getTimeDiff = (epochTime, baseDateObj = new Date()) => {
    const timeIn24hrFormat = this.convertEpochToLocalTimeString(epochTime);
    const [hh, mm, ss] = timeIn24hrFormat.split(":");
    const elapsedTime = hh * 60 + mm * 1 + ss / 60;
    const baseTime = baseDateObj.getHours() * 60 + baseDateObj.getMinutes();
    return elapsedTime - baseTime;
  };

  checkTimeInRange = (epochTime, range = 1, baseDateObj = new Date()) => {
    const diffTime = Math.abs(this.getTimeDiff(epochTime, baseDateObj));
    return diffTime <= range * 60;
  };

  checkTimeOverdue = (
    epochTime,
    toleranceMin = 1,
    baseDateObj = new Date(),
  ) => {
    const diffTime = -this.getTimeDiff(epochTime, baseDateObj);
    return diffTime > toleranceMin * 60;
  };

  _padDigits = (number, digits = 2) => {
    return (
      Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
    );
  };
}
