const getUid = () => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 16; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const EARLY_MORNING_MEDS = [
  {
    consumptionDate: "2019-12-31",
    drugName: "Test Medication", // Required (UI sorting and display)
    drugCode: getUid(), // Required {used for drug details}
    drugImageUrl:
      "https://dev.app.homefreerx.com/static/drug-images/images/AVG02670.JPG",
    dosageQty: 2.0001,
    unitOfDosage: "Tablets",
    isPartOfPillPack: null,
    beforeAfterMealFlag: null,
    strength: null,
    timeOfDay: "EARLY MORNING", // Required {categorization} ["EARLY MORNING", "MORNING", "NOON", "EVENING", "BEDTIME"]
    description: null,
    consumptionCadence: null, // {Finding as needed} ["TODAY", "AS_NEEDED"]
    uniqueIdentifier: getUid(), // Required {Adherence}
    adherenceTracking: {
      isTaken: null,
      takenTime: null,
      missedReasonCode: null,
      missedNotes: null,
      reminderDateTime: null,
    },
  },
];

export const MORNING_MEDS = [
  {
    consumptionDate: "2019-12-31",
    drugName: "SPIRONOLACTONE",
    drugCode: getUid(),
    drugImageUrl:
      "https://dev.app.homefreerx.com/static/drug-images/images/AVG02670.JPG",
    dosageQty: "",
    unitOfDosage: "",
    isPartOfPillPack: "",
    beforeAfterMealFlag: null,
    strength: "",
    timeOfDay: "MORNING",
    description: "",
    consumptionCadence: "DAILY",
    uniqueIdentifier: getUid(),
    splId: "90cc8c86-2db8-4687-8649-8127834e2c14",
    adherenceTracking: {
      isTaken: null,
      takenTime: null,
      missedReasonCode: null,
      missedNotes: null,
      reminderDateTime: null,
    },
  },
  {
    consumptionDate: "2019-12-31",
    drugName: "SPIRONOLACTONE",
    drugCode: getUid(),
    drugImageUrl:
      "https://dev.app.homefreerx.com/static/drug-images/images/AVG02670.JPG",
    dosageQty: "1.0000",
    unitOfDosage: "Tablet",
    isPartOfPillPack: "yes",
    beforeAfterMealFlag: null,
    strength: "25 milligrams",
    timeOfDay: "MORNING",
    description: "TAKE ONE TABLET BY MOUTH IN THE MORNING",
    consumptionCadence: "DAILY",
    uniqueIdentifier: getUid(),
    splId: "90cc8c86-2db8-4687-8649-8127834e2c14",
    adherenceTracking: {
      isTaken: null,
      takenTime: null,
      missedReasonCode: null,
      missedNotes: null,
      reminderDateTime: null,
    },
  },
  {
    consumptionDate: "2019-12-31",
    drugName: "SPIRONOLACTONE",
    drugCode: getUid(),
    drugImageUrl:
      "https://dev.app.homefreerx.com/static/drug-images/images/AVG02670.JPG",
    dosageQty: "1.0000",
    unitOfDosage: "Tablet",
    isPartOfPillPack: "yes",
    beforeAfterMealFlag: null,
    strength: "25 milligrams",
    timeOfDay: "MORNING",
    description: "TAKE ONE TABLET BY MOUTH IN THE MORNING",
    consumptionCadence: "DAILY",
    uniqueIdentifier: getUid(),
    adherenceTracking: {
      isTaken: null,
      takenTime: null,
      missedReasonCode: null,
      missedNotes: null,
      reminderDateTime: null,
    },
  },
  {
    consumptionDate: "2019-12-31",
    drugName: "THIS DRUG NAME is tO Long",
    drugCode: getUid(),
    drugImageUrl:
      "https://dev.app.homefreerx.com/static/drug-images/images/AVG02670.JPG",
    dosageQty: "1.0000",
    unitOfDosage: "Tablet",
    isPartOfPillPack: "yes",
    beforeAfterMealFlag: null,
    strength: "10 mg",
    timeOfDay: "MORNING",
    description: "TAKE ONE TABLET BY MOUTH IN THE MORNING",
    consumptionCadence: "DAILY",
    uniqueIdentifier: getUid(),
    splId: "90cc8c86-2db8-4687-8649-8127834e2c14",
    adherenceTracking: {
      isTaken: null,
      takenTime: null,
      missedReasonCode: null,
      missedNotes: null,
      reminderDateTime: null,
    },
  },
  {
    consumptionDate: "2019-12-31",
    drugName: "LEVOTHYROXINE sodium",
    drugCode: getUid(),
    drugImageUrl:
      "https://dev.app.homefreerx.com/static/drug-images/images/AVG02670.JPG",
    dosageQty: "1.0000",
    unitOfDosage: "Tablet",
    isPartOfPillPack: "yes",
    beforeAfterMealFlag: "ON AN EMPTY STOMACH",
    strength: "25 mcg",
    timeOfDay: "MORNING",
    description:
      "TAKE ONE TABLET BY MOUTH IN THE EARLY MORNING ON AN EMPTY STOMACH ONE HOUR BEFORE BREAKFAST",
    consumptionCadence: "DAILY",
    uniqueIdentifier: getUid(),
    splId: "90cc8c86-2db8-4687-8649-8127834e2c14",
    adherenceTracking: {
      isTaken: null,
      takenTime: null,
      missedReasonCode: null,
      missedNotes: null,
      reminderDateTime: null,
    },
  },
];

export const TAKE_AS_PRESCRIBED_MEDS = [
  {
    consumptionDate: "2019-12-31",
    drugName: "DIGOXIN",
    drugCode: getUid(),
    drugImageUrl:
      "https://dev.app.homefreerx.com/static/drug-images/images/AVG02670.JPG",
    dosageQty: "1.0000",
    unitOfDosage: "Tablet",
    isPartOfPillPack: "yes",
    beforeAfterMealFlag: null,
    strength: "125 mcg",
    timeOfDay: null,
    description: "TAKE ONE TABLET BY MOUTH EVERY OTHER DAY",
    consumptionCadence: "EVERY OTHER DAY",
    uniqueIdentifier: getUid(),
    splId: "90cc8c86-2db8-4687-8649-8127834e2c14",
    adherenceTracking: {
      isTaken: true,
      takenTime: 1631743200,
      missedReasonCode: "01",
      missedNotes: null,
      reminderDateTime: null,
    },
  },
];

export const BEDTIME_MEDS = [
  {
    consumptionDate: "2019-12-31",
    drugName: "ESZOPICLONE",
    drugCode: getUid(),
    drugImageUrl:
      "https://dev.app.homefreerx.com/static/drug-images/images/AVG02670.JPG",
    dosageQty: "1.0000",
    unitOfDosage: "Tablet",
    isPartOfPillPack: "yes",
    beforeAfterMealFlag: null,
    strength: "3 mg",
    timeOfDay: "BEDTIME",
    description: "TAKE ONE TABLET BY MOUTH AT BEDTIME AS NEEDED",
    consumptionCadence: "AS NEEDED",
    uniqueIdentifier: getUid(),
    splId: "90cc8c86-2db8-4687-8649-8127834e2c14",
    adherenceTracking: {
      isTaken: null,
      takenTime: 1631743200,
      missedReasonCode: "01",
      missedNotes: null,
      reminderDateTime: null,
    },
  },
  {
    consumptionDate: "2019-12-31",
    drugName: "QUETIAPINE FUMARATE",
    drugCode: getUid(),
    drugImageUrl:
      "https://dev.app.homefreerx.com/static/drug-images/images/AVG02670.JPG",
    dosageQty: "1.0000",
    unitOfDosage: "Tablet",
    isPartOfPillPack: "yes",
    beforeAfterMealFlag: null,
    strength: "25 mg",
    timeOfDay: "BEDTIME",
    description: "TAKE ONE TABLET BY MOUTH AT BEDTIME",
    consumptionCadence: "DAILY",
    uniqueIdentifier: getUid(),
    splId: "90cc8c86-2db8-4687-8649-8127834e2c14",
    adherenceTracking: {
      isTaken: null,
      takenTime: 1631743200,
      missedReasonCode: "01",
      missedNotes: null,
      reminderDateTime: null,
    },
  },
  {
    consumptionDate: "2019-12-31",
    drugName: "CITALOPRAM HBR",
    drugCode: getUid(),
    drugImageUrl:
      "https://dev.app.homefreerx.com/static/drug-images/images/AVG02670.JPG",
    dosageQty: 1.0,
    unitOfDosage: "Tablet",
    isPartOfPillPack: "yes",
    beforeAfterMealFlag: null,
    strength: "20 mg",
    timeOfDay: "BEDTIME",
    description: "TAKE ONE TABLET BY MOUTH AT BEDTIME",
    consumptionCadence: "DAILY",
    uniqueIdentifier: getUid(),
    splId: "90cc8c86-2db8-4687-8649-8127834e2c14",
    adherenceTracking: {
      isTaken: null,
      takenTime: null,
      missedReasonCode: "01",
      missedNotes: null,
      reminderDateTime: null,
    },
  },
];

export const NOON_MEDS = [
  {
    consumptionDate: "2019-12-31",
    drugName: "VITAMIN E",
    drugCode: getUid(),
    drugImageUrl:
      "https://dev.app.homefreerx.com/static/drug-images/images/AVG02670.JPG",
    dosageQty: "1.0000",
    unitOfDosage: "Capsule",
    isPartOfPillPack: "yes",
    beforeAfterMealFlag: null,
    strength: "1,000 unit",
    timeOfDay: "NOON",
    description: "TAKE ONE CAPSULE BY MOUTH AT NOON",
    consumptionCadence: "DAILY",
    uniqueIdentifier: getUid(),
    splId: "90cc8c86-2db8-4687-8649-8127834e2c14",
    adherenceTracking: {
      isTaken: true,
      takenTime: 1631743200,
      missedReasonCode: "01",
      missedNotes: null,
      reminderDateTime: null,
    },
  },
];

export const getDailyMedicationSample = (date) => {
  console.log({ date });
  let medications = [
    ...MORNING_MEDS,
    ...TAKE_AS_PRESCRIBED_MEDS,
    ...BEDTIME_MEDS,
    ...NOON_MEDS,
    ...EARLY_MORNING_MEDS,
  ];
  if (date % 4 === 0) medications = [];
  const yyyy = `${date.toString().substring(0, 4)}`;
  const mm = `${date.toString().substring(4, 6)}`;
  const dd = `${date.toString().substring(6, 8)}`;

  // Todo: needs fixing
  const consumptionDate = `${yyyy}-${mm}-${dd}`;
  medications = medications.map((medication) => ({
    ...medication,
    consumptionDate,
  }));

  const dailyMedicationsApiResponse = {
    medications,
  };

  return dailyMedicationsApiResponse;
};
