import { Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import EmailIcon from "./assets/email.svg";
import FaxIcon from "./assets/fax.svg";
import GuideIcon from "./assets/guide.svg";
import PhoneIcon from "./assets/phone.svg";
import VideoIcon from "./assets/video.svg";

export const styles = makeStyles((theme) => ({
  supportContainer: {},

  informationTitle: {
    display: "flex",
    alignItems: "center",
    margin: theme.customMixins.pxToRem(16, 0),
    "& > img": {
      width: theme.customMixins.pxToRem(24),
      height: theme.customMixins.pxToRem(24),
    },
    "& > h2": {
      font: theme.typography.sectionTitle.medium.font,
      color: theme.palette.primary.A900,
      marginLeft: theme.customMixins.pxToRem(8),
    },
  },
  contactDetails: {
    margin: theme.customMixins.pxToRem(16, 0),
    "& > h4": {
      margin: theme.customMixins.pxToRem(8, 0),
      font: theme.typography.body.medium.font,
      color: theme.palette.textPrimary.emphasisLow,
      "& > a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
      },
    },
  },
  contactHours: {
    margin: theme.customMixins.pxToRem(16, 0),
    "& > h3": {
      font: theme.typography.body.medium.font,
      color: theme.palette.textPrimary.emphasisLow,
    },
    "& > h4": {
      margin: theme.customMixins.pxToRem(8, 0),
      font: theme.typography.body.regular.font,
      color: theme.palette.textPrimary.emphasisLow,
    },
  },
  pdfGuide: {
    "& > h4": {
      margin: theme.customMixins.pxToRem(8, 0),
      paddingBottom: theme.customMixins.pxToRem(16),
      font: theme.typography.body.regular.font,
      color: theme.palette.textPrimary.emphasisLow,
      "& > a": {
        color: theme.palette.primary.main,
        border: theme.customCss.border,
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.white.high,
        padding: theme.customMixins.pxToRem(8, 0),
        "& > span": {
          margin: theme.customMixins.pxToRem(8),
        },
      },
    },
  },
  videoGuide: {
    "& > h4": {
      margin: theme.customMixins.pxToRem(8, 0),
      paddingBottom: theme.customMixins.pxToRem(16),
      font: theme.typography.body.regular.font,
      color: theme.palette.textPrimary.emphasisLow,
      "& > a": {
        color: theme.palette.primary.main,
        border: theme.customCss.border,
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.white.high,
        padding: theme.customMixins.pxToRem(8, 0),
        "& > span": {
          margin: theme.customMixins.pxToRem(8),
        },
      },
    },
  },
  blankBox: {
    height: theme.customMixins.pxToRem(16 * 8),
  },
}));

function SupportDetails({
  callDetails,
  faxDetails,
  timingDetails,
  emailDetails,
  pdfGuide,
  videoGuide,
}) {
  const classes = styles();

  return (
    <div data-testid="support-container" className={classes.supportContainer}>
      {timingDetails && (
        <>
          <div data-testid="timing-details" className={classes.contactHours}>
            <Typography variant="h3" color="textPrimary">
              Hours
            </Typography>
            {timingDetails}
          </div>
          <Divider data-testid="timing-details-divider" />
        </>
      )}

      {callDetails && (
        <>
          <div data-testid="call-details" className={classes.informationTitle}>
            <img src={PhoneIcon} alt="PhoneIcon" />
            <Typography variant="h2" color="primary">
              Call
            </Typography>
          </div>

          <div className={classes.contactDetails}>{callDetails}</div>
          <Divider data-testid="call-details-divider" />
        </>
      )}

      {faxDetails && (
        <>
          <div data-testid="fax-details" className={classes.informationTitle}>
            <img src={FaxIcon} alt="FaxIcon" />
            <Typography variant="h2" color="primary">
              Fax
            </Typography>
          </div>

          <div className={classes.contactDetails}>{faxDetails}</div>
          <Divider data-testid="fax-details-divider" />
        </>
      )}

      {emailDetails && (
        <>
          <div data-testid="email-details" className={classes.informationTitle}>
            <img src={EmailIcon} alt="EmailIcon" />
            <Typography variant="h2" color="primary">
              Email
            </Typography>
          </div>

          <div className={classes.contactDetails}>{emailDetails}</div>
          <div data-testid="email-details-divider"></div>
          <Divider data-testid="fax-details-divider" />
        </>
      )}

      {pdfGuide && (
        <>
          <div data-testid="email-details" className={classes.informationTitle}>
            <img src={GuideIcon} alt="EmailIcon" />
            <Typography variant="h2" color="primary">
              Application Support Guide
            </Typography>
          </div>

          <div className={classes.pdfGuide}>{pdfGuide}</div>
          <div data-testid="pdfGuide-details-divider"></div>
          {videoGuide && <Divider data-testid="pdfGuide-details-divider" />}
        </>
      )}

      {videoGuide && (
        <>
          <div data-testid="email-details" className={classes.informationTitle}>
            <img src={VideoIcon} alt="EmailIcon" />
            <Typography variant="h2" color="primary">
              View Application Support Videos
            </Typography>
          </div>

          <div className={classes.videoGuide}>{videoGuide}</div>
          <div data-testid="videoGuide-details-divider"></div>
        </>
      )}

      <div className={classes.blankBox}></div>
    </div>
  );
}

SupportDetails.defaultProps = {
  callDetails: null,
  faxDetails: null,
  emailDetails: null,
  timingDetails: null,
  pdfGuide: null,
  videoGuide: null,
};

SupportDetails.propTypes = {
  /**
   * Support Phone Number
   */
  callDetails: PropTypes.node,

  /**
   * Support Fax Number
   */
  faxDetails: PropTypes.node,

  /**
   * Support Email
   */
  emailDetails: PropTypes.node,

  /**
   * Support Timing Details
   */
  timingDetails: PropTypes.node,

  /**
   * Support PDF Details
   */
  pdfGuide: PropTypes.node,

  /**
   * Support Video Details
   */
  videoGuide: PropTypes.node,
};

export default SupportDetails;
