import { DELIVERY_DETAILS } from "samples/deliveryDetails";

import { fetchCustomPayload, get } from "../communication";
import { deliveryDetailSchema } from "./schema/getDeliveryDetails.schema";

export const ROUTE_URL = "/med-mgmt/member/{current}/deliveries/{shipmentId}";
export const API_DEV_HASH = "devMode##getDeliveryDetails";

export const getDeliveryDetails = async (shipmentId, user = "{current}") => {
  if (localStorage.getItem(API_DEV_HASH) === "true") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(fetchCustomPayload(API_DEV_HASH, DELIVERY_DETAILS.data));
      }, 1000);
    });
  } else {
    const response = await get(
      ROUTE_URL.replace("{current}", user).replace("{shipmentId}", shipmentId),
      {},
      deliveryDetailSchema,
    );
    // Todo: add error handling
    return response.data.data;
  }
};
