import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  deliveryAddress: {
    font: theme.typography.description.regular.font,
    color: theme.palette.textPrimary.emphasisLow,
  },
}));

function DeliveryCardAddress({ fullName, deliveryAddress }) {
  const classes = useStyles();

  return (
    <>
      {
        <Typography
          data-testid="address"
          className={classes.deliveryAddress}
          color="textPrimary"
          variant="h5"
        >
          {fullName && fullName}
          {fullName && <br />}
          {deliveryAddress.addressLine1 && deliveryAddress.addressLine1}
          {deliveryAddress.addressLine1 && <br />}
          {deliveryAddress.addressLine2 && deliveryAddress.addressLine2}
          {deliveryAddress.addressLine2 && <br />}
          {deliveryAddress.city} {deliveryAddress.state}
          &nbsp;
          {deliveryAddress.zipcode}
        </Typography>
      }
    </>
  );
}

export default DeliveryCardAddress;

DeliveryCardAddress.defaultProps = {
  address: {
    addressLine1: "1234 Broadway",
    addressLine2: "Bldg C APT 3",
    city: "Westville",
    state: "NJ",
    zipcode: "8093",
  },
  fullName: "John Doe",
};

DeliveryCardAddress.propTypes = {
  address: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,

  fullName: PropTypes.string,
};
