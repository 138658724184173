import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "utility";

import AsNeeded from "./assets/asNeeded.svg";
import BedTime from "./assets/bedTime.svg";
import collapsedIcon from "./assets/collapsedIcon.svg";
import EarlyMorningIcon from "./assets/earlyMorning.svg";
import expandedIcon from "./assets/expandedIcon.svg";
import halfMoon from "./assets/halfMoon.svg";
import Noon from "./assets/noon.svg";
import RedCircle from "./assets/redCircle.svg";
import SolidGreenTick from "./assets/solidGreenTick.svg";
import Sun from "./assets/sun.svg";

const CADENCE_ICON_MAP = {
  "EARLY MORNING": EarlyMorningIcon,
  MORNING: Sun,
  NOON: Noon,
  "AS NEEDED": AsNeeded,
  EVENING: halfMoon,
  BEDTIME: BedTime,
};

const useStyles = makeStyles((theme) => ({
  cardTitleContainer: {
    display: "block",
    margin: theme.customMixins.pxToRem(20, 0),
  },
  cadenceTitleContainer: {
    padding: `${theme.customMixins.pxToRem(6, 12)}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cadenceTitleLabelContainer: {
    display: "flex",
    alignItems: "center",
  },
  titleIconContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.customMixins.pxToRem(16),
    width: theme.customMixins.pxToRem(22),
    height: theme.customMixins.pxToRem(22),
  },
  cadenceTitle: {
    font: theme.typography.body.bold.font,
    color: theme.palette.textPrimary.emphasisHigh,
    "&.large": {
      font: theme.typography.sectionSubtitle.bold.font,
    },
  },
  adherenceTitle: {
    font: theme.typography.sectionSubtitle.regular.font,
  },
  expansionToggleButton: {
    font: theme.typography.description.medium.font,
    color: theme.palette.primary.main,

    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& > img": {
      marginTop: theme.customMixins.pxToRem(2),
    },
  },
}));

function MedicalCardTitle(props) {
  const classes = useStyles();

  const {
    title,
    showIcons,
    enableAdherenceTracking,
    enableReminderTracking,
    toggleExpansion,
    cardAdherenceState,
    expanded,
  } = props;

  if (!title) return <></>;

  // Step 1: Icon Selection
  const isTrackingEnabled = enableAdherenceTracking || enableReminderTracking;

  let cadenceIcon = CADENCE_ICON_MAP[title.toUpperCase()];

  let adherenceIcon = null;
  let adherenceMessage = null;
  if (cardAdherenceState.isActionTaken) {
    if (cardAdherenceState.takenCount === cardAdherenceState.totalCount) {
      adherenceIcon = SolidGreenTick;
    } else {
      adherenceIcon = RedCircle;
      adherenceMessage = cardAdherenceState.stateMessage;
    }
  }

  return (
    <div
      onClick={() => toggleExpansion()}
      className={classes.cardTitleContainer}
    >
      {title && (
        <div
          data-testid="cadence-title-container"
          className={classes.cadenceTitleContainer}
        >
          <div className={classes.cadenceTitleLabelContainer}>
            {showIcons && cadenceIcon && (
              <img
                className={classes.titleIconContainer}
                src={cadenceIcon}
                alt="icon"
              />
            )}

            {enableAdherenceTracking && adherenceIcon && (
              <img
                className={classes.titleIconContainer}
                src={adherenceIcon}
                alt="icon"
              />
            )}

            <Typography
              className={`${classes.cadenceTitle} ${
                isTrackingEnabled ? `large` : null
              }`}
              color="primary"
              variant="h4"
            >
              {capitalizeFirstLetter(title?.toLowerCase())}
              &nbsp;
              {enableAdherenceTracking && adherenceMessage && (
                <span className={classes.adherenceTitle}>
                  {adherenceMessage}
                </span>
              )}
            </Typography>
          </div>

          {isTrackingEnabled && (
            <Typography
              data-testid="expansion-toggle-button"
              className={classes.expansionToggleButton}
              variant="h6"
            >
              Details
              <img
                src={expanded ? expandedIcon : collapsedIcon}
                alt="toggleIcon"
              ></img>
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}

MedicalCardTitle.defaultProps = {
  title: null,
  showIcons: true,
  expanded: true,
  enableAdherenceTracking: false,
  enableReminderTracking: false,
  toggleExpansion: () => {},
};

MedicalCardTitle.propTypes = {
  title: PropTypes.string,
  showIcons: PropTypes.bool,
  expanded: PropTypes.bool,
  enableAdherenceTracking: PropTypes.bool.isRequired,
  enableReminderTracking: PropTypes.bool.isRequired,
  toggleExpansion: PropTypes.func,
  cardAdherenceState: PropTypes.shape({
    skippedCount: PropTypes.number.isRequired,
    takenCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    isActionTaken: PropTypes.bool.isRequired,
    stateMessage: PropTypes.string.isRequired,
  }).isRequired,
};

export default MedicalCardTitle;
