import { TIME_OPTIONS } from "constants/timeOfDay";
import { getDailyMedicationSample } from "samples/dailyMedications";
import { formatToStandardDate, groupBy, validURL } from "utility";

import { fetchCustomPayload, get } from "../communication";
import { TransformationError } from "./../../helpers/errors/transformationError";
import { dailyMedicationSchema } from "./schema/getDailyMedication.schema";

// Constants
export const ROUTE_URL = "/med-mgmt/member/{current}/medication/daily";
export const API_DEV_HASH = "devMode##getDailyMedication";

/**
 * @description Cleans Medication Data
 * Step 1: Validation of {drugImageUrl}
 *    | Removes badly formatted URLs
 * Step 2: Validation of {timeOfDay}
 *    | Converts badly formatted values to TAKE AS PRESCRIBED
 *    | Dose a full string search to guess nearest possible {timeOfDay} value
 * Step 3: Validation of {adherenceTracking}
 *    | Adds dummy {adherenceTracking} object if value is not found.
 * Step 4: Validation of {consumptionCadence}
 *    | Modifies consumptionCadence to As Needed or Today
 * @param {array} medication
 * @returns
 */
const dailyMedicationDataCleaner = (medication) => {
  for (let index = 0; index < medication.length; index++) {
    // Step 1: Validation of {drugImageUrl}
    if (!validURL(medication[index].drugImageUrl))
      medication[index].drugImageUrl = null;

    // Step 2: Validation of {timeOfDay}
    if (!medication[index].timeOfDay)
      medication[index].timeOfDay = "TAKE AS PRESCRIBED";

    // Step 3: Validation of {adherenceTracking}
    if (!medication[index].adherenceTracking) {
      medication[index].adherenceTracking = {
        isTaken: null,
        takenTime: null,
        missedReasonCode: null,
        missedNotes: null,
      };
    }

    // Step 4: Validation of {consumptionCadence}
    if (["AS NEEDED"].indexOf(medication[index].consumptionCadence) < 0)
      medication[index].consumptionCadence = "TODAY";
  }

  medication = medication.sort(function (a, b) {
    a = a.drugName.toLowerCase();
    b = b.drugName.toLowerCase();
    if (a === b) return 0;
    if (a > b) return 1;
    return -1;
  });

  return medication;
};

/**
 * @description Resolves for default card expansion
 * @param {*} groupedTimeData
 * @returns
 */
const getDefaultExpansions = (groupedTimeData) => {
  const actionList = TIME_OPTIONS.map((key) => {
    if (!groupedTimeData[key]) return null;
    return groupedTimeData[key].every(
      (rowData) => rowData.adherenceTracking.isTaken !== null,
    );
  });

  let expansionList = [];
  let previous = null;
  for (let index = 0; index < actionList.length; index++) {
    // Rule 1: Always closed if action is taken
    if (actionList[index]) expansionList.push(false);
    // Rule 2: Card Remains open if previous is acted upon
    else expansionList.push(previous === null || previous === true);
    if (actionList[index] !== null) previous = actionList[index];
  }

  return expansionList;
};

const isInFuture = (dateString) => {
  const yyyy = Number(dateString.substring(0, 4));
  const mm = Number(dateString.substring(4, 6));
  const dd = Number(dateString.substring(6, 8));

  const today = formatToStandardDate(new Date());
  const yyyyToday = Number(today.substring(0, 4));
  const mmToday = Number(today.substring(4, 6));
  const ddToday = Number(today.substring(6, 8));
  return (
    yyyyToday < yyyy ||
    (yyyyToday === yyyy && mmToday < mm) ||
    (yyyyToday === yyyy && mmToday === mm && ddToday < dd)
  );
};

const responseTransform = (date, response) => {
  const allMedications = dailyMedicationDataCleaner(response.data.medications);

  const groupedCadenceData = groupBy(allMedications, ["consumptionCadence"]);
  if (!groupedCadenceData["AS NEEDED"]) groupedCadenceData["AS NEEDED"] = [];

  const groupedTimeData = groupBy(groupedCadenceData["TODAY"], ["timeOfDay"]);

  const cardDefaultExpansion = getDefaultExpansions(groupedTimeData).map((v) =>
    isInFuture(date) ? false : v,
  );

  return { groupedCadenceData, groupedTimeData, cardDefaultExpansion };
};

export const getDailyMedications = async (
  date = new Date(),
  user = "{current}",
) => {
  let response = null;

  if (localStorage.getItem(API_DEV_HASH) === "true") {
    response = await fetchCustomPayload(API_DEV_HASH, {
      data: getDailyMedicationSample(date),
    });
  } else {
    // Todo: Remove After demo
    if (date instanceof Date) date = formatToStandardDate(date);
    else date = date.toString();
    response = await get(
      ROUTE_URL.replace("{current}", user),
      {
        date: date,
      },
      API_DEV_HASH,
      dailyMedicationSchema,
    );
  }

  try {
    const transformed = responseTransform(date, response);
    return Promise.resolve(transformed);
  } catch (transformationError) {
    throw new TransformationError(transformationError);
  }
};
