import { BottomNavigation, makeStyles } from "@material-ui/core";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Snackbar from "@material-ui/core/Snackbar";
import { clinicianRoutes } from "helpers/routeConfig";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Route, Switch, useLocation } from "react-router-dom";

import ReferralForm from "./ReferralForm/ReferralForm";
import ReferralDetails from "./ShowReferrals/ReferralDetails/ReferralDetails";
import Referrals from "./ShowReferrals/Referrals";

const useStyles = makeStyles((theme) => ({
  referralRootContainer: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  navigationTabContainer: {
    width: "100%",
    marginTop: theme.customMixins.pxToRem(-1),
    boxShadow: theme.customCss.navShadow,
    zIndex: 1,
    "& .MuiBottomNavigationAction-root": {
      background: theme.palette.primary.A800,
    },
  },
  navigationTab: {
    color: `${theme.palette.white.high} !important`,
    flexBasis: 0,
    maxWidth: "100%",
    marginRight: theme.customMixins.pxToRem(-1),
    fontWeight: 500,
    borderBottom: "4px solid",
    borderBottomColor: theme.palette.primary.A800,
    "& .MuiBottomNavigationAction-label": {
      font: theme.typography.description.medium.font,
      "&.Mui-selected": {
        font: theme.typography.description.bold.font,
      },
    },
    "&.Mui-selected": {
      borderBottomColor: theme.palette.white.high,
    },
  },
  pageContainer: {
    flexGrow: "1",
    width: "100%",
    maxWidth: theme.pageDimensions.mobileMaxWidth,
    display: "flex",
    flexDirection: "column",
  },
  snackbar: theme.customComponents.snackbar.success,
}));

export const ReferralViewComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const pathname = window.location.pathname;
  const location = useLocation();

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleClose = () => {
    setOpenSnackBar(false);
  };

  useEffect(() => {
    if (location?.state?.detail === "referralCreated") {
      setOpenSnackBar(true);
      window.history.replaceState(null, "");
    }
  }, [location]);

  return (
    <div
      data-testid="referral-root-container"
      className={classes.referralRootContainer}
    >
      <BottomNavigation
        value={pathname}
        onChange={(event, newValue) => history.push(newValue)}
        className={classes.navigationTabContainer}
        showLabels
      >
        <BottomNavigationAction
          label="Referral list"
          className={classes.navigationTab}
          value={clinicianRoutes.referrals}
        />
        <BottomNavigationAction
          label="Start a new referral"
          className={classes.navigationTab}
          value={clinicianRoutes.referralForm}
          data-testid="referral-submission"
        />
      </BottomNavigation>
      <div className={classes.pageContainer}>
        <Snackbar
          data-testid="referral-creation-snackbar"
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSnackBar}
          className={classes.snackbar}
          autoHideDuration={5000}
          onClose={handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              Thank you! Your referral has been received.
            </span>
          }
        />
        <Switch>
          <Route component={Referrals} path={clinicianRoutes.referrals} exact />
          <Route
            component={ReferralDetails}
            path={clinicianRoutes.referralDetail}
            exact
          />
          <Route
            component={ReferralForm}
            path={clinicianRoutes.referralForm}
            exact
          />
        </Switch>
      </div>
    </div>
  );
};

export default ReferralViewComponent;
