import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { getAllDeliveries } from "helpers/api";
import CardErrorBoundary from "pages/common/CardErrorBoundary/CardErrorBoundary";
import DeliveryCard from "pages/common/DeliveryCard/DeliveryCard";
import { NoDelivery } from "pages/common/NoDataScreens";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  cardHolder: {
    marginBottom: theme.customMixins.pxToRem(24),
    boxShadow: theme.customCss.cardShadow,
  },
  title: {
    color: theme.palette.primary.main,
    font: theme.typography.sectionSubtitle.regular,
    margin: theme.customMixins.pxToRem(8, 0),
  },
  loadMoreButton: {
    width: "100%",
    background: theme.palette.white.high,
    color: theme.palette.primary.main,
    marginBottom: theme.customMixins.pxToRem(16),
    height: theme.customMixins.pxToRem(48),
    "&.MuiButton-root:hover": {
      background: theme.palette.white.high,
    },
  },
  loaderContainer: {
    display: "flex",
    padding: "2rem",
    justifyContent: "center",
  },
}));

function DeliveryView({ patientPhoneNumber, showDetails }) {
  const classes = useStyles();
  const history = useHistory();
  const { path } = useRouteMatch();

  let [deliveries, setDeliveries] = useState([]);
  let [pageNumber, setPageNumber] = useState(1);
  let [morePagesFlag, setMorePagesFlag] = useState(true);
  let [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getAllDeliveries(pageNumber, undefined, patientPhoneNumber)
      .then((response) => {
        setMorePagesFlag(response.nextPageNumber !== null);
        setDeliveries((previous) => [...previous, ...response.deliveries]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [pageNumber, patientPhoneNumber]);

  const getNewPageInfo = () => {
    setPageNumber((current) => current + 1);
  };

  const showDetailsHandler = ({ shipmentId }) => {
    history.push(`${path}/shipment/${shipmentId}`);
  };

  return (
    <>
      <div data-testid="delivery-view" className={classes.mobileViewHolder}>
        {deliveries.map((delivery, index) => {
          return (
            <div
              data-testid="card-holder"
              key={index}
              className={classes.cardHolder}
            >
              <CardErrorBoundary>
                <DeliveryCard
                  key={index}
                  index={index % 4}
                  data={delivery}
                  showDetailsButton={showDetails}
                  showDetailsHandler={() => showDetailsHandler(delivery)}
                />
              </CardErrorBoundary>
            </div>
          );
        })}
        {!isLoading && deliveries.length === 0 && (
          <NoDelivery message="You have no past deliveries"></NoDelivery>
        )}

        {morePagesFlag && deliveries.length !== 0 && (
          <Button
            onClick={getNewPageInfo}
            className={classes.loadMoreButton}
            disabled={isLoading}
            variant="outlined"
          >
            See more
          </Button>
        )}

        {isLoading && (
          <div className={classes.loaderContainer}>
            <CircularProgress color="inherit" />
          </div>
        )}
      </div>
    </>
  );
}

DeliveryView.propTypes = {
  patientPhoneNumber: PropTypes.string,
  showDetails: PropTypes.bool,
};

DeliveryView.defaultProps = {
  patientPhoneNumber: "{current}",
  showDetails: true,
};

export default DeliveryView;
