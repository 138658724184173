import { makeStyles, Typography } from "@material-ui/core";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Store } from "context/Context";
import { clinicianRoutes } from "helpers/routeConfig";
import PatientProfile from "pages/clinician/ClinicianHome/Patients/PatientDetails/PatientProfile/PatientProfile";
import AllMedicationView from "pages/patient/components/AllMedicationView/AllMedicationView";
import DailyMedicationView from "pages/patient/components/DailyMedicationView/DailyMedicationView";
import DeliveryView from "pages/patient/components/DeliveryView/DeliveryView";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

import DownArrowIcon from "./assets/downArrow.svg";

const useStyles = makeStyles((theme) => ({
  patientRootContainer: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: theme.palette.gradient.linear,
    marginTop: theme.customMixins.pxToRem(-1),
  },
  popoverPaper: {
    left: "0% !important",
  },
  dropdown: {
    width: "100%",
    maxWidth: "100%",
    "& .MuiMenu-list": {
      backgroundColor: theme.palette.primary.A800,
      color: theme.palette.white.high,
      font: theme.typography.sectionSubtitle.medium.font,
    },
  },
  patientDetailContainer: {
    overflowY: "auto",
    flexGrow: 1,
    padding: theme.customMixins.pxToRem(24),
    height: theme.customMixins.pxToRem(100),
    width: "100%",
    maxWidth: theme.customMixins.pxToRem(480),
  },
  allMedicationContainer: {},
  dailyMedicationContainer: {},
  deliveryContainer: {},
  displayProfile: {},

  menuContainer: {
    width: "100%",
    background: theme.palette.primary.A800,
    padding: theme.customMixins.pxToRem(16),
    "& > h2": {
      font: theme.typography.sectionSubtitle.medium.font,
      color: theme.palette.white.high,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
  },
}));

const MENU_OPTIONS = {
  dailyMedication: "Daily Medication",
  allMedications: "All Medications",
  deliveries: "Deliveries",
  profile: "Profile",
};

export const PatientDetails = () => {
  const classes = useStyles();
  const history = useHistory();

  const { dispatch } = useContext(Store);

  const patientDetails = JSON.parse(localStorage.getItem("patientDetails"));
  const patientPhoneNumber = patientDetails?.phoneNumber;
  const patientFirstName = patientDetails?.firstName;
  const patientLastName = patientDetails?.lastName;

  if (!patientPhoneNumber) history.replace(clinicianRoutes.patientView);

  useEffect(() => {
    dispatch({
      type: "APP_BAR_TITLE",
      title: `${patientFirstName} ${patientLastName}`,
    });
    dispatch({ type: "SHOW_BACK_BUTTON", showBackButton: true });
  }, [dispatch, patientFirstName, patientLastName]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    localStorage.getItem("patientDetailsMenu") || MENU_OPTIONS.dailyMedication,
  );
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (selectedItem) => {
    setAnchorEl(null);
    localStorage.setItem(
      "patientDetailsMenu",
      selectedItem || selectedMenuItem,
    );
    setSelectedMenuItem(selectedItem || selectedMenuItem);
  };

  return (
    <div className={classes.patientRootContainer} data-testid="patient-details">
      <div className={classes.menuContainer}>
        <Typography
          id="demo-positioned-button"
          aria-controls="demo-positioned-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="h2"
          onClick={handleClick}
          className={classes.menuText}
        >
          {selectedMenuItem}
          <img src={DownArrowIcon} alt="DownArrowIcon" />
        </Typography>

        <Menu
          PopoverClasses={{ paper: classes.popoverPaper }}
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose()}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          // classes={{ paper: classes.dropdown }}
          PaperProps={{
            style: {
              backgroundColor: "#2D5FA8",
              color: "#FFFFFF",
              font: "500 18px Inter",
              width: "100%",
              maxWidth: "100%",
            },
          }}
        >
          {Object.values(MENU_OPTIONS).map((option) => {
            return (
              <MenuItem onClick={() => handleClose(option)}>{option}</MenuItem>
            );
          })}
        </Menu>
      </div>

      <div className={classes.patientDetailContainer}>
        {selectedMenuItem === MENU_OPTIONS.allMedications && (
          <div
            data-testid="all-medication-container"
            className={classes.allMedicationContainer}
          >
            <AllMedicationView
              patientPhoneNumber={patientPhoneNumber}
              noDataMessage="Patient medication information is not available"
              readOnly={true}
            />
          </div>
        )}
        {selectedMenuItem === MENU_OPTIONS.dailyMedication && (
          <div
            data-testid="daily-medication-container"
            className={classes.dailyMedicationContainer}
          >
            <DailyMedicationView
              patientPhoneNumber={patientPhoneNumber}
              readOnly={true}
              showMissedReason={true}
              showGreetingMessage={false}
              noDataMessage="Patient medication information is not available on this date"
              defaultDate={new Date()}
            />
          </div>
        )}
        {selectedMenuItem === MENU_OPTIONS.deliveries && (
          <div
            data-testid="delivery-container"
            className={classes.deliveryContainer}
          >
            <DeliveryView
              patientPhoneNumber={patientPhoneNumber}
              showDetails={true}
            />
          </div>
        )}
        {selectedMenuItem === MENU_OPTIONS.profile && (
          <div
            data-testid="delivery-container"
            className={classes.profileDisplayContainer}
          >
            <PatientProfile
              patientPhoneNumber={patientPhoneNumber}
              patientFirstName={patientFirstName}
              patientLastName={patientLastName}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientDetails;
