import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import DeliveryDetailsCardRow from "./DeliveryDetailsCardRow";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: theme.palette.white.high,
  },
}));

const DeliveryDetailsCard = ({ cardData, reactUniqueId }) => {
  const classes = useStyles();
  console.log({ reactUniqueId });
  return (
    <div key={reactUniqueId} className={classes.mainContainer}>
      {cardData.map((dataItem, index) => {
        return (
          <DeliveryDetailsCardRow
            key={`${reactUniqueId}-c-${index}`}
            drugName={dataItem.drugName}
            dosageQty={dataItem.dosageQty}
            prescriberName={dataItem.prescriberName}
            reactUniqueId={`${reactUniqueId}-r-${index}`}
          />
        );
      })}
    </div>
  );
};

export default DeliveryDetailsCard;

DeliveryDetailsCard.propTypes = {
  cardData: PropTypes.arrayOf(PropTypes.shape(DeliveryDetailsCardRow.propTypes))
    .isRequired,

  reactUniqueId: PropTypes.string.isRequired,
};

DeliveryDetailsCard.defaultProps = {
  cardData: [
    DeliveryDetailsCardRow.defaultProps,
    {
      drugName: "New Drug",
      dosageQty: "1.1",
      prescriberName: "Long prescriberName",
    },
  ],
  reactUniqueId: `c-${+new Date()}`,
};
