import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  search: {
    display: "flex",
    borderRadius: theme.customMixins.pxToRem(50),
    position: "relative",
    margin: theme.customMixins.pxToRem(8, 0),
  },
  input: {
    borderRadius: theme.customMixins.pxToRem(50),
    border: theme.customMixins.pxToRem(0),
    font: theme.typography.legal.regular,
    paddingLeft: theme.customMixins.pxToRem(3 * 16),
    height: theme.customMixins.pxToRem(2.5 * 16),
    width: "100%",
    outline: "none",
    opacity: "0.74",
  },
  searchIcon: {
    position: "absolute",
    paddingLeft: theme.customMixins.pxToRem(16),
    padding: theme.customMixins.pxToRem(8),
    color: theme.palette.primary.main,
  },
}));

function SearchBar({ onChange, placeholder }) {
  const classes = useStyles();

  let timer;
  const onChangeHandler = (changeEvent) => {
    const input = changeEvent.target.value;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => onChange(input), 150);
  };

  return (
    <div data-testid="search-bar" className={classes.search}>
      <input
        type="text"
        className={classes.input}
        onChange={onChangeHandler}
        placeholder={placeholder}
      />
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
    </div>
  );
}

SearchBar.defaultProps = {
  onChange: (_) => {},
  placeholder: "",
};

SearchBar.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default SearchBar;
