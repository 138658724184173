import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { useGlobalNoDataStyles } from "./../global.styles";
import NoDataSvg from "./no-data.svg";

const useStyles = makeStyles((theme) => ({
  text: {
    font: theme.typography.body.medium.font,
    color: theme.palette.primary.main,
    marginTop: theme.customMixins.pxToRem(24),
  },
}));

function NoPatients({ hidden, message }) {
  const globalClasses = useGlobalNoDataStyles();
  const classes = useStyles();

  if (hidden) return <></>;

  return (
    <div
      data-testid="no-data-root-container"
      className={globalClasses.noDataRootContainer}
    >
      <img
        src={NoDataSvg}
        className={globalClasses.noDataIcon}
        alt="No data available"
      />
      <Typography className={classes.text}>{message}</Typography>
    </div>
  );
}

export default NoPatients;

NoPatients.defaultProps = {
  message: "No patient data available. Please check back later.",
  hidden: false,
};

NoPatients.propTypes = {
  /**
   * Message text to be shown on UI
   */
  message: PropTypes.string.isRequired,

  /**
   * Set UI visibility
   */
  hidden: PropTypes.bool,
};
