import { post } from "../communication";

export const ROUTE_URL =
  "/med-mgmt/clinician/member/{patientPhoneNumber}/profile";
export const API_DEV_HASH = "devMode##setPatientProfile";

export const setHighRiskStatus = (patientPhoneNumber, isHighRisk) => {
  let apiPromise;

  if (localStorage.getItem(API_DEV_HASH) === "true") {
    apiPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: {} });
      }, 1000);
    });
  } else {
    const payload = [
      {
        patientProfilePropertyType: "HIGH_RISK",
        patientProfilePropertyValue: isHighRisk,
      },
    ];
    const routeUrl = ROUTE_URL.replace(
      "{patientPhoneNumber}",
      patientPhoneNumber,
    );

    apiPromise = post(routeUrl, payload);
  }
  return apiPromise;
};

export const setMyPatientStatus = (patientPhoneNumber, isMyPatient) => {
  let apiPromise;

  if (localStorage.getItem(API_DEV_HASH) === "true") {
    apiPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: {} });
      }, 1000);
    });
  } else {
    const payload = [
      {
        patientProfilePropertyType: "MY_PATIENT",
        patientProfilePropertyValue: isMyPatient,
      },
    ];
    const routeUrl = ROUTE_URL.replace(
      "{patientPhoneNumber}",
      patientPhoneNumber,
    );
    apiPromise = post(routeUrl, payload);
  }

  return apiPromise;
};
