const PATIENT_BASE_PATH = "/patient-home";
const CLINICIAN_BASE_PATH = "/clinician-home";
const UNAUTHENTICATED_PATH = "/no-auth";

export const patientRoutes = {
  medication: `${PATIENT_BASE_PATH}/medication`,
  delivery: `${PATIENT_BASE_PATH}/delivery`,
  careTeam: `${PATIENT_BASE_PATH}/care-team`,
  viewMedication: `${PATIENT_BASE_PATH}/medication/drug`,
  home: PATIENT_BASE_PATH,
  skippedMedication: `${PATIENT_BASE_PATH}/medication/skipped`,
  applicationSettings: `${PATIENT_BASE_PATH}/application-settings`,
  deliveryDetails: `${PATIENT_BASE_PATH}/delivery/:shipmentId`,
};

export const clinicianRoutes = {
  patientView: `${CLINICIAN_BASE_PATH}/patients`,
  patientDetail: `${CLINICIAN_BASE_PATH}/patient`,
  referrals: `${CLINICIAN_BASE_PATH}/referrals`,
  referralForm: `${CLINICIAN_BASE_PATH}/referrals/submit`,
  referralDetail: `${CLINICIAN_BASE_PATH}/referrals/detail`,
  support: `${CLINICIAN_BASE_PATH}/support`,
};

const APPLICATION_SETTINGS_BASE = patientRoutes.applicationSettings;

export const ApplicationSettingRoutes = {
  home: `${APPLICATION_SETTINGS_BASE}/`,
  address: `${APPLICATION_SETTINGS_BASE}/address`,

  support: `${APPLICATION_SETTINGS_BASE}/support`,
  billing: `${APPLICATION_SETTINGS_BASE}/support/billing`,
  customerService: `${APPLICATION_SETTINGS_BASE}/support/customer-service`,

  preferences: `${APPLICATION_SETTINGS_BASE}/preferences`,
  medicationReminders: `${APPLICATION_SETTINGS_BASE}/preferences/medication-reminders`,
  shippingNotifications: `${APPLICATION_SETTINGS_BASE}/preferences/shipping-notifications`,
  trackMyMedication: `${APPLICATION_SETTINGS_BASE}/preferences/track-my-medication`,

  careGiver: `${APPLICATION_SETTINGS_BASE}/care-giver`,
  userProfileSettings: `${APPLICATION_SETTINGS_BASE}/user-profile-settings`,
};

export const unauthenticatedRoutes = {
  developer: `${UNAUTHENTICATED_PATH}/developer`,
  termsOfService: `${UNAUTHENTICATED_PATH}/terms-of-service`,
  privacyPolicy: `${UNAUTHENTICATED_PATH}/privacy-policy`,
};
