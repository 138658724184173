// Todo: Move to static class

export const isDateInWithInXDay = (date, days) => {
  const todayObj = new Date();
  todayObj.setHours(0, 0, 0);
  date.setHours(0, 0, 0);

  const diffTime = date - todayObj;
  const diffDays = diffTime / (1000 * 60 * 60 * 24);

  if (days > 0) {
    return diffDays >= -0.0001 && diffDays <= days * 0.99999;
  } else {
    return diffDays >= (days - 1) * 1.001 && diffDays <= days * 1.001;
  }
};

export const checkPastFuture = (initial, final) => {
  const diffYear = final.getFullYear() - initial.getFullYear();
  const diffMonth = final.getMonth() - initial.getMonth();
  const diffDate = final.getDate() - initial.getDate();
  if (diffYear > 0) return 1;
  else if (diffYear < 0) return -1;
  else {
    if (diffMonth > 0) return 1;
    else if (diffMonth < 0) return -1;
    else {
      if (diffDate > 0) return 1;
      else if (diffDate < 0) return -1;
      else return 0;
    }
  }
};

export function formatToStandardDate(date, separator = "") {
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${year}${separator}${month}${separator}${day}`;
}

export function getEpochTime(date = new Date()) {
  if (!(date instanceof Date)) {
    throw new Error("[date] should be of type {Date}.");
  }
  return parseInt(+date / 1000);
}

export function convertDateTo12HrFormat(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const amPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  hours = hours < 10 ? "0" + hours : hours;
  const strTime = hours + ":" + minutes + " " + amPm;
  return strTime;
}

export function checkIfFutureDate(date) {
  const today = new Date();
  today.setHours(0, 0, 0);
  date.setHours(0, 0, 0);

  return getEpochTime(date) - getEpochTime(today) > 0;
}

export function checkIfPastDate(date) {
  const today = new Date();
  today.setHours(0, 0, 0);
  date.setHours(0, 0, 0);

  return getEpochTime(today) - getEpochTime(date) > 0;
}

export function convertEpochToLocal(utcDate) {
  const dateObject = new Date(utcDate * 1000);
  return dateObject;
}
