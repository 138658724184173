import { makeStyles } from "@material-ui/core/styles";
import { Store } from "context/Context";
import DeliveryDetailsView from "pages/patient/components/DeliveryDetailsView/DeliveryDetailsView";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  deliveryRouteContainer: {
    overflowY: "auto",
    background: theme.palette.gradient.linear,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    "& > *": {
      width: "100%",
      maxWidth: theme.pageDimensions.mobileMaxWidth,
      padding: theme.customMixins.pxToRem(24),
    },
  },
}));

function DeliveryDetails() {
  const classes = useStyles();
  const { state, dispatch } = useContext(Store);
  const { shipmentId } = useParams();

  useEffect(() => {
    dispatch({ type: "APP_BAR_TITLE", title: "Delivery Details" });
    dispatch({ type: "SHOW_BACK_BUTTON", showBackButton: true });
  }, [state.title, dispatch]);

  return (
    <div
      data-testid="delivery-container"
      className={classes.deliveryRouteContainer}
    >
      <DeliveryDetailsView shipmentId={shipmentId} />
    </div>
  );
}

export default DeliveryDetails;
