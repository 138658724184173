import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import ReferralDetailListItem from "./ReferralDetailListItem";
import ReferralTimelineTitle from "./ReferralTimelineTitle";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    background: theme.palette.white.high,
    padding: theme.customMixins.pxToRem(32),
  },
  sectionTitleContainer: {
    display: "flex",
    marginTop: theme.customMixins.pxToRem(24),
  },
  sectionDetails: {
    marginBottom: theme.customMixins.pxToRem(-5),

    "& > :last-child": {
      paddingBottom: theme.customMixins.pxToRem(24),
    },
  },
  disabledSectionDetails: {
    borderLeftWidth: theme.customMixins.pxToRem(2),
    borderLeftColor: theme.palette.textPrimary.emphasisLowest,
    borderLeftStyle: "dashed",
    marginBottom: theme.customMixins.pxToRem(-5),
    "& > :last-child": {
      paddingBottom: theme.customMixins.pxToRem(24),
    },
  },
  emptyItem: {
    marginBottom: theme.customMixins.pxToRem(-5),
  },
}));

const VIEW_ORDERING = [
  { label: "Reach out", key: "reachOut" },
  { label: "Consult", key: "consult" },
  { label: "Pharmacy services", key: "pharmacyServices" },
];

function ReferralTimeline({ referralData }) {
  const classes = useStyles();

  return (
    <div data-testid="referral-detail-root" className={classes.listContainer}>
      {VIEW_ORDERING.map((order) => {
        const { key, label } = order;
        const isEmpty = referralData[key].length === 0;

        const title = (
          <ReferralTimelineTitle
            key={key + "title"}
            label={label}
            isEmpty={isEmpty}
          />
        );

        if (referralData[order.key].length === 0) {
          return (
            <div key={key + "title"}>
              {title}
              {key !== "pharmacyServices" && (
                <div className={classes.disabledSectionDetails}>
                  <div className={classes.emptyItem} />
                </div>
              )}
            </div>
          );
        }

        const listItems = referralData[key].map((referralDetailItem, i) => {
          return (
            <ReferralDetailListItem
              key={referralDetailItem.eventName + ": " + i}
              referralDetailItem={referralDetailItem}
              isLastItem={
                key === "pharmacyServices" && i === referralData[key].length - 1
              }
            />
          );
        });

        return (
          <div key={key + "sectionDetails"}>
            {title}
            <div className={classes.sectionDetails}>{listItems}</div>
          </div>
        );
      })}
    </div>
  );
}

ReferralTimeline.defaultProps = {
  referralData: {
    reachOut: [],
    consult: [],
    pharmacyServices: [],
  },
};

ReferralTimeline.propTypes = {
  referralData: PropTypes.shape({
    reachOut: PropTypes.arrayOf(PropTypes.object),
    consult: PropTypes.arrayOf(PropTypes.object),
    pharmacyServices: PropTypes.arrayOf(PropTypes.object),
  }),
};
export default ReferralTimeline;
