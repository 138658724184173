import { generateCaseInsensitiveName } from "utility/stringModifications";

import CallBackRequestIcon from "../assets/callBackRequest.svg";
import ConsultancyScheduledIcon from "../assets/consultancyScheduled.svg";
import ConsultCancelledIcon from "../assets/consultCancelled.svg";
import DeclinedIcon from "../assets/declined.svg";
import documentationPendingIcon from "../assets/documentationPending.svg";
import LeftVoiceMailIcon from "../assets/leftVoiceMail.svg";
import NotWorkingNumberIcon from "../assets/notWorkingNumber.svg";
import PendingInitialCallIcon from "../assets/pendingInitialCall.svg";
import ReceivedByPharmacyIcon from "../assets/receivedByPharmacy.svg";
import ShipmentIcon from "../assets/shipment.svg";
import VirtualVisitIcon from "../assets/virtualVisit.svg";

const EVENT_STATE_ICONS = {
  pending_initial_call: PendingInitialCallIcon,
  non_working_number: NotWorkingNumberIcon,

  wrong_number: NotWorkingNumberIcon,
  unable_to_reach: NotWorkingNumberIcon,
  no_answer: NotWorkingNumberIcon,

  left_voicemail: LeftVoiceMailIcon,
  call_back_request: CallBackRequestIcon,

  consult_scheduled: ConsultancyScheduledIcon,
  consult_rescheduled: ConsultancyScheduledIcon,

  consult_cancelled: ConsultCancelledIcon,
  consult_no_show: ConsultCancelledIcon,

  documentation_pending: documentationPendingIcon,

  virtual_visit: VirtualVisitIcon,
  tele_health: VirtualVisitIcon,
  telehealth: VirtualVisitIcon,

  consult_declined: DeclinedIcon,
  service_declined: DeclinedIcon,
  client_cancelled: DeclinedIcon,

  submitted_by_pharmacy: ReceivedByPharmacyIcon,
  submitted_to_pharmacy: ReceivedByPharmacyIcon,
  received_by_pharmacy: ReceivedByPharmacyIcon,

  first_shipment_created: ShipmentIcon,

  default: PendingInitialCallIcon,
};

export const getReferralEventIcon = (eventName) => {
  if (typeof eventName !== "string") eventName = "default";
  const eventState = generateCaseInsensitiveName(eventName);
  const icon = EVENT_STATE_ICONS[eventState];
  return icon ? icon : EVENT_STATE_ICONS.default;
};
