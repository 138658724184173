import React, { createContext, useEffect, useReducer } from "react";

import { primaryReducer } from "./PrimaryReducer";

const defaultState = {
  loading: true,
  token: null,
  showBackButton: false,
  dailyMedicationDate: new Date(),
  userType: null,
};

export const Store = createContext({});

const Context = ({ children }) => {
  const [state, dispatch] = useReducer(primaryReducer, defaultState);

  useEffect(() => {
    // add API calls here for data that needs to be grabbed on app startup
  }, []);

  return (
    <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
  );
};

export default Context;
