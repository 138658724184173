import { TIME_OPTIONS } from "constants/timeOfDay";

export const REMINDER_TIMING = [
  {
    timeOfDay: TIME_OPTIONS[0],
    reminderTime: 1631670400,
  },
  {
    timeOfDay: TIME_OPTIONS[1],
    reminderTime: 1631682800,
  },
  {
    timeOfDay: TIME_OPTIONS[2],
    reminderTime: 1631697200,
  },
  {
    timeOfDay: TIME_OPTIONS[3],
    reminderTime: 1631718800,
  },
  {
    timeOfDay: TIME_OPTIONS[4],
    reminderTime: 1631543200,
  },
];
