import * as Joi from "joi";

const referralItems = Joi.object({
  referralFirstName: Joi.string().allow(null, ""),
  referralLastName: Joi.string().allow(null, ""),
  referralStatus: Joi.string().allow(null, ""), //.valid("Consult Cancelled", "Received by Pharmacy","Left Voicemail","Service Declined","Pending Initial Call"),
  latestUpdateAt: Joi.number().allow(null),
  referralId: Joi.string().allow(null, ""),
}).unknown();

export const referralsSchema = Joi.object({
  referrals: Joi.array().items(referralItems).required(),
}).unknown();
