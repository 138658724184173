import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import { CONFIGURATION } from "constants/configuration";
import PropTypes from "prop-types";

import CloseIcon from "./assets/close.svg";

const useStyles = makeStyles((theme) => ({
  dialogBox: {
    "& .MuiPaper-root": {
      padding: theme.customMixins.pxToRem(16),
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: theme.customMixins.pxToRem(380),
    },
  },
  dialogCloseTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.customMixins.pxToRem(16),
    "& > h3": {
      font: theme.typography.sectionSubtitle.bold.font,
      color: theme.palette.textPrimary.emphasisHigh,
    },
    "& > img": {
      cursor: "pointer",
      width: theme.customMixins.pxToRem(24),
      height: theme.customMixins.pxToRem(24),
      marginLeft: theme.customMixins.pxToRem(16),
    },
  },
  dialogCloseDescriptionContainer: {
    "& > h4": {
      font: theme.typography.body.regular.font,
      color: theme.palette.textPrimary.emphasisLow,
    },
  },
  dialogActionButton: {
    marginTop: theme.customMixins.pxToRem(16),
    padding: theme.customMixins.pxToRem(6, 20),
    width: "100%",
  },
  dialogCancelButton: {
    marginTop: theme.customMixins.pxToRem(16),
    padding: theme.customMixins.pxToRem(6, 20),
    width: "100%",
    color: theme.palette.error.main,
  },
  buttons: {
    flexDirection: "column",
  },
  button: {
    margin: theme.customMixins.pxToRem(8),
    color: theme.palette.primary.A700,
    boxShadow: "none",
    width: "100%",
  },
}));

function ContactUsDialog(props) {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      className={classes.dialogBox}
      keepMounted
      onClose={props.onClose}
      data-testid="contact-us-dialog"
    >
      <div className={classes.dialogCloseTitleContainer}>
        <Typography
          className={classes.dialogCloseTitle}
          data-testid="test-title"
          variant="h3"
        >
          Contact us
        </Typography>
        <img
          onClick={props.onClose}
          data-testid="close-button"
          src={CloseIcon}
          alt="CloseIcon"
        />
      </div>
      <div className={classes.dialogCloseDescriptionContainer}>
        <Typography className={classes.dialogCloseTitle} variant="h4">
          If you have questions or need to update information for a referral,
          please contact us
        </Typography>
      </div>
      <DialogActions className={classes.buttons}>
        <Button
          onClick={props.onContactPhone}
          variant="outlined"
          className={classes.button}
          data-testid="contact-phone-button"
          color="primary"
        >
          Call us at {CONFIGURATION.pharmacyContact.display}
        </Button>
        <Button
          onClick={props.onContactEmail}
          variant="outlined"
          className={classes.button}
          data-testid="contact-email-button"
          color="primary"
        >
          support@homefreerx.com
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ContactUsDialog.propTypes = {
  /**
   * Set Dialog to be open or close
   */
  open: PropTypes.bool,

  /**
   * Function call on close action click
   */
  onClose: PropTypes.func,

  /**
   * Function call on phone action click
   */
  onContactPhone: PropTypes.func,

  /**
   * Function call on email action click
   */
  onContactEmail: PropTypes.func,
};

ContactUsDialog.defaultProps = {
  onClose: console.log,
  onContactPhone: console.log,
  onContactEmail: console.log,
  open: false,
};

export default ContactUsDialog;
