import { SAMPLE_REFERRALS } from "samples/referrals";

import { fetchCustomPayload, get } from "../communication";
import { referralsSchema } from "./schema/getReferralList.schema";

export const ROUTE_URL = "/user-mgmt/clinician/referrals";
export const API_DEV_HASH = "devMode##getReferralList";

export const getReferralList = async () => {
  if (localStorage.getItem(API_DEV_HASH) === "true") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(fetchCustomPayload(API_DEV_HASH, SAMPLE_REFERRALS.referrals));
      }, 1000);
    });
  } else {
    const response = await get(ROUTE_URL, null, API_DEV_HASH, referralsSchema);
    return Promise.resolve(response.data.referrals);
  }
};
