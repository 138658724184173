import { convertDateTo12HrFormat, convertEpochToLocal } from "utility";

/**
 *
 * @param {rows} param0
 * @returns
 */
export const getCardAdherenceState = ({ rows }) => {
  const adherenceStats = {
    skippedCount: rows.filter(
      (row) => row.adherenceTracking && row.adherenceTracking.isTaken === false,
    ).length,
    takenCount: rows.filter(
      (row) => row.adherenceTracking && row.adherenceTracking.isTaken === true,
    ).length,
    totalCount: rows.length,
    isActionTaken: rows.every(
      (row) => row.adherenceTracking && row.adherenceTracking.isTaken !== null,
    ),
  };

  const stateMessage = `(${adherenceStats.takenCount}/${adherenceStats.totalCount})`;

  const takenTime = rows.find((row) => row.adherenceTracking?.takenTime)
    ?.adherenceTracking.takenTime;

  return {
    ...adherenceStats,
    stateMessage,
    takenTime:
      takenTime && convertDateTo12HrFormat(convertEpochToLocal(takenTime)),
    epochTime: takenTime,
  };
};
