import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.customMixins.pxToRem(0, 8),
  },
  control: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  dateDisplay: {
    padding: theme.customMixins.pxToRem(16),
    font: theme.typography.body.medium.font,
    color: theme.palette.primary.main,
  },
}));

export const getCustomFormattedDate = (selectedDate) => {
  const dateOptions = { month: "numeric", day: "numeric", year: "numeric" };
  const dateLocales = "en-US";

  const todayDateString = new Date().toLocaleDateString(
    dateLocales,
    dateOptions,
  );
  const selectedDateString = selectedDate.toLocaleDateString(
    dateLocales,
    dateOptions,
  );

  const date = selectedDate.getDate();
  const month = selectedDate.toLocaleDateString(dateLocales, { month: "long" });

  const getOrdinalNum = (n) => {
    return (
      n +
      (n > 0
        ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10]
        : "")
    );
  };

  return todayDateString === selectedDateString
    ? `Today, ${month} ${getOrdinalNum(date)}`
    : `${month} ${getOrdinalNum(date)}`;
};

function DateToggle({ defaultDate, onDateChange }) {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState(defaultDate);

  const onDateChangeHandler = (incrementDays) => {
    setSelectedDate((previousSelectedDate) => {
      previousSelectedDate.setDate(
        previousSelectedDate.getDate() + incrementDays,
      );
      // previousSelectedDate.setHours(0,0,0)
      const newDate = new Date(previousSelectedDate);
      onDateChange(newDate);
      return newDate;
    });
  };

  return (
    <Card>
      <CardContent className={classes.contentContainer}>
        <div className={classes.cardContainer}>
          <ChevronLeft
            name="decrease"
            className={classes.control}
            onClick={() => {
              onDateChangeHandler(-1);
            }}
            data-testid="test-chevron-left"
          />
          <div className={classes.dateDisplay}>
            <span data-testid="test-selected-date">
              {getCustomFormattedDate(selectedDate)}
            </span>
          </div>
          <ChevronRight
            name="increase"
            className={classes.control}
            onClick={() => {
              onDateChangeHandler(+1);
            }}
            data-testid="test-chevron-right"
          />
        </div>
      </CardContent>
    </Card>
  );
}

DateToggle.propTypes = {
  onDateChange: PropTypes.func,
  defaultDate: PropTypes.object,
};

DateToggle.defaultProps = {
  onDateChange: () => {},
  defaultDate: new Date(),
};

export default DateToggle;
