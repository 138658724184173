import { makeStyles } from "@material-ui/core/styles";

export const useGlobalNoDataStyles = makeStyles((theme) => ({
  noDataRootContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: theme.customMixins.pxToRem(16 * 3),
  },
  noDataIcon: {
    maxWidth: "90%",
    maxHeight: "90%",
  },
}));
