import { Button, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { API_DEV_HASHES } from "helpers/api";
import { switchUserRole } from "helpers/api/switchUserRole";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    overflow: "auto",
    maxHeight: "100vh",
    padding: theme.customMixins.pxToRem(16),
  },
  actionTitle: {
    color: theme.palette.primary.main,
    font: theme.typography.sectionSubtitle.medium.font,
  },
  tableContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.customMixins.pxToRem(16),
  },
  roleSwitchContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: theme.customMixins.pxToRem(16),
  },
  settingsTitle: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    padding: theme.customMixins.pxToRem(16),
    "& > h2": {
      color: theme.palette.primary.main,
      font: theme.typography.sectionSubtitle.medium.font,
    },
  },
  table: {
    border: "1px solid black",
    boxShadow: "1px 1px 1px rgb(0 0 0 / 25%)",
    maxWidth: theme.customMixins.pxToRem(16 * 20),
  },
  payload: {
    width: "100%",
    minWidth: "90vw",
  },
}));

function Developer() {
  const [developerMode, setDeveloperMode] = useState(
    localStorage.getItem("developerMode") === "true",
  );

  const [enableAdherenceTracking, setEnableAdherenceTracking] = useState(
    localStorage.getItem("enableAdherenceTracking") === "true",
  );

  const [enableReminderTracking, setEnableReminderTracking] = useState(
    localStorage.getItem("enableReminderTracking") === "true",
  );

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [apiPayload, setApiPayload] = useState("{}");
  const [apiPayloadHash, setApiPayloadHash] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeveloperMode = () => {
    const newDeveloperMode = !developerMode;
    localStorage.setItem("developerMode", newDeveloperMode);

    if (newDeveloperMode) {
      API_DEV_HASHES.forEach((api) => {
        const hashKey = api.hashKey;
        localStorage.setItem(hashKey, "true");
      });
      setApiDevMode(API_DEV_HASHES.map((_) => true));
    } else {
      API_DEV_HASHES.forEach((api) => {
        const hashKey = api.hashKey;
        const sampleHash = hashKey + "#payload";
        localStorage.removeItem(hashKey);
        localStorage.removeItem(sampleHash);
      });
      setApiDevMode(API_DEV_HASHES.map((_) => false));
    }
    setDeveloperMode((v) => !v);
  };

  const handleEnableAdherenceTracking = () => {
    localStorage.setItem("enableAdherenceTracking", !enableAdherenceTracking);
    setEnableAdherenceTracking((v) => !v);
  };

  const handleEnableReminderTracking = () => {
    localStorage.setItem("enableReminderTracking", !enableReminderTracking);
    setEnableReminderTracking((v) => !v);
  };

  const [apiDevMode, setApiDevMode] = useState(
    API_DEV_HASHES.map((api) => localStorage.getItem(api.hashKey) === "true"),
  );

  const handleApiDevModeChange = (index) => {
    setApiDevMode((prev) => {
      const next = [...prev];
      next[index] = !prev[index];
      if (next[index]) {
        localStorage.setItem(API_DEV_HASHES[index].hashKey, "true");
      } else {
        const hashKey = API_DEV_HASHES[index].hashKey;
        const sampleHash = hashKey + "#payload";
        localStorage.removeItem(hashKey);
        localStorage.removeItem(sampleHash);
      }
      return next;
    });
  };

  return (
    <div className={classes.pageContainer}>
      <Grid
        container
        className={classes.listContainer}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={12}>
          <div className={classes.settingsTitle}>
            <Typography variant="h2">
              Patient Application Preferences
            </Typography>
          </div>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.actionTitle} variant="h2">
            Adherence Tracking:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Switch
            checked={enableAdherenceTracking}
            onChange={handleEnableAdherenceTracking}
            className={classes.actionTitle}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.actionTitle} variant="h2">
            Reminder Tracking:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Switch
            checked={enableReminderTracking}
            onChange={handleEnableReminderTracking}
            className={classes.actionTitle}
          />
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.listContainer}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={12} className={classes.roleSwitchContainer}>
          <Button
            variant="outlined"
            onClick={() => {
              switchUserRole("CLINICIAN").then((resp) => console.log({ resp }));
            }}
          >
            Switch Role member to clinician
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              switchUserRole("MEMBER").then((resp) => console.log({ resp }));
            }}
          >
            Switch Role clinician to member
          </Button>
        </Grid>
      </Grid>
      <div>
        <div class={classes.settingsTitle}>
          <Typography variant="h2">API Developer Modes</Typography>
        </div>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>API</TableCell>
                <TableCell align="right">
                  Activate
                  <Switch
                    checked={developerMode}
                    onChange={handleDeveloperMode}
                    name="checkedA"
                    className={classes.actionTitle}
                  />
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {API_DEV_HASHES.map((apis, index) => (
                <TableRow
                  key={apis.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {apis.name}
                  </TableCell>
                  <TableCell align="right">
                    <Switch
                      key={index}
                      checked={apiDevMode[index]}
                      color="primary"
                      onChange={() => {
                        handleApiDevModeChange(index);
                      }}
                      name="checkedA"
                      className={classes.actionTitle}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      disabled={!apiDevMode[index]}
                      onClick={(e) => {
                        const sampleHash = apis.hashKey + "#payload";
                        setApiPayloadHash(sampleHash);
                        const customPayload = localStorage.getItem(sampleHash);
                        const defaultPayload = apis.sample ? apis.sample : {};
                        const sample = customPayload
                          ? JSON.parse(customPayload)
                          : defaultPayload;
                        setApiPayload(JSON.stringify(sample, undefined, 2));
                        console.log(sampleHash, sample);
                        handleClickOpen(e);
                      }}
                    >
                      Custom Payload
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Custom API Payload</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <TextField
                className={classes.payload}
                placeholder="JSON Payload"
                value={apiPayload}
                onChange={(e) => {
                  setApiPayload(e.target.value);
                }}
                multiline
                rows={10}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => {
                try {
                  const customPayload = JSON.stringify(JSON.parse(apiPayload));
                  localStorage.setItem(apiPayloadHash, customPayload);
                } catch (e) {
                  console.error(e);
                }
                handleClose();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default Developer;
