import { SAMPLE_PATIENT_LIST } from "samples/listPatients";

import { fetchCustomPayload, get } from "../communication";
import { patientListSchema } from "./schema/getPatientList.schema";

export const ROUTE_URL = "/med-mgmt/patients";
export const API_DEV_HASH = "devMode##getPatientList";

export const getPatientList = async () => {
  if (localStorage.getItem(API_DEV_HASH) === "true") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(fetchCustomPayload(API_DEV_HASH, SAMPLE_PATIENT_LIST));
      }, 1000);
    });
  } else {
    const response = await get(
      ROUTE_URL,
      null,
      API_DEV_HASH,
      patientListSchema,
    );
    return Promise.resolve(response.data);
  }
};
