export const DELIVERY_DETAILS = {
  data: [
    {
      drugName: "New Drug",
      dosageQty: "1.1",
      prescriberName: "Long prescriberName",
    },
    {
      drugName: "New Drug 2",
      dosageQty: "1.1",
      prescriberName: "Long prescriberName",
    },
    {
      drugName: "New Drug 3",
      dosageQty: "1.1",
      prescriberName: "Long prescriberName",
    },
  ],
};
