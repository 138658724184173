import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";

import { DeliveryCardDetails } from "./DeliveryCardDetails";
import DeliveryCardFooter from "./DeliveryCardFooter";
import { generateCardSubtitleText, generateCardTitleText } from "./helper";

const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    padding: theme.customMixins.pxToRem(0, 18),
    maxHeight: theme.customMixins.pxToRem(16 * 5),
    minHeight: theme.customMixins.pxToRem(16 * 5),
    "&.Mui-expanded": {
      maxHeight: theme.customMixins.pxToRem(16 * 5),
      minHeight: theme.customMixins.pxToRem(16 * 5),
    },
  },
  headerTitle: {
    font: theme.typography.sectionSubtitle.bold.font,
    color: theme.palette.primary.main,
    marginBottom: theme.customMixins.pxToRem(8),
    "&.DELIVERED": {
      color: theme.palette.textPrimary.emphasisHigh,
    },
  },
  headerDetail: {
    font: theme.typography.body.regular.font,
    color: theme.palette.textPrimary.emphasisLow,
  },
}));

function DeliveryCard({ data, showDetailsButton, showDetailsHandler }) {
  const classes = useStyles();

  const cardTitle = generateCardTitleText(data.latestStatus, data.deliveryDate);
  const cardSubtitle = generateCardSubtitleText(data.deliveryDate);

  return (
    <Accordion data-testid="accordion" square={true}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon color="primary" />}
      >
        <Grid container justifyContent="space-around" alignItems="center">
          <Grid item xs={12}>
            <Typography
              className={`${classes.headerTitle} ${data.latestStatus}`}
              variant="h3"
            >
              {cardTitle}
            </Typography>
            <Grid container alignItems="center">
              <Typography className={classes.headerDetail} variant="h5">
                Delivery: {cardSubtitle}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <Divider variant="middle" />
      <AccordionDetails>
        <DeliveryCardDetails deliveryData={data} />
      </AccordionDetails>
      <Divider variant="middle" />
      <AccordionDetails>
        <DeliveryCardFooter
          showDetailsButton={showDetailsButton}
          showDetailsHandler={showDetailsHandler}
        />
      </AccordionDetails>
    </Accordion>
  );
}

DeliveryCard.defaultProps = {
  data: {
    address: {
      addressLine1: "1234 Broadway",
      addressLine2: "Bldg C APT 3",
      city: "Westville",
      state: "NJ",
      zipcode: "8093",
    },
    deliveryDate: null,
    deliveryEvents: [
      { status: "Processing", dateOfEvent: "2021/8/2", note: null },
      { status: "Shipped", dateOfEvent: "2021/8/16", note: null },
    ],
    firstName: "John",
    lastName: "Doe",
    latestStatus: "PROCESSING",
    shipmentId: "1234",
  },
  isExpanded: false,
};

DeliveryCard.propTypes = {
  /**
   * Delivery Card Data
   */
  data: PropTypes.shape({
    address: PropTypes.shape({
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    deliveryEvents: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string.isRequired,
        dateOfEvent: PropTypes.string,
        note: PropTypes.string,
      }).isRequired,
    ).isRequired,
    deliveryDate: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    latestStatus: PropTypes.string.isRequired,
    shipmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  }).isRequired,
};

export default DeliveryCard;
