export const primaryReducer = (state, action) => {
  switch (action.type) {
    case "SET_GLOBAL_ERROR":
      return {
        ...state,
        globalError: action.globalError,
      };
    case "IS_PRODUCTION":
      return {
        ...state,
        isProduction: action.isProduction,
      };
    case "SET_USER_TYPE":
      return {
        ...state,
        userType: action.userType,
      };
    case "ADD_TOKEN":
      return {
        ...state,
        token: action.token,
      };
    case "TOGGLE_LOADER":
      return {
        ...state,
        loading: action.loading,
      };
    case "APP_BAR_TITLE":
      return {
        ...state,
        title: action.title,
      };
    case "SHOW_BACK_BUTTON":
      return {
        ...state,
        showBackButton: action.showBackButton,
      };
    case "DAILY_MEDICATION_DATE":
      return {
        ...state,
        dailyMedicationDate: action.dailyMedicationDate,
      };
    case "SCHEMA_ERROR":
      let schemaError = {};
      if (action.schemaError) {
        schemaError = { ...state.schemaError, ...action.schemaError };
      }
      return {
        ...state,
        schemaError,
      };
    default:
      return state;
  }
};
