import { PALETTE } from "./colorPalette";
import { MIXINS } from "./mixins";
import { TYPOGRAPHY } from "./typography";

const SNACKBAR_SUCCESS = {
  "&.MuiSnackbar-anchorOriginTopCenter": {
    top: MIXINS.pxToRem(16 * 5),
  },
  "& .MuiPaper-root": {
    borderRadius: MIXINS.pxToRem(4),
    background: PALETTE.system.W,
    borderWidth: MIXINS.pxToRem(2),
    borderStyle: "solid",
    borderColor: PALETTE.alert.green,
  },
  "& .MuiSnackbarContent-message": {
    color: PALETTE.alert.green,
    font: TYPOGRAPHY.body.medium.font,
  },
};

const SNACKBAR_WARNING = {
  "&.MuiSnackbar-anchorOriginTopCenter": {
    top: MIXINS.pxToRem(16 * 5),
  },
  "& .MuiPaper-root": {
    borderRadius: MIXINS.pxToRem(4),
    background: PALETTE.system.W,
    borderWidth: MIXINS.pxToRem(2),
    borderStyle: "solid",
    borderColor: PALETTE.error.secondary,
  },
  "& .MuiSnackbarContent-message": {
    color: PALETTE.error.secondary,
    font: TYPOGRAPHY.body.medium.font,
  },
};

const SNACKBAR_ERROR = {
  "&.MuiSnackbar-anchorOriginTopCenter": {
    top: MIXINS.pxToRem(16 * 5),
  },
  "& .MuiPaper-root": {
    borderRadius: MIXINS.pxToRem(4),
    background: PALETTE.system.W,
    borderWidth: MIXINS.pxToRem(2),
    borderStyle: "solid",
    borderColor: PALETTE.error.secondary,
  },

  "& .MuiSnackbarContent-message": {
    color: PALETTE.error.secondary,
    font: TYPOGRAPHY.body.medium.font,
  },
};

export const SNACKBAR = {
  success: SNACKBAR_SUCCESS,
  warning: SNACKBAR_WARNING,
  error: SNACKBAR_ERROR,
};
