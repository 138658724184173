import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    display: "flex",
    marginTop: theme.customMixins.pxToRem(12),
  },
  action: {
    padding: theme.customMixins.pxToRem(16, 0),
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "& > svg": {
      color: theme.palette.system.W,
      width: theme.customMixins.pxToRem(48),
      height: theme.customMixins.pxToRem(48),
    },
    "& > span": {
      color: theme.palette.system.W,
      font: theme.typography.sectionSubtitle.bold.font,
      fontStyle: "normal",
      lineHeight: theme.customMixins.pxToRem(17),
      letterSpacing: 0,
    },
  },
  actionAccept: {
    background: theme.palette.success.main,
  },
  actionReject: {
    background: theme.palette.error.main,
  },
  disabled: {
    opacity: 0.5,
  },
}));

export function CardFooterAction({ onAccept, isDisabled, onReject }) {
  const classes = useStyles();

  const handleAccept = () => {
    if (!isDisabled) onAccept();
  };

  const handleReject = () => {
    if (!isDisabled) onReject();
  };

  return (
    <div className={classes.actionContainer}>
      <div
        onClick={handleAccept}
        data-testid="action-accept"
        className={[
          classes.action,
          classes.actionAccept,
          isDisabled ? classes.disabled : "",
        ].join(" ")}
      >
        <CheckIcon />
        <span> All taken </span>
      </div>
      <div
        onClick={handleReject}
        data-testid="action-reject"
        className={[
          classes.action,
          classes.actionReject,
          isDisabled ? classes.disabled : "",
        ].join(" ")}
      >
        <ClearOutlinedIcon />
        <span> Not all taken </span>
      </div>
    </div>
  );
}

CardFooterAction.defaultProps = {
  isDisabled: false,
};

CardFooterAction.propTypes = {
  isDisabled: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default CardFooterAction;
