export const UI_ERROR_MESSAGES = {
  generic: "Sorry, we encountered a problem. Please try again later.",
  referralDetailsDisplay:
    "Sorry, failed to display referral details. Please try again later.",
  medicationDetailsDisplay: "Failed to show medication details.",
};

export const API_ERROR_MESSAGES = {
  generic: "",
};
