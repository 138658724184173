import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CONFIGURATION } from "constants/configuration";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cardRowThumbnail: {
    width: theme.customMixins.pxToRem(25),
    height: theme.customMixins.pxToRem(25),
    marginRight: theme.customMixins.pxToRem(16),
  },
  cardDetailsContainer: {
    padding: theme.customMixins.pxToRem(12),
    flexGrow: "1",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    "& :nth-child(2)": {
      marginTop: theme.customMixins.pxToRem(8),
    },

    border: theme.customCss.border,
    margin: theme.customMixins.pxToRem(0),
    "&:last-child": {
      borderBottom: `none`,
      marginBottom: theme.customMixins.pxToRem(0),
    },
  },
  drugName: {
    textTransform: "capitalize",
    font: theme.typography.body.medium.font,
    color: theme.palette.textPrimary.emphasisHigh,
  },
  drugDetails: {
    font: theme.typography.body.medium.font,
    color: theme.palette.textPrimary.emphasisLow,
  },
}));

const modifyQuantity = (dosageQty, unitOfDosage) => {
  if (isNaN(dosageQty)) return null;

  const dosageQtyNumber = Number(dosageQty);

  // Data Cleaning
  if (dosageQtyNumber === 0) return null;

  // Display Not Required
  if (dosageQtyNumber === 1) return null;

  return `${dosageQtyNumber.toString()} ${unitOfDosage}`;
};

const DeliveryDetailsCardRow = (props) => {
  const { drugName, dosageQty, drugImageUrl, prescriberName, reactUniqueId } =
    props;

  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <div
        key={reactUniqueId}
        className={classes.cardDetailsContainer}
        data-testid="details"
      >
        {CONFIGURATION.showMedicationImages && (
          <>
            {
              <img
                className={classes.cardRowThumbnail}
                src={
                  drugImageUrl
                    ? drugImageUrl
                    : "https://via.placeholder.com/150"
                }
                alt="ico"
              ></img>
            }
          </>
        )}
        <div>
          <Typography
            className={classes.drugName}
            data-testid="drug-name"
            color="textPrimary"
            variant="h5"
          >
            {drugName.toLowerCase()}
          </Typography>

          <Typography
            className={classes.drugDetails}
            color="textPrimary"
            variant="h6"
          >
            Qty {dosageQty}
          </Typography>

          <Typography
            className={classes.drugDetails}
            color="textPrimary"
            variant="h6"
          >
            {prescriberName}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default DeliveryDetailsCardRow;

DeliveryDetailsCardRow.propTypes = {
  drugName: PropTypes.string.isRequired,
  strength: PropTypes.string.isRequired,
  dosageQty: PropTypes.string.isRequired,
  unitOfDosage: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  drugImageUrl: PropTypes.string,

  reactUniqueId: PropTypes.string.isRequired,
};

DeliveryDetailsCardRow.defaultProps = {
  drugName: "New Drug",
  dosageQty: "3",
  prescriberName: "prescriberName",
  reactUniqueId: `c-${+new Date()}`,
};
