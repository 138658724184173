import * as Joi from "joi";

const medicationItems = Joi.object({
  drugName: Joi.string().required(),
  drugCode: Joi.number().allow(null),
  drugImageUrl: Joi.string().allow(null, ""),
  dosageQty: Joi.alternatives(Joi.string(), Joi.number()).allow(null, ""),
  unitOfDosage: Joi.string().allow(null, ""),
  strength: Joi.string().allow(null, ""),
  unitOfStrength: Joi.string().allow(null, ""),
  description: Joi.string().allow(null, ""),
  isPartOfPillPack: Joi.string().allow(null, ""),
  consumptionCadence: Joi.string().allow(null, ""),
}).unknown();

export const allMedicationSchema = Joi.object({
  medications: Joi.array().items(medicationItems).required(),
})
  .required()
  .unknown();
