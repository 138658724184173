import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Store } from "context/Context";
import { getReferralList } from "helpers/api";
import { INSTANT_READ_CACHE } from "helpers/cacheConfiguration";
import { clinicianRoutes } from "helpers/routeConfig";
import CardErrorBoundary from "pages/common/CardErrorBoundary/CardErrorBoundary";
import GlobalApiError from "pages/common/ErrorScreens/GlobalApiError";
import NoReferrals from "pages/common/NoDataScreens/NoReferrals/NoReferrals";
import SimplePageLoader from "pages/common/PageLoaders/SimplePageLoader";
import ReferralCard from "pages/common/ReferralCard/ReferralCard";
import SearchBar from "pages/common/SearchBar/SearchBar";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { generateHash } from "utility";

import { sortReferrals } from "./sortReferrals";

const useStyles = makeStyles((theme) => ({
  referralsSection: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  referralContainer: {
    height: theme.customMixins.pxToRem(10),
    overflowY: "scroll",
    padding: theme.customMixins.pxToRem(24),
    margin: "0 auto",
    width: "100%",
    flexGrow: 1,
  },
  errorMessage: {
    font: theme.typography.description.regular.font,
    color: theme.palette.error.main,
  },
  selectFilter: {
    marginTop: theme.customMixins.pxToRem(24),
    "& .MuiSvgIcon-root ": {
      color: theme.palette.primary.A900,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      color: theme.palette.primary.A900,
      font: theme.typography.sectionSubtitle.medium.font,
    },
    "& .MuiSelect-select": {
      color: theme.palette.primary.A900,
      font: theme.typography.sectionSubtitle.medium.font,
      padding: theme.customMixins.pxToRem(0),
    },
  },
  filterButton: {
    display: "inline-block",

    borderColor: theme.palette.white.high,
    borderWidth: theme.customMixins.pxToRem(1),
    borderStyle: "solid",
    borderRadius: theme.customMixins.pxToRem(6),

    padding: theme.customMixins.pxToRem(8, 16),
    margin: theme.customMixins.pxToRem(16, 0),
    marginRight: theme.customMixins.pxToRem(8),

    background: theme.palette.white.high,
    color: theme.palette.textPrimary.emphasisHigh,
    font: theme.typography.body.regular.font,
    cursor: "pointer",

    "-webkit-tap-highlight-color": "transparent",
    "user-select": "none",

    "&.selected": {
      borderColor: theme.palette.primary.A700,
      background: theme.palette.primary.A100,
    },
    "&.hidden": {
      display: "none",
    },
  },
}));

const getReferralListItems = async (args) => {
  return getReferralList();
};

export const Referrals = () => {
  const classes = useStyles();
  const history = useHistory();

  const [sort, setSort] = useState("Name A – Z");
  const { dispatch, state } = useContext(Store);

  const [searched, setSearched] = useState("");
  const [showMyReferrals, setShowMyReferrals] = useState(false);
  const [showActionNeeded, setShowActionNeeded] = useState(false);

  const [showReferralDetail, setShowReferralDetail] = useState(null);
  const {
    data: referrals,
    isLoading,
    isError,
    error,
  } = useQuery(["ReferralList"], getReferralListItems, INSTANT_READ_CACHE);

  useEffect(() => {
    dispatch({ type: "APP_BAR_TITLE", title: "Referrals" });
  }, [dispatch]);

  const sortRefs = (referrals) => {
    let filter = {};

    if (showMyReferrals) filter = { ...filter, isMyReferral: true };
    if (showActionNeeded) filter = { ...filter, isActionNeeded: true };

    return sortReferrals(referrals, searched, filter, sort);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleShowMyReferralsChange = () => {
    setShowMyReferrals((v) => !v);
  };

  const handleShowActionNeededChange = () => {
    setShowActionNeeded((v) => !v);
  };

  const handleShowReferralDetail = (referral) => {
    if (referral) {
      setSearched("");
      setShowReferralDetail(referral);
      localStorage.setItem("referralDetail", JSON.stringify(referral));
      history.push(clinicianRoutes.referralDetail);
    } else {
      setShowReferralDetail(null);
    }
  };
  return (
    <div data-testid="referral-section" className={classes.referralsSection}>
      <SimplePageLoader hidden={!isLoading} />
      {!isError && !isLoading && referrals && (
        <>
          <NoReferrals extraPadding={4} hidden={referrals.length !== 0} />
          {referrals.length > 0 && !showReferralDetail && (
            <div className={classes.referralContainer}>
              {!showReferralDetail && (
                <>
                  <div data-testid="referral-section-body">
                    <SearchBar
                      onChange={setSearched}
                      placeholder="Search by patient name"
                    />

                    <div ata-testid="referral-filters">
                      <Typography
                        className={`${classes.filterButton} ${
                          showMyReferrals ? "selected" : ""
                        }`}
                        onClick={handleShowMyReferralsChange}
                      >
                        My referrals
                      </Typography>
                      <Typography
                        className={`${classes.filterButton} hidden ${
                          showActionNeeded ? "selected" : ""
                        }`}
                        onClick={handleShowActionNeededChange}
                      >
                        Action needed
                      </Typography>
                    </div>

                    <Select
                      value={sort}
                      onChange={handleSortChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className={classes.selectFilter}
                      data-testid="sort-select"
                      name="sort"
                    >
                      <MenuItem
                        value={"Name A – Z"}
                        data-testid="sort-select-name"
                      >
                        Name A – Z
                      </MenuItem>
                      <MenuItem
                        value={"New – Old"}
                        data-testid="sort-select-date"
                      >
                        New – Old
                      </MenuItem>
                    </Select>

                    {sortRefs(referrals).map((referral, idx) => {
                      return (
                        <CardErrorBoundary>
                          <ReferralCard
                            referral={referral}
                            key={generateHash(JSON.stringify(referral))}
                            onClick={() => handleShowReferralDetail(referral)}
                            data-testid="referral-card"
                          />
                        </CardErrorBoundary>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}

      {isError && (
        <GlobalApiError showErrorDetails={!state.isProduction} error={error} />
      )}
    </div>
  );
};

export default Referrals;
