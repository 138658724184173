import {
  AxiosHttpErrors,
  SchemaValidationError,
  TransformationError,
} from "./errors";

const MAX_FAILURE_COUNT = 2;
const retryConfiguration = (failureCount, error) => {
  const ALLOWED_ERROR_CODES = ["500", "501", "502", "503", "504", undefined];
  const errorCode = error.errorCode;

  console.warn({
    retryConfigurationError: error,
    stats: { errorCode, failureCount },
  });

  if (error instanceof SchemaValidationError) {
    console.warn("RETRY REVOKED | SchemaValidationError");
    return false;
  }

  if (error instanceof TransformationError) {
    console.warn("RETRY REVOKED | TransformationError");
    return false;
  }

  if (error instanceof AxiosHttpErrors) {
    if (!ALLOWED_ERROR_CODES.includes(`${error.statusCode}`)) {
      console.warn("RETRY REVOKED | Invalid status code", {
        statusCode: error.statusCode,
      });
      return false;
    }
  }

  if (failureCount > MAX_FAILURE_COUNT - 1) {
    console.warn("RETRY REVOKED | Maximum Limit Reached");
    return false;
  }

  return true;
};

export const BASIC_CACHE = {
  staleTime: 5 * 30 * 1000,
  refetchInterval: 15 * 60 * 1000,
  refetchOnWindowFocus: true,
  refetchOnReconnect: true,
  refetchOnMount: true,
  retry: retryConfiguration,
};

export const INSTANT_READ_CACHE = {
  staleTime: 0,
  refetchInterval: 5 * 60 * 1000,
  refetchOnWindowFocus: true,
  refetchOnReconnect: true,
  refetchOnMount: true,
  retry: retryConfiguration,
};
