import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { generateFullName } from "utility/stringModifications";

import RedFlagIcon from "./assets/icons/redFlag.svg";
import RightArrowIcon from "./assets/icons/rightChevron.svg";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    margin: theme.customMixins.pxToRem(16, 0),
    padding: theme.customMixins.pxToRem(18),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: theme.customCss.cardShadow,
    background: theme.palette.white.high,
    cursor: "pointer",
  },
  rightContainer: {
    cursor: "pointer",
    padding: theme.customMixins.pxToRem(12),
    minHeight: theme.customMixins.pxToRem(16 * 3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    font: theme.typography.sectionSubtitle.bold.font,
    marginBottom: theme.customMixins.pxToRem(6),
    "& > img": {
      marginLeft: theme.customMixins.pxToRem(10),
    },
  },
  description: {
    "& .alert": {
      color: theme.palette.error.dark,
    },
  },
}));

export const PatientCard = ({ patient, onClick }) => {
  const classes = useStyles();

  const patientName = generateFullName({
    firstName: patient.firstName,
    lastName: patient.lastName,
    separator: ", ",
    reverse: true,
  });
  const missedDosesCount =
    patient.missedDoses === 0 ? "No" : `${patient.missedDoses}`;

  return (
    <div
      onClick={onClick}
      className={classes.cardContainer}
      data-testid="patient-card"
    >
      <div className={classes.leftContainer}>
        <Typography className={classes.headerText}>
          {patientName}
          {patient.isHighRisk && <img src={RedFlagIcon} alt="RedFlagIcon" />}
        </Typography>
        <div className={classes.description}>
          {patient.isATOn ? (
            <Typography
              className={`${patient.missedDoses >= 3 ? "alert" : ""}`}
            >
              {missedDosesCount} missed doses
            </Typography>
          ) : (
            <Typography>No medication tracking</Typography>
          )}
        </div>
      </div>
      <div className={classes.rightContainer}>
        <img src={RightArrowIcon} alt="RightArrowIcon" />
      </div>
    </div>
  );
};

PatientCard.propTypes = {
  /**
   * Patient Card details.
   */
  patient: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isATOn: PropTypes.bool.isRequired,
    missedDoses: PropTypes.number,
    isHighRisk: PropTypes.bool,
    isMyPatient: PropTypes.bool.isRequired,
  }).isRequired,
  /**
   * Returns a response when the cards is clicked.
   */
  onClick: PropTypes.func,
};

PatientCard.defaultProps = {
  patient: {
    firstName: "Violet",
    lastName: "Johnson",
    missedDoses: 3,
    isATOn: true,
    isHighRisk: true,
    isMyPatient: false,
  },
  onClick: console.log,
};

export default PatientCard;
