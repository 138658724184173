export const style = (theme) => ({
  referralContainer: {
    flexGrow: 1,
    padding: theme.customMixins.pxToRem(16),
    overflowY: "scroll",
    height: theme.customMixins.pxToRem(10),
    maxWidth: theme.pageDimensions.mobileMaxWidth,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    "& .MuiFormControl-root .MuiFilledInput-root": {
      backgroundColor: theme.palette.white.high,
    },
    "& > *": {
      width: "100%",
    },
  },
  divider: {
    background: theme.palette.system.line,
    margin: theme.customMixins.pxToRem(16),
  },
  subSectionTitle: {
    font: theme.typography.sectionSubtitle.medium.font,
    color: theme.palette.primary.A900,
    padding: theme.customMixins.pxToRem(6),
  },
  subSectionBody: {
    font: theme.typography.body.regular.font,
    color: theme.palette.textPrimary.emphasisMedium,
    padding: theme.customMixins.pxToRem(6),
  },
  careGiverToggle: {
    background: theme.palette.white.high,
    height: theme.customMixins.pxToRem(60),
  },
  submitButton: {
    margin: theme.customMixins.pxToRem(16),
    padding: theme.customMixins.pxToRem(12),
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  greyedSubmitButton: {
    backgroundColor: theme.palette.shades.A1,
    border: theme.customCss.border,
    color: theme.palette.textPrimary.emphasisLow,
    "&:hover": {
      backgroundColor: theme.palette.shades.A1,
    },
  },
  removeButton: {
    margin: theme.customMixins.pxToRem(16),
    color: theme.palette.error.main,
    backgroundColor: theme.palette.system.W,
    border: theme.customCss.border,
    "&:hover": {
      backgroundColor: theme.palette.system.W,
    },
  },
  subSection: {
    alignSelf: "flex-start",
  },
  disclaimerText: {
    font: theme.typography.description.regular.font,
    color: theme.palette.textPrimary.emphasisLow,
    padding: theme.customMixins.pxToRem(6),
  },
  errorMessage: {
    font: theme.typography.description.regular.font,
    color: theme.palette.error.main,
  },
  hideField: {
    display: "none",
  },
  dropdownListContainer: {
    boxShadow: theme.customCss.boxShadow,
  },
  leaveButton: {
    margin: theme.customMixins.pxToRem(8, 0),
    backgroundColor: theme.palette.primary.A700,
    color: theme.palette.white.high,
    boxShadow: "none",
  },
});
