import * as Joi from "joi";

const patientDetails = Joi.object({
  firstName: Joi.string().allow(null, ""),
  lastName: Joi.string().allow(null, ""),
  missedDoses: Joi.number().allow(null), //coming as string from BE
  isATOn: Joi.boolean(),
  isHighRisk: Joi.boolean(),
  isMyPatient: Joi.boolean(),
  phoneNumber: Joi.string().required(),
}).unknown();

export const patientListSchema = Joi.array().items(patientDetails).required();
