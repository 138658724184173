import * as Joi from "joi";

export const patientProfileSchema = Joi.object({
  patientFirstName: Joi.string().allow(null, ""),
  patientLastName: Joi.string().allow(null, ""),
  patientPhoneNumber: Joi.string().required(),
  isMyPatient: Joi.boolean().required(),
  isHighRisk: Joi.boolean().required(),
})
  .required()
  .unknown();
