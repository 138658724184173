import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { checkIfPastDate, TimeUtility } from "utility";

import Bell from "./assets/bell.svg";
import BlackTick from "./assets/blackTick.svg";

const useStyles = makeStyles((theme) => ({
  cardTrackingContainer: {
    marginBottom: theme.customMixins.pxToRem(12),
    paddingLeft: theme.customMixins.pxToRem(12),
    display: "flex",
  },
  badge: {
    display: "flex",
    borderRadius: theme.customMixins.pxToRem(12),
    alignItems: "center",
    maxWidth: "fit-content",
    padding: theme.customMixins.pxToRem(6, 12),
    marginBottom: theme.customMixins.pxToRem(6),
    background: theme.palette.system.BG,
    textTransform: "lowercase",
    "& img": {
      marginRight: theme.customMixins.pxToRem(4),
    },
  },
  overdue: {
    borderWidth: theme.customMixins.pxToRem(1),
    borderStyle: "solid",
    borderColor: theme.palette.error.secondary,
    background: theme.palette.warning.light,
  },
  reminderTrackingBadge: {
    marginRight: theme.customMixins.pxToRem(16),
  },
  clickable: {
    cursor: "pointer",
  },
  adherenceTrackingBadge: {
    marginRight: theme.customMixins.pxToRem(16),
  },
  adherenceIcon: {
    marginRight: theme.customMixins.pxToRem(14),
  },
  adherenceTitle: {
    font: theme.typography.body.medium.font,
    marginLeft: theme.customMixins.pxToRem(6),
  },
}));

function MedicationCardBadges(props) {
  const classes = useStyles();

  const {
    enableAdherenceTracking,
    enableReminderTracking,
    onAdherenceTrackingClick,
    onReminderTrackingClick,
    consumptionDate,
    takenTime,
    overdue,
  } = props;

  if (!enableReminderTracking) return <></>;

  const timeUtility = new TimeUtility();
  let reminderTime = timeUtility.convertTimeTo12HrFormat(props.reminderTime);

  const onReminderClick = () => {
    if (!checkIfPastDate(new Date(consumptionDate))) {
      onReminderTrackingClick();
    }
  };

  return (
    <div className={classes.cardTrackingContainer}>
      {reminderTime && (
        <div
          data-testid="reminder-tracking-badge"
          onClick={onReminderClick}
          className={`${classes.badge} ${classes.reminderTrackingBadge}
            ${!checkIfPastDate(new Date(consumptionDate)) && classes.clickable}
            ${overdue && classes.overdue}`}
        >
          <img src={Bell} alt="icon" />
          <span>{reminderTime}</span>
        </div>
      )}
      {enableAdherenceTracking && takenTime && (
        <div
          onClick={onAdherenceTrackingClick}
          className={`${classes.badge} ${classes.reminderTrackingBadge}`}
        >
          <img src={BlackTick} alt="icon" />
          <span>{takenTime}</span>
        </div>
      )}
    </div>
  );
}

MedicationCardBadges.defaultProps = {
  overdue: false,
  reminderTime: +new Date(),
  enableAdherenceTracking: false,
  enableReminderTracking: false,
  onAdherenceTrackingClick: () => {},
  onReminderTrackingClick: () => {},
  consumptionDate: "2000-01-01",
};

MedicationCardBadges.propTypes = {
  overdue: PropTypes.bool,
  reminderTime: PropTypes.number,
  takenTime: PropTypes.string,
  enableAdherenceTracking: PropTypes.bool.isRequired,
  enableReminderTracking: PropTypes.bool.isRequired,
  onAdherenceTrackingClick: PropTypes.func.isRequired,
  onReminderTrackingClick: PropTypes.func.isRequired,
  consumptionDate: PropTypes.string.isRequired,
};

export default MedicationCardBadges;
