import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import LineIcon from "./assets/line.svg";
import { getReferralEventIcon } from "./helpers/getReferralDetailEventIcon";

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    display: "flex",
  },
  itemDetails: {
    padding: theme.customMixins.pxToRem(8),
  },
  itemIconHolder: {
    display: "flex",
    alignItems: "center",
    height: theme.customMixins.pxToRem(16 * 2.5),
    "& > img": {
      paddingRight: theme.customMixins.pxToRem(8),
    },
  },
  verticalLine: {
    top: 0,
    left: 0,
    width: theme.customMixins.pxToRem(2),
    height: theme.customMixins.pxToRem(21),
    backgroundColor: theme.palette.primary.A900,
  },
  eventName: {
    font: theme.typography.body.bold.font,
    color: theme.palette.textPrimary.emphasisLow,
  },
  errorEventName: {
    font: theme.typography.body.bold.font,
    color: theme.palette.error.secondary,
  },
  updatedAt: {
    font: theme.typography.description.regular.font,
    color: theme.palette.textPrimary.emphasisLow,
  },
  eventComment: {
    marginTop: theme.customMixins.pxToRem(16),
    font: theme.typography.description.regular.font,
    color: theme.palette.primary.A900,
  },
  commentUpdatedAt: {
    font: theme.typography.description.regular.font,
    color: theme.palette.textPrimary.emphasisLow,
  },
  itemContent: {
    display: "flex",
    flexDirection: "row",
    borderLeftWidth: theme.customMixins.pxToRem(2),
    borderLeftColor: theme.palette.primary.A900,
    borderLeftStyle: "solid",
  },
  lastItemContent: {
    display: "flex",
    flexDirection: "row",
  },
}));

const getFormattedDate = (dateString) => {
  const options = { month: "long", day: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};
const ReferralDetailListItem = ({ referralDetailItem, isLastItem }) => {
  const classes = useStyles();
  const eventName = referralDetailItem.eventName;
  let icon = getReferralEventIcon(eventName);
  let eventNameClass = "eventName";

  return (
    <div className={isLastItem ? classes.lastItemContent : classes.itemContent}>
      {isLastItem && <div className={classes.verticalLine} />}

      <div
        data-testid="referral-detail-list-item"
        className={classes.itemContainer}
      >
        <div className={classes.itemIconHolder}>
          <img src={LineIcon} alt="LineIcon" />
          <img src={icon} alt="icon" />
        </div>
        <div className={classes.itemDetails}>
          <Typography className={classes[eventNameClass]} variant="h3">
            {referralDetailItem.eventName}
          </Typography>
          <Typography className={classes.updatedAt} variant="h3">
            Updated {getFormattedDate(referralDetailItem.updatedAt)}
          </Typography>
          {referralDetailItem.eventCommentDetails && (
            <>
              <Typography className={classes.eventComment} variant="h3">
                {referralDetailItem.eventCommentDetails.eventComment}
              </Typography>
              <Typography className={classes.commentUpdatedAt} variant="h3">
                Updated{" "}
                {getFormattedDate(
                  referralDetailItem.eventCommentDetails.commentUpdatedAt,
                )}
              </Typography>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

ReferralDetailListItem.defaultProps = {
  referralDetailItem: {},
  isLastItem: false,
};

ReferralDetailListItem.propTypes = {
  referralDetailItem: PropTypes.object,
  isLastItem: PropTypes.bool,
};

export default ReferralDetailListItem;
