import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Checkbox,
  Divider,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AddCircleOutline } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  careGiverRelationshipOptions,
  referralFormDefaultInputValues,
} from "constants/formInputValues";
import { US_STATE_LIST } from "constants/usStateList";
import { Store } from "context/Context";
import { sendReferralData } from "helpers/api";
import { clinicianRoutes } from "helpers/routeConfig";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import validator from "validator";
import Dialog from "@material-ui/core/Dialog";

import { style } from "./ReferralForm.style";
import useUnsavedChangesWarning from "./useUnsavedChangesWarning";
import { CONFIGURATION } from "constants/configuration";

const useStyles = makeStyles((theme) => style(theme));

function ReferralForm() {
  const classes = useStyles();
  const history = useHistory();
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  const [disclaimerCheck, setDisclaimerCheck] = useState(false);
  const [showDisclaimerError, setShowDisclaimerError] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [careGiverPhoneNumber, setCareGiverPhoneNumber] = useState("");
  const [showCareGiverDetails, setShowCareGiverDetails] = useState(false);

  const { dispatch } = useContext(Store);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    unregister,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: "onBlur",
    defaultValues: referralFormDefaultInputValues,
  });

  const [Prompt] = useUnsavedChangesWarning(isDirty);

  const onSubmit = (data) => {
    if (disclaimerCheck) {
      setIsLoading(true);
      sendReferralData(data)
        .then((_) => {
          setIsLoading(false);
          reset();
          history.push({
            pathname: clinicianRoutes.referrals,
            state: { detail: "referralCreated" },
          });
        })
        .catch((err) => {
          if (err?.statusCode === 409) {
            setValidationErrorMessage(
              `A referral already exists that is associated with that mobile number. Please call ${CONFIGURATION.pharmacyContact.display} for help`,
            );
            setIsLoading(false);
            setShowDialog(true);
          } else {
            setValidationErrorMessage(
              "Sorry, we encountered a problem. Please try again later",
            );
            setIsLoading(false);
            setShowDialog(true);
          }
        });
    } else {
      setShowDisclaimerError(true);
    }
  };

  useEffect(() => {
    dispatch({ type: "APP_BAR_TITLE", title: "Referrals" });
  }, [dispatch]);

  const handleDateChange = (date) => {
    setDateOfBirth(date.toLocaleDateString("en-US"));
    setValue("dateOfBirth", date.toLocaleDateString("en-US"));
    clearErrors("dateOfBirth");
  };
  const handleZipCodeInputChange = (e) => {
    const { value } = e.target;
    if ((value === "" || validator.isNumeric(value)) && value.length <= 5) {
      setZipCode(value);
    }
  };

  const handlePhoneNumberInputChange = (e) => {
    const { value, name } = e.target;
    if ((value === "" || validator.isNumeric(value)) && value.length <= 10) {
      name === "phoneNumber" && setPhoneNumber(value);
      name === "careGiverPhoneNumber" && setCareGiverPhoneNumber(value);
    }
  };

  const careGiverFields = [
    "careGiverFirstName",
    "careGiverLastName",
    "careGiverPhoneNumber",
    "careGiverPatientRelationship",
    "isCareGiverPreferredContact",
  ];
  return (
    <div className={classes.referralContainer} data-testid="referral-form">
      <form onSubmit={handleSubmit(onSubmit)} className={classes.formContainer}>
        <div>
          <Typography color="primary" className={classes.subSectionTitle}>
            Patient referrals
          </Typography>
          <Typography className={classes.subSectionBody}>
            Refer a patient to HomeFree Pharmacy, and our team will be in
            contact within 1-2 business days.
          </Typography>
        </div>

        <TextField
          helperText={errors.firstName && "First name is required"}
          error={errors.firstName}
          label="First name"
          variant="filled"
          type="text"
          margin="normal"
          name="firstName"
          autoComplete="no"
          inputProps={{ maxLength: 200, "data-testid": "firstname" }}
          {...register("firstName", { required: true })}
        />

        <TextField
          error={errors.lastName}
          helperText={errors.lastName && "Last name is required"}
          variant="filled"
          type="text"
          name="lastName"
          label="Last name"
          margin="normal"
          autoComplete="no"
          inputProps={{ maxLength: 200, "data-testid": "lastname" }}
          {...register("lastName", { required: true })}
        />

        <TextField
          error={errors.address}
          helperText={errors.address && "Shipping address is required"}
          variant="filled"
          type="text"
          name="address"
          label="Shipping address"
          margin="normal"
          autoComplete="no"
          inputProps={{ maxLength: 200, "data-testid": "address" }}
          {...register("address", { required: true })}
        />

        <TextField
          error={errors.city}
          helperText={errors.city && "City is required"}
          variant="filled"
          type="text"
          name="city"
          label="City"
          margin="normal"
          autoComplete="no"
          inputProps={{ maxLength: 200, "data-testid": "city" }}
          {...register("city", { required: true })}
        />

        <TextField
          variant="filled"
          select
          error={errors.state}
          helperText={errors.state && "State is required"}
          name="state"
          label="State"
          margin="normal"
          inputProps={{ "data-testid": "state" }}
          SelectProps={{
            SelectDisplayProps: {
              "data-testid": "id-country",
            },
          }}
          MenuProps={{ classes: { paper: classes.dropdownListContainer } }}
          {...register("state", { required: true })}
        >
          {US_STATE_LIST.map((usState, index) => {
            return (
              <MenuItem
                value={usState.value}
                inputProps={{ "data-testid": `select-option[${index}]` }}
                data-testid={`select-option[${index}]`}
              >
                {usState.label}
              </MenuItem>
            );
          })}
        </TextField>

        <TextField
          error={errors.zipcode}
          helperText={
            (errors.zipcode?.type === "required" && "Zip Code is required") ||
            (errors.zipcode?.type === "validate" && "Incorrect zip code") ||
            (errors.zipcode?.type === "minLength" && "Incorrect zip code") ||
            JSON.stringify(errors.zipcode?.type)
          }
          variant="filled"
          type="text"
          name="zipcode"
          label="Zip code"
          value={zipCode}
          margin="normal"
          autoComplete="no"
          inputProps={{ "data-testid": "zipcode", maxLength: 5 }}
          {...register("zipcode", {
            onChange: handleZipCodeInputChange,
            required: true,
            minLength: 5,
            maxLength: 5,
            validate: (zipcode) => zipcode.toString().length === 5,
          })}
        />

        {/* do not show this input field */}
        <TextField
          error={errors.dateOfBirth}
          helperText={errors.dateOfBirth && "Date of Birth is required"}
          variant="filled"
          type="text"
          name="dateOfBirth"
          label="Date of birth"
          margin="normal"
          className={classes.hideField}
          value={dateOfBirth}
          {...register("dateOfBirth", {
            required: true,
            onChange: handleDateChange,
          })}
        />

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            error={errors.dateOfBirth}
            helperText={errors.dateOfBirth && "Date of birth is required"}
            disableFuture
            format="MM/dd/yyyy"
            inputVariant="filled"
            label="Date of birth"
            openTo="year"
            value={dateOfBirth}
            onChange={(date) => handleDateChange(date)}
            margin="normal"
            InputProps={{ readOnly: true }}
            inputProps={{ "data-testid": "dateOfBirth" }}
          />
        </MuiPickersUtilsProvider>

        <TextField
          error={errors.phoneNumber}
          helperText={
            (errors.phoneNumber?.type === "required" &&
              "Phone Number is required") ||
            (errors.phoneNumber?.type === "minLength" &&
              "Incorrect phone number")
          }
          variant="filled"
          type="tel"
          name="phoneNumber"
          label="Phone number"
          value={phoneNumber}
          inputProps={{
            maxLength: 10,
            "data-testid": "phoneNumber",
          }}
          margin="normal"
          autoComplete="no"
          {...register("phoneNumber", {
            onChange: handlePhoneNumberInputChange,
            required: true,
            minLength: 10,
            maxLength: 10,
          })}
        />

        <TextField
          error={errors.email}
          helperText={errors.email ? "Incorrect email format" : "Optional"}
          variant="filled"
          type="text"
          name="email"
          label="Email"
          margin="normal"
          autoComplete="no"
          inputProps={{ maxLength: 320 }}
          {...register("email", {
            setValueAs: (email) => (email ? email : null),
            validate: (email) => (email ? validator.isEmail(email) : true),
          })}
        />

        <TextField
          error={errors.preferredLanguage}
          helperText={errors.preferredLanguage && "Choose a language"}
          variant="filled"
          select
          name="preferredLanguage"
          label="Preferred language"
          margin="normal"
          defaultValue="English"
          {...register("preferredLanguage", { required: true })}
        >
          <MenuItem value="English">English</MenuItem>
          <MenuItem value="Spanish">Spanish</MenuItem>
        </TextField>

        <TextField
          variant="filled"
          select
          name="numberOfMedications"
          label="Number of medications"
          margin="normal"
          helperText="Optional"
          {...register("numberOfMedications", {
            setValueAs: (numberOfMedications) =>
              numberOfMedications ? numberOfMedications : null,
          })}
        >
          <MenuItem value="Unknown">Unknown</MenuItem>
          <MenuItem value="01-05">1-5</MenuItem>
          <MenuItem value="06-11">6-11</MenuItem>
          <MenuItem value="12+">12+</MenuItem>
        </TextField>

        <TextField
          variant="filled"
          type="text"
          name="comment"
          label="Comment"
          helperText="Optional"
          margin="normal"
          multiline={true}
          rows={2}
          inputProps={{ maxLength: 200 }}
          autoComplete="no"
          {...register("comment")}
        />

        <Divider className={classes.divider} />

        {!showCareGiverDetails && (
          <Button
            className={classes.careGiverToggle}
            color="primary"
            size="large"
            endIcon={<AddCircleOutline />}
            data-testid="add-caregiver-button"
            onClick={() => setShowCareGiverDetails(true)}
          >
            Add caregiver information
          </Button>
        )}

        {showCareGiverDetails && (
          <>
            <div className={classes.subSection}>
              <Typography color="primary" className={classes.subSectionTitle}>
                Caregiver details
              </Typography>
            </div>

            <TextField
              helperText={errors.careGiverFirstName && "First name is required"}
              error={errors.careGiverFirstName}
              label="First name"
              variant="filled"
              type="text"
              margin="normal"
              name="careGiverFirstName"
              autoComplete="no"
              inputProps={{ maxLength: 200 }}
              {...register("careGiverFirstName", {
                required: true,
              })}
            />

            <TextField
              helperText={errors.careGiverLastName && "Last name is required"}
              error={errors.careGiverLastName}
              label="Last name"
              variant="filled"
              type="text"
              margin="normal"
              name="careGiverLastName"
              autoComplete="no"
              inputProps={{ maxLength: 200 }}
              {...register("careGiverLastName", {
                required: true,
              })}
            />

            <TextField
              helperText={
                (errors.careGiverPhoneNumber?.type === "required" &&
                  "Phone Number is required") ||
                (errors.careGiverPhoneNumber?.type === "minLength" &&
                  "Incorrect phone number")
              }
              error={errors.careGiverPhoneNumber}
              variant="filled"
              type="tel"
              name="careGiverPhoneNumber"
              label="Phone number"
              value={careGiverPhoneNumber}
              inputProps={{
                maxLength: 10,
              }}
              margin="normal"
              autoComplete="no"
              {...register("careGiverPhoneNumber", {
                onChange: handlePhoneNumberInputChange,
                required: true,
                minLength: 10,
                maxLength: 10,
              })}
            />

            <TextField
              helperText="Optional"
              error={errors.careGiverPatientRelationship}
              label="Relationship to patient"
              variant="filled"
              select
              margin="normal"
              name="careGiverPatientRelationship"
              {...register("careGiverPatientRelationship", { required: false })}
            >
              {careGiverRelationshipOptions.map((option, index) => (
                <MenuItem value={option} key={index}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="isCareGiverPreferredContact"
                  {...register("isCareGiverPreferredContact")}
                />
              }
              label={
                <Typography className={classes.subSectionBody}>
                  Preferred contact
                </Typography>
              }
            />

            <Button
              className={classes.removeButton}
              variant="contained"
              data-testid="remove-caregiver-button"
              onClick={() => {
                setShowCareGiverDetails(false);
                setCareGiverPhoneNumber("");
                unregister(careGiverFields);
              }}
            >
              Remove caregiver
            </Button>
          </>
        )}

        <Divider className={classes.divider} />

        <div className={classes.subSection}>
          <Typography color="primary" className={classes.subSectionTitle}>
            What times should we reach out?
          </Typography>
          <Typography className={classes.subSectionBody}>
            Expect contact within 1-2 business days after submission.
          </Typography>
        </div>

        <TextField
          variant="filled"
          select
          name="preferredContactTime"
          label="Best times to contact"
          margin="normal"
          defaultValue="Any time"
          {...register("preferredContactTime")}
        >
          <MenuItem value="Any time">Any time</MenuItem>
          <MenuItem value="Morning: 8:00-11:00 am ET">
            Morning: 8:00-11:00 am ET
          </MenuItem>
          <MenuItem value="Afternoon: 11:00-2:00 pm ET">
            Afternoon: 11:00-2:00 pm ET
          </MenuItem>
          <MenuItem value="Evening: 2:00-5:00 pm ET">
            Evening: 2:00-5:00 pm ET
          </MenuItem>
        </TextField>

        <Typography className={classes.disclaimerText}>
          I understand and agree that by submitting the above information that I
          am authorized to do so and this is considered consent for HomeFree
          Pharmacy Services to contact the individual for services. Furthermore,
          that I agree to HomeFree Pharmacy Services “Terms of Use” and
          acknowledge HomeFree Pharmacy Services “Privacy Statement” & “HIPAA
          Privacy Notice”. Policies are available for review from the HomeFree
          Pharmacy Services site (<a href="homefreerx.com">homefreerx.com</a>).
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={disclaimerCheck}
              inputProps={{ "data-testid": "disclaimer-check" }}
              onChange={(event) => {
                setDisclaimerCheck(event.target.checked);
                setShowDisclaimerError(false);
              }}
            />
          }
          label={
            <Typography className={classes.subSectionBody}>
              I understand
            </Typography>
          }
        />

        {showDisclaimerError && (
          <Typography className={classes.errorMessage}>
            You must agree to the disclaimer above
          </Typography>
        )}

        <Button
          className={
            !isValid || !disclaimerCheck
              ? `${classes.greyedSubmitButton} ${classes.submitButton}`
              : classes.submitButton
          }
          variant="contained"
          type="submit"
          color="primary"
          data-testid="submit-button"
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={26} />
          ) : (
            "Submit"
          )}
        </Button>
        <Dialog onClose={() => setShowDialog(false)} open={showDialog}>
          <DialogTitle>Sorry!</DialogTitle>
          <DialogContent>
            <DialogContentText data-testid="referral-error">
              {validationErrorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowDialog(false)}
              variant="contained"
              className={classes.leaveButton}
            >
              Go Back
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      {Prompt}
    </div>
  );
}

export default ReferralForm;
