// import Developer from "./pages/developer/Developer";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { CONFIGURATION } from "constants/configuration";
import { postLog } from "helpers/api";
import { useCallback, useContext } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { postSignOutFailure, postSignOutSuccess } from "utility";

import { Store } from "./context/Context";
import { theme } from "./helpers/theme";
import ClinicianHome from "./pages/clinician/ClinicianHome/ClinicianHome";
import DiscardPrompt from "./pages/common/DiscardPrompt/DiscardPrompt";
import GlobalApiError from "./pages/common/ErrorScreens/GlobalApiError";
import { monitorSignOut } from "./utility/signOut";

const useStyles = makeStyles((theme) => ({
  globalErrorContainer: {
    height: "100vh",
    background: theme.palette.gradient.linear,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.customMixins.pxToRem(16 * 4, 0),
  },
  logoutButton: {
    maxWidth: "480px",
    width: "80vw",
    margin: theme.customMixins.pxToRem(16 * 8, 16 * 2),
    background: theme.palette.white.high,
  },
}));

function RouteComponent() {
  const { state, dispatch } = useContext(Store);
  const classes = useStyles();
  const { instance } = useMsal();
  monitorSignOut();

  const userConfirmation = useCallback((_message, callback) => {
    const node = document.getElementById("custom-prompt");
    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(node);
      callback(answer);
    };
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <DiscardPrompt
          message={"Are you sure you want to discard your changes?"}
          cleanUp={cleanUp}
        />
      </ThemeProvider>,
      node,
    );
  }, []);

  const nurseAccess =
    state.userType === "NURSE" ||
    process.env.REACT_APP_ACCESS?.includes("NURSE");

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      {state.globalError && (
        <div className={classes.globalErrorContainer}>
          {state.globalError.statusCode === 401 && (
            <GlobalApiError
              showErrorDetails={!state.isProduction}
              error={state.globalError}
              customMessage={
                <Typography color="textPrimary" variant="h6">
                  Sorry, the user is not registered with us.
                  <br />
                  Please contact us at&nbsp;
                  <a href={"tel:" + CONFIGURATION.pharmacyContact.call}>
                    {CONFIGURATION.pharmacyContact.display}
                  </a>
                </Typography>
              }
            />
          )}

          {state.globalError.statusCode !== 401 && (
            <GlobalApiError
              customMessage={
                <Typography color="textPrimary" variant="h6">
                  Sorry, we encountered a problem with user registration.
                  <br />
                  Please contact us at&nbsp;
                  <a href={"tel:" + CONFIGURATION.pharmacyContact.call}>
                    {CONFIGURATION.pharmacyContact.display}
                  </a>
                </Typography>
              }
              showErrorDetails={!state.isProduction}
              error={state.globalError}
            />
          )}

          <Button
            className={classes.logoutButton}
            variant="outlined"
            size="large"
            data-testid="go-back"
            onClick={async () => {
              postLog("loggout");
              instance
                .logout()
                .then((_) => {
                  // Dispatching State cleanup
                  dispatch({ type: "ADD_TOKEN", token: null });
                  dispatch({ type: "TOGGLE_LOADER", loading: true });

                  // Post Sign Out Operations
                  postSignOutSuccess();
                })
                .catch((logoutError) => {
                  console.error({ logoutError });
                  postSignOutFailure();
                });
            }}
          >
            Go Back
          </Button>
        </div>
      )}
      {state.token && nurseAccess && (
        <Router getUserConfirmation={userConfirmation}>
          <Switch>
            <Route component={ClinicianHome} path="/clinician-home" />
            <Redirect to="/clinician-home/patient-view" />
          </Switch>
        </Router>
      )}
    </MsalAuthenticationTemplate>
  );
}

export default RouteComponent;
