// Todo:
// 1. Testing

import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Store } from "context/Context";
import { getAllMedications } from "helpers/api";
import { BASIC_CACHE } from "helpers/cacheConfiguration";
import MedicalCard from "pages/common/MedicalCard/MedicalCard";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useQuery } from "react-query";

import GlobalApiError from "./../../../common/ErrorScreens/GlobalApiError";
import NoMedication from "./NoMedication/NoMedication";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    overflowY: "auto",
    padding: theme.customMixins.pxToRem(24),
    maxWidth: theme.pageDimensions.mobileMaxWidth,
  },
  cardHolder: {
    margin: theme.customMixins.pxToRem(16, 0),
    boxShadow: theme.customCss.cardShadow,
  },
  cadenceContainer: {
    marginBottom: theme.customMixins.pxToRem(24),
    "& > h2": {
      font: theme.typography.sectionSubtitle.medium.font,
      color: theme.palette.primary.A900,
    },
  },
  loaderContainer: {
    display: "flex",
    padding: "2rem",
    justifyContent: "center",
  },
}));

function AllMedicationView({ patientPhoneNumber, readOnly }) {
  const classes = useStyles();
  const { state } = useContext(Store);

  const { isLoading, data, isError, error } = useQuery(
    ["AllMedication", patientPhoneNumber],
    (args) => {
      return getAllMedications(undefined, args.queryKey[1]);
    },
    BASIC_CACHE,
  );

  return (
    <>
      {isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress color="inherit" />
        </div>
      )}

      {isError && (
        <GlobalApiError showErrorDetails={!state.isProduction} error={error} />
      )}

      {!isError && (
        <>
          {data && data.count === 0 && (
            <>
              <NoMedication />
            </>
          )}
          {data && data.count !== 0 && (
            <>
              <div
                className={classes.cadenceContainer}
                hidden={!data.groupedCardData["DAILY"].length}
              >
                <Typography data-testid="take-daily" variant="h2">
                  Take daily
                </Typography>
                <div className={classes.cardHolder}>
                  <MedicalCard
                    id="Take daily"
                    title={null}
                    showRowSeparators={true}
                    showDosageDetails={true}
                    rows={data.groupedCardData["DAILY"]}
                    readOnly={readOnly}
                  ></MedicalCard>
                </div>
              </div>
              <div
                className={classes.cadenceContainer}
                hidden={!data.groupedCardData["LESS FREQUENTLY"].length}
              >
                <Typography variant="h2" data-testid="less-frequently">
                  Take less frequently than daily
                </Typography>
                <div className={classes.cardHolder}>
                  <MedicalCard
                    id="Take less frequently than daily"
                    title={null}
                    showRowSeparators={true}
                    showDosageDetails={true}
                    rows={data.groupedCardData["LESS FREQUENTLY"]}
                    readOnly={readOnly}
                  ></MedicalCard>
                </div>
              </div>
              <div
                className={classes.cadenceContainer}
                hidden={!data.groupedCardData["AS NEEDED"].length}
              >
                <Typography variant="h2" data-testid="as-needed">
                  Take as needed
                </Typography>
                <div className={classes.cardHolder}>
                  <MedicalCard
                    id="Take as needed"
                    title={null}
                    showRowSeparators={true}
                    showDosageDetails={true}
                    rows={data.groupedCardData["AS NEEDED"]}
                    readOnly={readOnly}
                  ></MedicalCard>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

AllMedicationView.propTypes = {
  patientPhoneNumber: PropTypes.string,
  readOnly: PropTypes.bool,
};

AllMedicationView.defaultProps = {
  patientPhoneNumber: "{current}",
  readOnly: false,
};

export default AllMedicationView;
