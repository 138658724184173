import { SAMPLE_USER_DETAILS } from "samples/userDetails";

import { get } from "../communication";

export const ROUTE_URL = "/user-mgmt/user/{current}/details";
export const API_DEV_HASH = "devMode##getUserDetails";

export const getUserDetails = () => {
  if (localStorage.getItem(API_DEV_HASH) === "true") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(SAMPLE_USER_DETAILS);
      }, 1000);
    });
  } else {
    return get(ROUTE_URL).then((response) => Promise.resolve(response.data));
  }
};
