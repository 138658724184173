import { PATIENT_PROFILE } from "samples/patientProfile";

import { get } from "../communication";
import { patientProfileSchema } from "./schema/getPatientProfile.schema";

export const ROUTE_URL =
  "/med-mgmt/clinician/member/{patientPhoneNumber}/profile";
export const API_DEV_HASH = "devMode##getPatientProfile";

export const getPatientProfile = async (patientPhoneNumber) => {
  if (localStorage.getItem(API_DEV_HASH) === "true") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(PATIENT_PROFILE);
      }, 1000);
    });
  } else {
    const routeUrl = ROUTE_URL.replace(
      "{patientPhoneNumber}",
      patientPhoneNumber,
    );
    const response = await get(
      routeUrl,
      null,
      API_DEV_HASH,
      patientProfileSchema,
    );
    return Promise.resolve(response.data);
  }
};
