import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import PropTypes from "prop-types";
import { generateCaseInsensitiveName } from "utility/stringModifications";

import GreenCircle from "./assets/icons/green-circle.svg";
import GreyCircle from "./assets/icons/grey-circle.svg";
import RedCircle from "./assets/icons/red-circle.svg";

const useStyles = makeStyles((theme) => ({
  statusContainer: {
    display: "flex",
    alignItems: "center",
    "& > img": {
      marginRight: theme.customMixins.pxToRem(14),
    },
    "& > h5": {
      color: theme.palette.textPrimary.emphasisLow,
      font: theme.typography.description.regular.font,
    },
  },
}));

const statusIcons = {
  left_voicemail: <img src={GreenCircle} alt="GreenCircle" />,
  consult_declined: <img src={GreyCircle} alt="GreyCircle" />,
  pending_initial_call: <img src={GreenCircle} alt="GreenCircle" />,
  call_back_request: <img src={GreenCircle} alt="GreenCircle" />,
  wrong_number: <img src={RedCircle} alt="RedCircle" />,
  unable_to_reach: <img src={RedCircle} alt="RedCircle" />,
  non_working_number: <img src={RedCircle} alt="RedCircle" />,
};

const StatusLine = ({ status }) => {
  const classes = useStyles();
  const theme = useTheme();
  let circle = <img src={GreenCircle} alt="GreenCircle" />;
  let color = theme.palette.textPrimary.emphasisLow;

  const statusKey = generateCaseInsensitiveName(status);

  if (statusIcons[statusKey]) circle = statusIcons[statusKey];
  const highlightedStatus = [
    "wrong_number",
    "unable_to_reach",
    "non_working_number",
  ];
  if (highlightedStatus.includes(statusKey)) color = theme.palette.error.dark;

  return (
    <div data-testid="status" className={classes.statusContainer}>
      {circle}
      <Typography
        variant="h5"
        className={classes.statusText}
        style={{ color: color }}
      >
        {status}
      </Typography>
    </div>
  );
};

export default StatusLine;

StatusLine.propTypes = {
  status: PropTypes.string.isRequired,
};

StatusLine.defaultProps = {
  status: "none",
};
