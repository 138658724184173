import { REASON_CODE_MAPPING } from "samples/reasonCodeMapping";

import { get } from "../communication";

export const ROUTE_URL =
  "/med-mgmt/member/{current}/medication/skip-reason-codes";
export const API_DEV_HASH = "devMode##getMedicationSkipReasons";

export const getMedicationSkipReasons = () => {
  if (localStorage.getItem(API_DEV_HASH) === "true") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(REASON_CODE_MAPPING);
      }, 1000);
    });
  } else {
    return get(ROUTE_URL).then((response) => Promise.resolve(response.data));
  }
};
