import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { TimeUtility } from "utility";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > h3": {
      font: theme.typography.body.bold.font,
      color: theme.palette.primary.A900,
    },
  },
  closeIcon: {
    width: theme.customMixins.pxToRem(24),
    height: theme.customMixins.pxToRem(24),
    cursor: "pointer",
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.customMixins.pxToRem(16),
  },
  timeContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.customMixins.pxToRem(24),
    marginBottom: theme.customMixins.pxToRem(24),
    borderBottom: theme.customCss.border,
  },
  periodSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  timeSeparator: {
    font: theme.typography.title.regular.font,
    color: theme.palette.primary.main,
  },
  inputStyle: {
    font: theme.typography.title.regular.font,
    display: "flex",
    alignItems: "center",
    minWidth: theme.customMixins.pxToRem(16 * 5),
    maxWidth: theme.customMixins.pxToRem(16 * 6),
    height: theme.customMixins.pxToRem(72),
    padding: theme.customMixins.pxToRem(8),
    borderRadius: theme.customMixins.pxToRem(4),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.system.BG,
    border: 0,
    "&:before": {
      content: "none",
    },
    "& input": {
      textAlign: "center",
    },
  },
  timeSelectionSection: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    margin: theme.customMixins.pxToRem(0, 18, 0, 0),
    alignItems: "center",
  },
  periodButton: {
    font: theme.typography.sectionTitle.regular.font,
    width: theme.customMixins.pxToRem(74),
    height: theme.customMixins.pxToRem(34),
    borderRadius: theme.customMixins.pxToRem(4),
    backgroundColor: theme.palette.textPrimary.emphasisHighG,
    textTransform: "lowercase",
  },
  selectedPeriodButton: {
    color: theme.palette.system.W,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  notesSection: {
    width: "100%",
    height: theme.customMixins.pxToRem(108),
    backgroundColor: theme.palette.white.high,
    padding: theme.customMixins.pxToRem(14, 18),
    font: theme.typography.description.regular.font,
    border: theme.customCss.border,
    borderRadius: theme.customMixins.pxToRem(14),
    color: theme.palette.system.text,
    resize: "none",
    margin: theme.customMixins.pxToRem(24, 0),
  },
  saveButton: {
    width: "100%",
    height: theme.customMixins.pxToRem(36),
    borderRadius: theme.customMixins.pxToRem(4),
    border: `${theme.customMixins.pxToRem(1)} solid`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.system.W,

    "&:disabled": {
      color: theme.palette.textPrimary.emphasisMedium,
      backgroundColor: theme.palette.shades.A1,
      borderColor: theme.palette.system.line,
    },

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.system.W,
    },
  },
}));

const getTimeIn12HrFormat = (timeString) => {
  const timeUtility = new TimeUtility();
  const timeIn12HourFormat = timeUtility.convertTimeTo12HrFormat(timeString);
  const { hh, mm, amPm } = timeUtility.split12HourFormat(timeIn12HourFormat);
  return { hour: hh, minutes: mm, period: amPm.toLowerCase() };
};

function UpdateAdherenceTime({ onClose, onSave, currentTime }) {
  const classes = useStyles();
  const { hour, minutes, period } = getTimeIn12HrFormat(currentTime.time);
  const [selectedHour, setSelectedHour] = useState(hour);
  const [selectedMinutes, setSelectedMinutes] = useState(minutes);
  const [selectedPeriod, setSelectedPeriod] = useState(period);

  const handleHourChange = (event) => {
    let newHourValue = event.target.value;
    if (newHourValue.length !== 0) {
      newHourValue = parseInt(newHourValue);
      if (newHourValue >= 1 && newHourValue <= 9) {
        event.target.value = "0" + newHourValue;
        setSelectedHour("0" + newHourValue);
      } else if (newHourValue >= 10 && newHourValue <= 12) {
        event.target.value = newHourValue;
        setSelectedHour(newHourValue);
      } else if (newHourValue > 12) {
        setSelectedHour(12);
      } else {
        setSelectedHour("00");
      }
    } else {
      setSelectedHour("00");
    }
  };

  const handleHourOnBlur = (event) => {
    let newHourValue = event.target.value;
    newHourValue = parseInt(newHourValue);

    if (newHourValue === 0) {
      setSelectedHour(12);
    }
  };

  const handleMinutesChange = (event) => {
    let newMinutesValue = event.target.value;
    if (newMinutesValue.length !== 0) {
      newMinutesValue = parseInt(newMinutesValue);
      if (newMinutesValue >= 0 && newMinutesValue <= 9) {
        event.target.value = "0" + newMinutesValue;
        setSelectedMinutes("0" + newMinutesValue);
      } else if (newMinutesValue >= 10 && newMinutesValue <= 59) {
        event.target.value = newMinutesValue;
        setSelectedMinutes(newMinutesValue);
      } else if (newMinutesValue > 59) {
        setSelectedMinutes(59);
      } else {
        setSelectedMinutes("00");
      }
    } else {
      setSelectedMinutes("00");
    }
  };

  useEffect(() => {
    handleHourChange({ target: { value: hour } });
    handleMinutesChange({ target: { value: minutes } });
  }, [hour, minutes]);

  const handleSaveButton = () => {
    const timeUtility = new TimeUtility();
    const timeIn12HourFormat = `${selectedHour}:${selectedMinutes} ${selectedPeriod}`;
    const timeIn24hrFormat =
      timeUtility.convertTimeTo24HrFormat(timeIn12HourFormat);
    const epochTime = timeUtility.convertLocalTimeStringToEpoch(
      timeIn24hrFormat,
      currentTime.consumptionDate,
    );

    onSave(epochTime);
  };

  return (
    <Dialog onClose={onClose} open={true}>
      <DialogTitle onClose={onClose}>
        <div className={classes.headerContainer}>
          <Typography data-testid="test-title" variant="h3">
            Change taken time
          </Typography>
          <CloseIcon className={classes.closeIcon} onClick={onClose} />
        </div>
      </DialogTitle>
      <DialogContent>
        <section className={classes.bodyContainer}>
          <section className={classes.timeContainer}>
            <div className={classes.timeSelectionSection}>
              <Input
                type="number"
                className={classes.inputStyle}
                value={selectedHour}
                onChange={handleHourChange}
                onBlur={handleHourOnBlur}
              />
              <span className={classes.timeSeparator}>:</span>
              <Input
                type="number"
                className={classes.inputStyle}
                value={selectedMinutes}
                onChange={handleMinutesChange}
                onBlur={handleMinutesChange}
              />
            </div>
            <div className={classes.periodSection}>
              <Button
                data-testid="am-button"
                className={`${classes.periodButton} ${
                  selectedPeriod === "am" ? classes.selectedPeriodButton : ""
                }`}
                onClick={() => setSelectedPeriod("am")}
              >
                am
              </Button>
              <Button
                data-testid="pm-button"
                className={`${classes.periodButton} ${
                  selectedPeriod === "pm" ? classes.selectedPeriodButton : ""
                }`}
                onClick={() => setSelectedPeriod("pm")}
              >
                pm
              </Button>
            </div>
          </section>
          <Button
            className={classes.saveButton}
            data-testid="save-button"
            onClick={handleSaveButton}
            disabled={
              (selectedHour === hour &&
                selectedMinutes === minutes &&
                selectedPeriod === period) ||
              !parseInt(selectedHour)
            }
          >
            Save
          </Button>
        </section>
      </DialogContent>
    </Dialog>
  );
}

UpdateAdherenceTime.defaultProps = {
  onClose: () => {},
};

UpdateAdherenceTime.propTypes = {
  onClose: PropTypes.func,
  currentTime: PropTypes.shape({
    time: PropTypes.number.isRequired,
    consumptionDate: PropTypes.instanceOf(Date),
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default UpdateAdherenceTime;
