import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBack } from "@material-ui/icons";
import { CONFIGURATION } from "constants/configuration";
import { UI_ERROR_MESSAGES } from "constants/errorMessages";
import { Store } from "context/Context";
import { getReferralDetails } from "helpers/api";
import { BASIC_CACHE } from "helpers/cacheConfiguration";
import CardErrorBoundary from "pages/common/CardErrorBoundary/CardErrorBoundary";
import ContactUsDialog from "pages/common/ContactUsDialog/ContactUsDialog";
import GlobalApiError from "pages/common/ErrorScreens/GlobalApiError";
import SimplePageLoader from "pages/common/PageLoaders/SimplePageLoader";
import ReferralTimeLine from "pages/common/ReferralTimeline/ReferralTimeline";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { generateFullName } from "utility";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    height: theme.customMixins.pxToRem(10),
    flexGrow: 1,
  },
  listContainer: {
    background: theme.palette.white.high,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  errorMessage: {
    font: theme.typography.description.regular.font,
    color: theme.palette.error.main,
  },
  buttonContainer: {
    background: theme.palette.white.high,
  },
  listItems: {
    flexGrow: 10,
  },
  headerContainer: {
    padding: theme.customMixins.pxToRem(16 * 2),
    background: theme.palette.gradient.angle,
  },
  patientDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.customMixins.pxToRem(16, 0),
    "& > h3": {
      font: theme.typography.sectionTitle.bold.font,
      color: theme.palette.primary.A700,
      wordWrap: "break-word",
      width: "90%",
      textAlign: "center",
    },
    "& > h4": {
      font: theme.typography.description.regular.font,
      color: theme.palette.textPrimary.emphasisLow,
    },
  },
  backArrow: {
    color: theme.palette.primary.A700,
    width: theme.customMixins.pxToRem(24),
    height: theme.customMixins.pxToRem(24),
    cursor: "pointer",
  },
  sectionTitle: {
    display: "inline-block",
    font: theme.typography.sectionSubtitle.bold.font,
    color: theme.palette.primary.A900,
  },
  contactButton: {
    color: theme.palette.primary.main,
    width: "90%",
    boxShadow: "none",
    margin: theme.customMixins.pxToRem(16),
    padding: theme.customMixins.pxToRem(12),
    bottom: theme.customMixins.pxToRem(10),
  },
}));

const getPageData = (args) => {
  return getReferralDetails(args.queryKey[1], args.queryKey[2]);
};

const getFormattedDate = (dateString) => {
  const options = { month: "long", day: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};

function ReferralDetails() {
  const referral = JSON.parse(localStorage.getItem("referralDetail"));
  const classes = useStyles();
  const history = useHistory();
  const { state } = useContext(Store);
  const [contactUsDialog, setContactUsDialog] = useState(false);
  const {
    data: referralData,
    isLoading,
    isError,
    error,
  } = useQuery(
    ["ReferralDetails", referral.referralId, referral.latestUpdateAt],
    getPageData,
    BASIC_CACHE,
  );

  const fullName = generateFullName({
    firstName: referral.referralLastName,
    lastName: referral.referralFirstName,
    separator: ", ",
  });

  return (
    <div data-testid="referral-detail-card" className={classes.rootContainer}>
      {isError && (
        <GlobalApiError showErrorDetails={!state.isProduction} error={error} />
      )}
      {!isError && (
        <div className={classes.headerContainer}>
          <ArrowBack
            data-testid="referral-detail-back-button"
            className={classes.backArrow}
            onClick={() => history.goBack()}
          />
          <div className={classes.patientDetails}>
            <Typography variant="h3">{fullName}</Typography>
            <Typography variant="h4">
              Updated {getFormattedDate(referral.latestUpdateAt)}
            </Typography>
          </div>
        </div>
      )}
      {!isError && (
        <div className={classes.listContainer}>
          {!isLoading && (
            <div className={classes.listItems}>
              {!referralData && (
                <div data-testid="referral-no-data">No data </div>
              )}
              {referralData && (
                <CardErrorBoundary
                  message={UI_ERROR_MESSAGES.referralDetailsDisplay}
                >
                  <ReferralTimeLine referralData={referralData} />
                </CardErrorBoundary>
              )}
            </div>
          )}
          <SimplePageLoader hidden={!isLoading} />
          <div className={classes.buttonContainer}>
            <Button
              data-testid="contact-button"
              onClick={() => setContactUsDialog(true)}
              className={classes.contactButton}
              variant="outlined"
            >
              Contact us
            </Button>
          </div>
        </div>
      )}
      <ContactUsDialog
        open={contactUsDialog}
        onContactPhone={() => {
          window.location.href = "tel:" + CONFIGURATION.pharmacyContact.call;
        }}
        onContactEmail={() =>
          (window.location.href = "mailto:support@homefreerx.com")
        }
        onClose={() => setContactUsDialog(false)}
      />
    </div>
  );
}

export default ReferralDetails;
