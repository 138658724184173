import { post } from "../communication";

export const ROUTE_URL = "/user-mgmt/enrollment/user";
export const API_DEV_HASH = "devMode##userEnrollmentRequest";

export const userEnrollmentRequest = async () => {
  let response;

  if (localStorage.getItem(API_DEV_HASH) === "true") {
    response = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: {} });
      }, 1000);
    });
  } else {
    response = await post(ROUTE_URL);
  }

  return response;
};
