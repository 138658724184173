import { get } from "../communication";

export const ROUTE_URL = "/med-mgmt/notification-categories";
export const API_DEV_HASH = "devMode##getAllSmsConfiguration";

export const getAllSmsConfiguration = (page, limit = 4) => {
  if (localStorage.getItem(API_DEV_HASH) === "true") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([]);
      }, 1000);
    });
  } else {
    return get(ROUTE_URL, { page, limit }).then((response) =>
      Promise.resolve(response.data),
    );
  }
};
