// Todo:
// 1. Testing

import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Store } from "context/Context";
import { getDeliveryDetails } from "helpers/api";
import { BASIC_CACHE } from "helpers/cacheConfiguration";
import DeliveryDetailsCard from "pages/common/DeliveryDetailsCard/DeliveryDetailsCard";
import NoDelivery from "pages/common/NoDataScreens/NoDelivery/NoDelivery";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useQuery } from "react-query";

import GlobalApiError from "./../../../common/ErrorScreens/GlobalApiError";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    overflowY: "auto",
    padding: theme.customMixins.pxToRem(24),
    maxWidth: theme.pageDimensions.mobileMaxWidth,
  },
  cardHolder: {
    margin: theme.customMixins.pxToRem(16, 0),
    boxShadow: theme.customCss.cardShadow,
  },
  cadenceContainer: {
    marginBottom: theme.customMixins.pxToRem(24),
    "& > h2": {
      font: theme.typography.sectionSubtitle.medium.font,
      color: theme.palette.primary.A900,
    },
  },
  loaderContainer: {
    display: "flex",
    padding: "2rem",
    justifyContent: "center",
  },
}));

function DeliveryDetailsView({ shipmentId }) {
  const classes = useStyles();
  const { state } = useContext(Store);

  let patientPhoneNumber = "{current}";
  try {
    const patientDetails = JSON.parse(localStorage.getItem("patientDetails"));
    patientPhoneNumber = patientDetails.phoneNumber;
  } catch (err) {}

  const { isLoading, data, isError, error } = useQuery(
    ["DeliveryDetails", shipmentId, patientPhoneNumber],
    (args) => {
      return getDeliveryDetails(args.queryKey[1], args.queryKey[2]);
    },
    BASIC_CACHE,
  );

  return (
    <div>
      {isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress color="inherit" />
        </div>
      )}
      {isError && (
        <GlobalApiError showErrorDetails={!state.isProduction} error={error} />
      )}
      {!isError && (
        <>
          {data && data.length === 0 && (
            <>
              <NoDelivery
                message="No delivery details available"
                hidden={false}
              />
            </>
          )}
          {data && data.length !== 0 && (
            <>
              <div className={classes.cadenceContainer} hidden={!data.length}>
                <Typography data-testid="take-daily" variant="h2">
                  Medications in your delivery
                </Typography>
                <div className={classes.cardHolder}>
                  <DeliveryDetailsCard
                    cardData={data}
                    reactUniqueId="cardDetailsView"
                  ></DeliveryDetailsCard>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

DeliveryDetailsView.propTypes = {
  shipmentId: PropTypes.string,
};

DeliveryDetailsView.defaultProps = {
  shipmentId: "",
};

export default DeliveryDetailsView;
