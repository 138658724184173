import { put } from "../communication";

export const ROUTE_URL = "/user-mgmt/role/user";
export const API_DEV_HASH = "devMode##switchUserRole";

export const switchUserRole = (userType) => {
  let apiPromise;

  if (localStorage.getItem(API_DEV_HASH) === "true") {
    apiPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: {} });
      }, 1000);
    });
  } else {
    apiPromise = put(ROUTE_URL, { userType });
  }

  return apiPromise;
};
